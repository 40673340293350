import {
  SEND_SAFECHECK_MESSAGE_REQUEST,
  SEND_SAFECHECK_MESSAGE_SUCCESS,
  SEND_SAFECHECK_FAILURE,
  SEND_SAFECHECK_CLEAR,
  FETCH_SAFECHECK_RECEIPT_REQUEST,
  FETCH_SAFECHECK_RECEIPT_SUCCESS,
  FETCH_SAFECHECK_RECEIPT_FAILURE,
  FETCH_SAFECHECK_RECEIPT_CLEAR,
  SUBMIT_SAFECHECK_RECEIPT_REQUEST,
  SUBMIT_SAFECHECK_RECEIPT_SUCCESS,
  SUBMIT_SAFECHECK_RECEIPT_FAILURE,
  SUBMIT_SAFECHECK_RECEIPT_CLEAR,
  FETCH_SAFECHECK_MESSAGES_REQUEST,
  FETCH_SAFECHECK_MESSAGES_SUCCESS,
  FETCH_SAFECHECK_MESSAGES_FAILURE,
  FETCH_SAFECHECK_MESSAGES_CLEAR,
  FETCH_SAFECHECK_MESSAGE_REQUEST,
  FETCH_SAFECHECK_MESSAGE_SUCCESS,
  FETCH_SAFECHECK_MESSAGE_FAILURE,
  FETCH_SAFECHECK_MESSAGE_CLEAR,
  RESEND_SAFECHECK_MESSAGE_REQUEST,
  RESEND_SAFECHECK_MESSAGE_SUCCESS,
  RESEND_SAFECHECK_MESSAGE_FAILURE,
  RESEND_SAFECHECK_MESSAGE_CLEAR,
  VALIDATE_PHONE_NUMBER,
  SET_VALIDATE_PHONE_NUMBER_LOADING,
  UNENROLL_SAFECHECK_REQUEST,
  UNENROLL_SAFECHECK_SUCCESS,
  UNENROLL_SAFECHECK_FAILURE,
  SEND_SAFECHECK_ENROLLMENT_REQUEST,
  SEND_SAFECHECK_ENROLLMENT_SUCCESS,
  SEND_SAFECHECK_ENROLLMENT_FAILURE,
  SEND_SAFECHECK_ENROLLMENT_CLEAR,
} from '../actions/types';

const initialState = {
  sendSafecheckMessage: {
    data: null,
    loading: false,
    error: null,
  },
  fetchSafecheckReceipt: {
    data: null,
    loadiing: false,
    error: null,
  },
  submitSafecheckReceipt: {
    data: null,
    loadiing: false,
    error: null,
  },
  fetchSafecheckMessages: {
    data: null,
    loading: false,
    error: null,
  },
  fetchSafecheckMessage: {
    data: null,
    loading: false,
    error: null,
  },
  resendSafecheckMessage: {
    data: null,
    loading: false,
    error: null,
  },
  validatePhoneNumber: {
    validatedPhoneNumber: [],
    loading: false,
    error: null,
  },
  validatePhoneNumberLoading: false,
  safeCheckEnrollmentRequest: {
    data: null,
    loading: false,
    error: null,
  },

  unEnrollSafeCheckLoading: false,
  unEnrollSafeCheckError: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SEND_SAFECHECK_MESSAGE_REQUEST:
      return {
        ...state,
        sendSafecheckMessage: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case SEND_SAFECHECK_MESSAGE_SUCCESS:
      return {
        ...state,
        sendSafecheckMessage: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case SEND_SAFECHECK_FAILURE:
      return {
        ...state,
        sendSafecheckMessage: {
          data: null,
          loading: false,
          error: 'There was a problem sending the SafeCheck message.  Please try again later.',
        },
      };
    case SEND_SAFECHECK_CLEAR:
      return {
        ...state,
        sendSafecheckMessage: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_RECEIPT_REQUEST:
      return {
        ...state,
        fetchSafecheckReceipt: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_SAFECHECK_RECEIPT_SUCCESS:
      return {
        ...state,
        fetchSafecheckReceipt: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_RECEIPT_FAILURE:
      return {
        ...state,
        fetchSafecheckReceipt: {
          data: null,
          loading: false,
          error: 'There was a problem finding the SafeCheck receipt.  Please try again later.',
        },
      };
    case FETCH_SAFECHECK_RECEIPT_CLEAR:
      return {
        ...state,
        fetchSafecheckReceipt: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case SUBMIT_SAFECHECK_RECEIPT_REQUEST:
      return {
        ...state,
        submitSafecheckReceipt: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case SUBMIT_SAFECHECK_RECEIPT_SUCCESS:
      return {
        ...state,
        submitSafecheckReceipt: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case SUBMIT_SAFECHECK_RECEIPT_FAILURE:
      return {
        ...state,
        submitSafecheckReceipt: {
          data: null,
          loading: false,
          error: 'There was a problem finding the SafeCheck receipt.  Please try again later.',
        },
      };
    case SUBMIT_SAFECHECK_RECEIPT_CLEAR:
      return {
        ...state,
        submitSafecheckReceipt: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGES_REQUEST:
      return {
        ...state,
        fetchSafecheckMessages: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGES_SUCCESS:
      return {
        ...state,
        fetchSafecheckMessages: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGES_FAILURE:
      return {
        ...state,
        fetchSafecheckMessages: {
          data: null,
          loading: false,
          error: 'There was a problem loading the SafeCheck messages.  Please try again later.',
        },
      };
    case FETCH_SAFECHECK_MESSAGES_CLEAR:
      return {
        ...state,
        fetchSafecheckMessages: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGE_REQUEST:
      return {
        ...state,
        fetchSafecheckMessage: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchSafecheckMessage: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGE_FAILURE:
      return {
        ...state,
        fetchSafecheckMessage: {
          data: null,
          loading: false,
          error: 'There was a problem finding the SafeCheck message.  Please try again later.',
        },
      };
    case FETCH_SAFECHECK_MESSAGE_CLEAR:
      return {
        ...state,
        fetchSafecheckMessage: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case RESEND_SAFECHECK_MESSAGE_REQUEST:
      return {
        ...state,
        resendSafecheckMessage: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case RESEND_SAFECHECK_MESSAGE_SUCCESS:
      return {
        ...state,
        resendSafecheckMessage: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case RESEND_SAFECHECK_MESSAGE_FAILURE:
      return {
        ...state,
        resendSafecheckMessage: {
          data: null,
          loading: false,
          error: 'There was a problem resending the SafeCheck message.  Please try again later.',
        },
      };
    case RESEND_SAFECHECK_MESSAGE_CLEAR:
      return {
        ...state,
        resendSafecheckMessage: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case VALIDATE_PHONE_NUMBER:
      return {
        ...state,
        validatePhoneNumber: {
          validatedPhoneNumber: action.payload,
          loading: true,
          error: action.payload.error,
        },
      };

    case SET_VALIDATE_PHONE_NUMBER_LOADING:
      return {
        ...state,
        validatePhoneNumberLoading: action.payload,
      };

    case UNENROLL_SAFECHECK_REQUEST:
      return {
        ...state,
        unEnrollSafeCheckLoading: true,
        unEnrollSafeCheckError: '',
      };

    case UNENROLL_SAFECHECK_SUCCESS:
      return {
        ...state,
        unEnrollSafeCheckLoading: false,
      };

    case UNENROLL_SAFECHECK_FAILURE:
      return {
        ...state,
        unEnrollSafeCheckError: action.payload,
        unEnrollSafeCheckLoading: false,
      };

    case SEND_SAFECHECK_ENROLLMENT_REQUEST:
      return {
        ...state,
        safeCheckEnrollmentRequest: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case SEND_SAFECHECK_ENROLLMENT_SUCCESS:
      return {
        ...state,
        safeCheckEnrollmentRequest: {
          data: action.payload.success.split('')[0] === '0' ? null : action.payload.success,
          loading: false,
          error: action.payload.failure.split('')[0] === '0' ? null : action.payload.failure,
        },
      };
    case SEND_SAFECHECK_ENROLLMENT_FAILURE:
      return {
        ...state,
        safeCheckEnrollmentRequest: {
          data: null,
          loading: false,
          error: 'There was a problem sending the SafeCheck Enrollment request.  Please try again later.',
        },
      };
    case SEND_SAFECHECK_ENROLLMENT_CLEAR:
      return {
        ...state,
        safeCheckEnrollmentRequest: {
          data: null,
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
}
