// Traveler profile
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_RACE_OPTIONS = 'GET_RACE_OPTIONS';
export const UPDATE_TRAVELER_INFO = 'UPDATE_TRAVELER_INFO';
export const GET_GENDER_IDENTITIES = 'GET_GENDER_IDENTITIES';
export const GET_NOTIFICATION_TYPES = 'GET_NOTIFICATION_TYPES';
export const GET_NOTIFICATION_OPT_OUTS = 'GET_NOTIFICATION_OPT_OUTS';
export const GET_TRAVELER_PROFILE_DATA = 'GET_TRAVELER_PROFILE_DATA';
export const GET_INBOUND_ORG_DATA = "GET_INBOUND_ORG_DATA";
// Traveler events
export const SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT';
export const CLEAR_ACTIVE_EVENT = 'CLEAR_ACTIVE_EVENT';
export const GET_TRAVELER_EVENTS = 'GET_TRAVELER_EVENTS';
export const TOGGLE_EVENT_REGISTRATION_MODAL = 'TOGGLE_EVENT_REGISTRATION_MODAL';

// Traveler application submissions
export const TOGGLE_DEFER_MODAL = 'TOGGLE_DEFER_MODAL';
export const TOGGLE_COMMIT_MODAL = 'TOGGLE_COMMIT_MODAL';
export const TOGGLE_WITHDRAW_MODAL = 'TOGGLE_WITHDRAW_MODAL';
export const TOGGLE_REQUEST_TO_WITHDRAW_MODAL = 'TOGGLE_REQUEST_TO_WITHDRAW_MODAL';
export const STORE_PROGRAM_ID = 'STORE_PROGRAM_ID';
export const GET_APPLICATION_STATUSES = 'GET_APPLICATION_STATUSES';
export const GET_TRAVELER_FORM_SUBMISSION = 'GET_TRAVELER_FORM_SUBMISSION';
export const RESET_TRAVELER_FORM_SUBMISSION = 'RESET_TRAVELER_FORM_SUBMISSION';
export const RESET_TRAVELER_APPLICATION_UPDATE = 'RESET_TRAVELER_APPLICATION_UPDATE';
export const GET_TRAVELER_APPLICATION_SUBMISSION = 'GET_TRAVELER_APPLICATION_SUBMISSION';
export const GET_TRAVELER_APPLICATION_SUBMISSIONS = 'GET_TRAVELER_APPLICATION_SUBMISSIONS';
export const RESET_TRAVELER_APPLICATION_SUBMISSION = 'RESET_TRAVELER_APPLICATION_SUBMISSION';
export const UPDATE_TRAVELER_APPLICATION_SUBMISSION = 'UPDATE_TRAVELER_APPLICATION_SUBMISSION';

// Current organization
export const GET_CURRENT_ORGANIZATION = 'GET_CURRENT_ORGANIZATION';

// Program dashboard
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAMS_REQUEST = 'GET_PROGRAMS_REQUEST';
export const SET_PROGRAMS_SORT = 'SET_PROGRAMS_SORT';
export const TOGGLE_PROGRAMS_FILTER = 'TOGGLE_PROGRAMS_FILTER';
export const SET_PROGRAMS_SEARCH_TERM = 'SET_PROGRAMS_SEARCH_TERM';
export const CLEAR_ALL_PROGRAMS_FILTERS = 'CLEAR_ALL_PROGRAMS_FILTERS';
