import { FETCH_RECOMMENDATION, SUBMIT_RECOMMENDATION } from '../actions/types';

const initialState = {
  name: '',
  recommendation: {},
  program: {},
  instructions: '',
  sendCode: null,
  client_user: false,
  clientLogo: '',
  loading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECOMMENDATION:
      return {
        ...state,
        name: action.payload.name,
        recommendation: action.payload.recommendation,
        program: action.payload.program,
        instructions: action.payload.instructions,
        client_user: action.payload.client_user,
        clientLogo: action.payload.client_logo,
        loading: false,
      };
    case SUBMIT_RECOMMENDATION:
      return {
        recommendation: action.payload.recommendation,
      };
    default:
      return state;
  }
}
