import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../routes/errorpages/ServerError/serverError.css';
import ServerErrorImage from '../../routes/errorpages/images/serverError.svg';
import PrimaryHeader from '../../components/PrimaryHeader';
import Rollbar from 'rollbar';
import rollbarToken from '../../shared/helpers/rollbarToken';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      rollbar: new Rollbar({
        accessToken: rollbarToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
      }),
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <PrimaryHeader />
          <div className={s.backgroundImg}>
            <div className={s.contentDiv}>
              <div className={s.titleOfError}>Stand by!</div>
              <div className={s.messageOfError}>
                {`Runtime Error. Your journey was unexpectedly interrupted because something on our end missed its connection. We're sorry for the delay!`}
                <p className={s.mT20}>
                  {' '}
                  A few choices:{' '}
                  <a href={window.location.href.indexOf('client') > 0 ? `/client/traveler-dashboard` : `/traveler`}>
                    Main Page,{' '}
                  </a>
                  <a href={`https://www.viatrm.com/blog/`}>Blog </a>
                  or contact support@via-trm.com.
                </p>
                <details style={{ whiteSpace: 'pre-wrap', align: 'left' }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </div>

              <img className={s.contentErrorImage} src={ServerErrorImage} />
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withStyles(s)(ErrorBoundary);
