import getRenderedLayout from '../utils/my-programs/getRenderedLayout';

import {
  TOGGLE_DEFER_MODAL,
  TOGGLE_COMMIT_MODAL,
  TOGGLE_WITHDRAW_MODAL,
  TOGGLE_REQUEST_TO_WITHDRAW_MODAL,
  GET_APPLICATION_STATUSES,
  GET_TRAVELER_FORM_SUBMISSION,
  RESET_TRAVELER_FORM_SUBMISSION,
  RESET_TRAVELER_APPLICATION_UPDATE,
  GET_TRAVELER_APPLICATION_SUBMISSION,
  GET_TRAVELER_APPLICATION_SUBMISSIONS,
  RESET_TRAVELER_APPLICATION_SUBMISSION,
  UPDATE_TRAVELER_APPLICATION_SUBMISSION,
  STORE_PROGRAM_ID,
} from '../actions/types';

const initialState = {
  formSubmission: null,
  showDeferModal: false,
  showCommitModal: false,
  applicationStatuses: [],
  showWithdrawModal: false,
  showRequestToWithdrawModal: false,
  applicationUpdated: false,
  applicationSubmissions: [],
  formSubmissionError: false,
  applicationSubmission: null,
  formSubmissionLoading: true,
  applicationUpdateError: false,
  applicationSubmissionError: false,
  applicationSubmissionLoading: true,
  applicationSubmissionsError: false,
  applicationSubmissionsLoading: true,
  programSubmissionId: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TRAVELER_APPLICATION_SUBMISSIONS:
      return {
        ...state,
        applicationSubmissionsLoading: false,
        applicationSubmissions: action.payload.data,
        applicationSubmissionsError: action.payload.error,
      };
    case GET_TRAVELER_APPLICATION_SUBMISSION:
      return {
        ...state,
        applicationSubmissionLoading: false,
        applicationSubmission: {
          ...action.payload.data,
          attributes: {
            ...action.payload.data.attributes,
            questions: action.payload.data.attributes.template.questions,
            layout: action.payload.data.attributes.template.template_layout,
            renderedLayout: getRenderedLayout(action.payload.data.attributes),
            submission_review: action.payload.data.attributes.submission_review || {},
            templateName: action.payload.data.attributes.template.name,
          },
        },
        applicationSubmissionError: action.payload.error,
      };
    case STORE_PROGRAM_ID:
      return {
        ...state,
        programSubmissionId: action.payload.programId,
      };
    case RESET_TRAVELER_APPLICATION_SUBMISSION:
      return {
        ...state,
        applicationSubmission: null,
        applicationSubmissionError: false,
        applicationSubmissionLoading: true,
      };
    case UPDATE_TRAVELER_APPLICATION_SUBMISSION:
      return {
        ...state,
        applicationUpdated: true,
        applicationUpdateError: action.payload.error,
      };
    case RESET_TRAVELER_APPLICATION_UPDATE:
      return {
        ...state,
        applicationUpdated: false,
        applicationUpdateError: false,
      };
    case GET_APPLICATION_STATUSES:
      return {
        ...state,
        applicationStatuses: action.payload,
      };
    case GET_TRAVELER_FORM_SUBMISSION:
      return {
        ...state,
        formSubmissionLoading: false,
        formSubmission: {
          ...action.payload.data,
          attributes: {
            ...action.payload.data.attributes,
            questions: action.payload.data.attributes.template.questions,
            layout: action.payload.data.attributes.template.template_layout,
            renderedLayout: getRenderedLayout(action.payload.data.attributes),
            submission_review: action.payload.data.attributes.submission_review || {},
          },
        },
        formSubmissionError: action.payload.error,
      };
    case RESET_TRAVELER_FORM_SUBMISSION:
      return {
        ...state,
        formSubmission: null,
        formSubmissionError: false,
        formSubmissionLoading: true,
      };
    case TOGGLE_COMMIT_MODAL:
      return {
        ...state,
        showCommitModal: !state.showCommitModal,
      };
    case TOGGLE_WITHDRAW_MODAL:
      return {
        ...state,
        showWithdrawModal: !state.showWithdrawModal,
      };

    case TOGGLE_REQUEST_TO_WITHDRAW_MODAL:
      return {
        ...state,
        showRequestToWithdrawModal: !state.showRequestToWithdrawModal,
      };

    case TOGGLE_DEFER_MODAL:
      return {
        ...state,
        showDeferModal: !state.showDeferModal,
      };
    default:
      return state;
  }
}
