import { combineReducers } from 'redux';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import adminEventsReducer from './adminEventsReducer';
import travelPlansReducer from './travelPlansReducer';
import intakeReducer from './intakeReducer';
import programMatchReducer from './programMatchReducer';
import programDashboardReducer from './programDashboardReducer';
import travelerReducer from './travelerReducer';
import programReducer from './programReducer';
import programBrochureReducer from './programBrochureReducer';
import clientReducer from './clientReducer';
import adminApplicationsReducer from './adminApplicationsReducer';
import adminFormsReducer from './adminFormsReducer';
import orgSettingsReducer from './orgSettingsReducer';
import reportsReducer from './reportsReducer';
import termNamesReducer from './termNamesReducer';
import travelerSettingsReducer from './travelerSettingsReducer';
import tagsReducer from './tagsReducer';
import programAlternateReducer from './programAlternateReducer';
import alertsReducer from './alertsReducer';
import programSettingsReducer from './programSettingsReducer';
import invitationsReducer from './invitationsReducer';
import template from './templateReducer';
import templates from './templatesReducer';
import travelerApplications from './travelerApplicationsReducer';
import travelerRankAlternative from './travelerRankAlternativeReducer';
import travelerForms from './travelerFormsReducer';
import recommendations from './recommendationsReducer';
import roleReducer from './roleReducer';
import visitorEventsReducer from './visitorEventsReducer';
import announcementsReducer from './announcementsReducer';
import lookupReducer from './lookupReducer';
import plans from './plans';
import safecheckReducer from './safecheckReducer';
import visitorReducer from './visitorReducer';
import programModifyReducer from './programModifyReducer';
import authenticationReducer from './authenticationReducer';
import formsReducer from './formsReducer';
import intakeFormReducer from '../sites/travelerForms/reducers/intakeFormReducer';
import programMatchFormReducer from '../sites/travelerForms/reducers/programMatchFormReducer';
import travelerProfileReducer from '../sites/travelerProfile/reducers/travelerProfileReducer';
import currentOrganizationReducer from '../sites/travelerProfile/reducers/currentOrganizationReducer';
import travelerEventsReducer from '../sites/travelerProfile/reducers/travelerEventsReducer';
import programDashboardReducerV2 from '../sites/travelerProfile/reducers/programDashboardReducer';
import travelerApplicationsReducerV2 from '../sites/travelerProfile/reducers/travelerApplicationsReducer';
import riskAlertReducer from './riskAlertReducer';
import automationReducer from './automationReducer';
import reportV2Reducer from './reportV2Reducer';
import analyticsReportsReducer from './analyticsReportsReducer';
import exchangeBalanceReducer from './exchangeBalanceReducer';

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  traveler: travelerReducer,
  adminEvents: adminEventsReducer,
  travelPlans: travelPlansReducer,
  intake: intakeReducer,
  programMatch: programMatchReducer,
  programs: programDashboardReducer,
  programBrochure: programBrochureReducer,
  program: programReducer,
  client: clientReducer,
  adminApplications: adminApplicationsReducer,
  adminForms: adminFormsReducer,
  orgSettings: orgSettingsReducer,
  travelerSettings: travelerSettingsReducer,
  reports: reportsReducer,
  tags: tagsReducer,
  alternate_settings: programAlternateReducer,
  alerts: alertsReducer,
  programSettings: programSettingsReducer,
  adminInvitations: invitationsReducer,
  template: template,
  templates: templates,
  travelerApplications: travelerApplications,
  travelerForms: travelerForms,
  recommendations: recommendations,
  roles: roleReducer,
  travelerRankAlternative: travelerRankAlternative,
  visitorEvents: visitorEventsReducer,
  announcements: announcementsReducer,
  lookup: lookupReducer,
  plans: plans,
  termNames: termNamesReducer,
  safecheck: safecheckReducer,
  visitor: visitorReducer,
  programModify: programModifyReducer,
  authentication: authenticationReducer,
  forms: formsReducer,
  intakeForm: intakeFormReducer,
  programMatchForm: programMatchFormReducer,
  travelerProfile: travelerProfileReducer,
  currentOrganization: currentOrganizationReducer,
  travelerEvents: travelerEventsReducer,
  programDashboard: programDashboardReducerV2,
  travelerApplicationSubmissions: travelerApplicationsReducerV2,
  riskAlert: riskAlertReducer,
  automation: automationReducer,
  report_v2_data: reportV2Reducer,
  analyticsReports: analyticsReportsReducer,
  contracts: exchangeBalanceReducer
});
