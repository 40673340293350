import {
  FETCH_CONTINENTS,
  FETCH_TIMEZONES,
  FETCH_COUNTRIES,
  FETCH_ACTIVITY_TYPES,
  FETCH_HOUSING_TYPES,
  FETCH_TRANSPORTATION_TYPES,
  CLEAR_DETAIL_TYPES,
  FETCH_TRIP_TYPES,
  FETCH_PLAN_TYPES,
  FETCH_SAFECHECK_MESSAGE_TYPES,
  FETCH_PLAN_STATUS_TYPES,
  FETCH_REGISTRATION_STATUS_TYPES,
  FETCH_SUBJECT_AREAS,
  FETCH_PROGRAM_TYPES,
  FETCH_LANGUAGES,
  FETCH_PROGRAM_HOUSING_TYPES,
  FETCH_PROGRAM_CLIENT_ADMINS,
  FETCH_PROVIDER_CLIENT_ACCOUNTS,
  FETCH_RESIDENCY_STATUSES,
  FETCH_PASSPORT_STATUSES,
  FETCH_TRAVELER_TYPES,
  FETCH_YEAR_SCHOOL,
  FETCH_RACE_OPTIONS,
  FETCH_APPLICATION_STATUS_OPTIONS,
  FETCH_TRACKING_STEP_OPTIONS,
  FETCH_PROGRAM_PAYMENT_TYPES,
  FETCH_TRAVELER_BARRIERS_TYPES,
  FETCH_TRAVELER_CERTAINTY_TYPES,
  FETCH_TRAVELER_GOALS_TYPES,
  FETCH_TEMPLATES,
  FETCH_SUBMISSION_CONTENTS,
} from '../actions/types';

const initialState = {
  allContinents: {
    continents: null,
    loading: true,
    error: null,
  },
  allTimezones: {
    timezones: null,
    loading: true,
    error: null,
  },
  allCountries: {
    countries: null,
    loading: true,
    error: null,
  },
  allActivityTypes: {
    activityTypes: null,
    loading: true,
    error: null,
  },
  allHousingTypes: {
    housingTypes: null,
    loading: true,
    error: null,
  },
  allTransportationTypes: {
    transportationTypes: null,
    loading: true,
    error: null,
  },
  allTripTypes: {
    tripTypes: null,
    loading: true,
    error: null,
  },
  allPlanTypes: {
    planTypes: null,
    loading: true,
    error: null,
  },
  allSafecheckMessageTypes: {
    safecheckMessageTypes: null,
    loading: true,
    error: null,
  },
  allPlanStatusTypes: {
    planStatusTypes: null,
    loading: true,
    error: null,
  },
  allRegistrationStatusTypes: {
    registrationStatusTypes: null,
    loading: true,
    error: null,
  },
  allSubjectAreas: {
    subjectAreas: null,
    loading: true,
    error: null,
  },
  allProgramTypes: {
    programTypes: null,
    loading: true,
    error: null,
  },
  allLanguages: {
    languages: null,
    loading: true,
    error: null,
  },
  allProgramHousingTypes: {
    programHousingTypes: null,
    loading: true,
    error: null,
  },
  allClientAdmins: {
    clientAdmins: null,
    loading: true,
    error: null,
  },
  allProviderClientAccounts: {
    providerClientAccounts: null,
    loading: true,
    error: null,
  },
  allResidencyStatuses: {
    residencyStatuses: null,
    loading: true,
    error: null,
  },
  allPassportStatuses: {
    passportStatuses: null,
    loading: true,
    error: null,
  },
  allTravelerTypes: {
    travelerTypes: null,
    loading: false,
    error: null,
  },
  allYearSchool: {
    yearSchool: null,
    loading: false,
    error: null,
  },
  allRaceOptions: {
    raceOptions: null,
    loading: false,
    error: null,
  },
  allApplicationStatusOptions: {
    applicationStatusOptions: null,
    loading: false,
    error: null,
  },
  allTrackingStepOptions: {
    trackingStepOptions: null,
    loading: false,
    error: null,
  },
  allProgramPaymentOptions: {
    programPaymentOptions: null,
    loading: false,
    error: null,
  },
  allTravelerBarrierOptions: {
    travelerBarrierOptions: null,
    loading: false,
    error: null,
  },
  allTravelerCertaintyOptions: {
    travelerCertaintyOptions: null,
    loading: false,
    error: null,
  },
  allTravelerGoalOptions: {
    travelerGoalOptions: null,
    loading: false,
    error: null,
  },
  allTemplateOptions: {
    templateOptions: null,
    loading: false,
    error: null,
  },
  templateQuestions: {
    templateQuestions: null,
    loading: false,
    error: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRAVELER_BARRIERS_TYPES:
      return {
        ...state,
        allTravelerBarrierOptions: {
          travelerBarrierOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELER_CERTAINTY_TYPES:
      return {
        ...state,
        allTravelerCertaintyOptions: {
          travelerCertaintyOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELER_GOALS_TYPES:
      return {
        ...state,
        allTravelerGoalOptions: {
          travelerGoalOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_CONTINENTS:
      return {
        ...state,
        allContinents: {
          continents: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_TIMEZONES:
      return {
        ...state,
        allTimezones: {
          timezones: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        allCountries: {
          countries: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_ACTIVITY_TYPES:
      return {
        ...state,
        allActivityTypes: {
          activityTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_HOUSING_TYPES:
      return {
        ...state,
        allHousingTypes: {
          housingTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRANSPORTATION_TYPES:
      return {
        ...state,
        allTransportationTypes: {
          transportationTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case CLEAR_DETAIL_TYPES:
      return {
        ...state,
        allTransportationTypes: {
          templateOptions: null,
          loading: false,
          error: null,
        },
        allHousingTypes: {
          housingTypes: null,
          loading: false,
          error: null,
        },
        allActivityTypes: {
          activityTypes: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRIP_TYPES:
      return {
        ...state,
        allTripTypes: {
          tripTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PLAN_TYPES:
      return {
        ...state,
        allPlanTypes: {
          planTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SAFECHECK_MESSAGE_TYPES:
      return {
        ...state,
        allSafecheckMessageTypes: {
          safecheckMessageTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_REGISTRATION_STATUS_TYPES:
      return {
        ...state,
        allRegistrationStatusTypes: {
          registrationStatusTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PLAN_STATUS_TYPES:
      return {
        ...state,
        allPlanStatusTypes: {
          planStatusTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SUBJECT_AREAS:
      return {
        ...state,
        allSubjectAreas: {
          subjectAreas: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAM_TYPES:
      return {
        ...state,
        allProgramTypes: {
          programTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_LANGUAGES:
      return {
        ...state,
        allLanguages: {
          languages: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAM_HOUSING_TYPES:
      return {
        ...state,
        allProgramHousingTypes: {
          programHousingTypes: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAM_CLIENT_ADMINS:
      return {
        ...state,
        allClientAdmins: {
          clientAdmins: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROVIDER_CLIENT_ACCOUNTS:
      return {
        ...state,
        allProviderClientAccounts: {
          providerClientAccounts: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_RESIDENCY_STATUSES:
      return {
        ...state,
        allResidencyStatuses: {
          residencyStatuses: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_PASSPORT_STATUSES:
      return {
        ...state,
        allPassportStatuses: {
          passportStatuses: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELER_TYPES:
      return {
        ...state,
        allTravelerTypes: {
          travelerTypes: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_YEAR_SCHOOL:
      return {
        ...state,
        allYearSchool: {
          yearSchool: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_RACE_OPTIONS:
      return {
        ...state,
        allRaceOptions: {
          raceOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_APPLICATION_STATUS_OPTIONS:
      return {
        ...state,
        allApplicationStatusOptions: {
          applicationStatusOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRACKING_STEP_OPTIONS:
      return {
        ...state,
        allTrackingStepOptions: {
          trackingStepOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAM_PAYMENT_TYPES:
      return {
        ...state,
        allProgramPaymentOptions: {
          programPaymentOptions: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TEMPLATES:
      return {
        ...state,
        allTemplateOptions: {
          templateOptions: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SUBMISSION_CONTENTS:
      return {
        ...state,
        allTemplateOptions: {
          templateOptions: action.payload.data,
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
}
