import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import _remove from 'lodash/remove';

import {
  FETCH_ADMIN_APPLICATIONS,
  SEARCH_ADMIN_APPLICATIONS,
  INACTIVATE_APPLICATION,
  DELETE_APPLICATION,
  PUBLISH_APPLICATION,
  DUPLICATE_APPLICATION,
  CLIENT_FETCH_TRAVELER_APPLICATION,
  SUBMITTED_APPLICATIONS,
  SELECTED_APPLICATION,
  UPDATED_STATUS_RESPONSE,
  CLEAR_FLASH,
  FETCH_APPLICATION_DASHBOARD_FILTERS,
  DATA_LOADED,
  DATA_LOADING,
  RESET_FILTER,
  QUESTION_TYPES,
  LOAD_MORE_SUBMITTED_APPLICATIONS,
  CLEAR_PAGE,
  LOAD_FILTER_DATA,
  ADD_FILTER,
  DELETE_SEARCH_FILTER,
  SET_SELECTED_ROWS,
  ACCESS_DENIED,
  ADMIN_UPDATE_APPLICATION_PAYMENT_STATUS,
} from '../actions/types';

const initialState = {
  applications: [],
  loading: true,
  query: '',
  publishCode: null,
  unpublishCode: null,
  deleteCode: null,
  duplicateCode: null,
  applicationId: null,
  program: {},
  template: {},
  submission: {},
  answers: [],
  cashnetCodes: [],
  applicationLoading: true,
  submittedApplications: [],
  selectedRows: [],
  appDashboardFilters: {
    filters: {
      trm_application_templates: [],
      trm_form_templates: [],
      programs: [],
      program_range_term_name: [],
      term_statuses: [],
      program_countries: [],
      traveler_info_types: [],
      traveler_info_residency: [],
      traveler_countries_of_citizenship: [],
      financial_aid: [],
      tags: [],
      program_locations: [],
      internal_program_contacts: [],
      authorized_program_orgs: [],
      archived_trm_apps: [],
      school_names: [],
      colleges: [],
      primary_major: [],
      secondary_major: [],
      primary_minor: [],
      secondary_minor: [],
      third_minor: [],
      year_in_school: [],
      grad_year: [],
      honors_statuses: [],
      archived_templates: [],
      questions: [],
      program_types: [],
      is_alternate: false,
      internal_program_administrators: [],
      internal_program_archive_administrators: [],
      authorized_programs: [],
      archived_authorized_programs: [],
    },
  },
  selected_applications: [],
  update_status_response: [],
  update_status_success: null,
  page: 1,
  moreLoading: true,
  searchFilters: [],
  messageSuccess: null,
  accessDenied: null,
};

export default function(state = initialState, action) {
  let loading;
  let prevApplications;
  let prevSearchFilters;

  switch (action.type) {
    case ACCESS_DENIED:
      return {
        accessDenied: action,
        applicationLoading: false,
      };
    case FETCH_ADMIN_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
        loading: false,
      };
    case SEARCH_ADMIN_APPLICATIONS:
      return {
        ...state,
        query: action.query,
      };
    case INACTIVATE_APPLICATION:
      return {
        ...state,
        inactivateCode: action.code,
        publishCode: null,
        deleteCode: null,
        duplicateCode: null,
        applications: updateStatus([...state.applications], action.app_id, 2),
      };
    case DELETE_APPLICATION:
      prevApplications = [...state.applications];
      prevApplications.splice(prevApplications.findIndex(x => x.id === action.app_id), 1);
      return {
        ...state,
        inactivateCode: null,
        publishCode: null,
        deleteCode: action.code,
        duplicateCode: null,
        applications: prevApplications,
      };
    case PUBLISH_APPLICATION:
      return {
        ...state,
        inactivateCode: null,
        publishCode: action.code,
        deleteCode: null,
        duplicateCode: null,
        applications: updateStatus([...state.applications], action.app_id, 1),
      };
    case DUPLICATE_APPLICATION:
      return {
        ...state,
        applications: [action.applicationDup, ...state.applications],
        loading,
        duplicateCode: action.code,
        publishCode: null,
        deleteCode: null,
        inactivateCode: null,
      };
    case CLIENT_FETCH_TRAVELER_APPLICATION:
      return {
        ...state,
        program: action.payload.program,
        template: action.payload.trm_template,
        submission: action.payload.submission,
        answers: action.payload.answers,
        cashnetCodes: action.payload.cashnetCodes,
        applicationLoading: false,
      };
    case SUBMITTED_APPLICATIONS:
      return {
        ...state,
        submittedApplications: action.payload,
        page: state.page + 1,
        loading: false,
        selectedRows:
          !_isEmpty(action.payload.trm_submissions) && action.payload.trm_submissions.map(x => x.traveler_id),
      };
    case CLEAR_PAGE:
      return {
        ...state,
        page: 1,
        moreLoading: false,
        loading: true,
      };
    case LOAD_MORE_SUBMITTED_APPLICATIONS:
      let sapp = _cloneDeep(state.submittedApplications);
      sapp['trm_submissions'] = [...state.submittedApplications.trm_submissions, ...action.payload.trm_submissions];
      return {
        ...state,
        submittedApplications: sapp,
        selectedRows: sapp.trm_submissions.map(x => x.traveler_id),
        page: state.page + 1,
        loading: false,
        moreLoading: action.payload.trm_submissions.length > 0 ? true : false,
      };
    case LOAD_FILTER_DATA:
      return {
        ...state,
        submittedApplications: action.payload,
        page: state.page + 1,
        loading: false,
        moreLoading: action.payload.trm_submissions.length > 0 ? true : false,
        selectedRows:
          !_isEmpty(action.payload.trm_submissions) && action.payload.trm_submissions.map(x => x.traveler_id),
      };
    case SELECTED_APPLICATION:
      return {
        ...state,
        selected_applications: updateApplicationIds([...state.selected_applications], action.payload, action.status),
      };
    case UPDATED_STATUS_RESPONSE:
      let ids = action.data.applications.map(x => x.id);
      let status = action.data.applications[0].status;
      let app_copy = _cloneDeep(state.submittedApplications);
      let filtered_objects = _filter(app_copy.trm_submissions, v => _includes(ids, v.id));

      _forEach(filtered_objects, x => {
        x.status = status;
      });
      return {
        ...state,
        submittedApplications: app_copy,
        update_status_success: action.data.success,
        selectedRows: app_copy.trm_submissions.map(x => x.traveler_id),
      };
    case CLEAR_FLASH:
      return {
        ...state,
        update_status_success: null,
        messageSuccess: null,
      };
    case FETCH_APPLICATION_DASHBOARD_FILTERS:
      return {
        ...state,
        appDashboardFilters: action.payload,
        searchFilters: action.payload.search_filters,
      };
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DATA_LOADED:
      return {
        ...state,
        loading: false,
      };
    case RESET_FILTER: {
      return {
        ...state,
        page: 1,
        appDashboardFilters: {
          filters: {
            trm_application_templates: [],
            trm_form_templates: [],
            programs: [],
            program_range_term_name: [],
            traveler_info_types: [],
            traveler_info_residency: [],
            traveler_info_country_of_citizenship: [],
            financial_aid: [],
            tags: [],
            program_locations: [],
            internal_program_contacts: [],
            internal_program_administrators: [],
            internal_program_archive_administrators: [],
            authorized_programs: [],
            archived_authorized_programs: [],
            authorized_program_orgs: [],
            archived_trm_apps: [],
            school_names: [],
            colleges: [],
            primary_major: [],
            secondary_major: [],
            primary_minor: [],
            secondary_minor: [],
            third_minor: [],
            year_in_school: [],
            grad_year: [],
            honors_statuses: [],
            archived_templates: [],
            questions: [],
            program_types: [],
            is_alternate: false,
          },
        },
      };
    }
    case QUESTION_TYPES:
      return {
        ...state,
        appDashboardFilters: {
          ...state.appDashboardFilters,
          filters: {
            ...state.appDashboardFilters.filters,
            questions: action.payload,
          },
        },
      };
    case ADD_FILTER:
      prevSearchFilters = [...state.searchFilters];
      let added_filter = {
        ...action.payload.search_filter,
        filter: JSON.parse(action.payload.search_filter.filter),
      };
      prevSearchFilters.push(added_filter);
      return {
        ...state,
        searchFilters: prevSearchFilters,
      };
    case DELETE_SEARCH_FILTER:
      prevSearchFilters = [...state.searchFilters];
      prevSearchFilters.splice(prevSearchFilters.findIndex(x => x.id === action.payload.appliedFilterId), 1);
      return {
        ...state,
        searchFilters: prevSearchFilters,
      };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };
    case ADMIN_UPDATE_APPLICATION_PAYMENT_STATUS:
      return {
        ...state,
        answers: updatePaymentState(state.answers, action.payload)
      }
    default:
      return state;
  }
}

const updateStatus = (applications, app_id, newStatus) => {
  applications.find(app => {
    if (app.id === app_id) {
      app.status = newStatus;
      return true;
    }
  });
  return applications;
};

const updateApplicationIds = (selected_applications, app_id, status) => {
  if (status) {
    selected_applications.push(app_id);
  } else {
    _remove(selected_applications, x => x == app_id);
  }

  return selected_applications;
};

const updatePaymentState = (_answers = [], payload) => {
  let answers = _cloneDeep(_answers);
  const answerIdx = answers?.findIndex(ans => ans.element_id === payload.element_id);
  if (answerIdx > -1) {
    answers[answerIdx] = payload;
  } else {
    answers.unshift(payload);
  }

  return answers;
}