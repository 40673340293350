exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3E85k{margin-top:18px}._1UPrI{background-image:url(\"/img/sample_bg.jpg\");display:-ms-flexbox;min-height:100vh;height:100%;background-size:contain;display:flex;-ms-flex-pack:center;justify-content:center}.qo36B{text-align:center;margin-top:40px;width:40%}.gyRE1{font-size:77px;font-style:italic;font-weight:700;margin-bottom:30px}._2m56h{font-size:18px;margin-bottom:45px}._3qdkB{margin-top:20px}._2m56h a{cursor:pointer}._1KsBG{height:300px}@media only screen and (max-width:767px){._1UPrI{display:block;background-position-y:12rem;background-repeat:no-repeat}}", ""]);

// exports
exports.locals = {
	"headerPrimary": "_3E85k",
	"backgroundImg": "_1UPrI",
	"contentDiv": "qo36B",
	"titleOfError": "gyRE1",
	"messageOfError": "_2m56h",
	"mT20": "_3qdkB",
	"contentErrorImage": "_1KsBG"
};