import {
  GET_REPORT,
  GET_AVAILABLE_REPORTS,
  FETCH_TRAVELERS_REPORT_REQUEST,
  FETCH_TRAVELERS_REPORT_SUCCESS,
  FETCH_TRAVELERS_REPORT_FAILURE,
  FETCH_TRAVELERS_REPORT_CLEAR,
  FETCH_PROGRAMS_REPORT_REQUEST,
  FETCH_PROGRAMS_REPORT_SUCCESS,
  FETCH_PROGRAMS_REPORT_FAILURE,
  FETCH_PROGRAMS_REPORT_CLEAR,
  FETCH_APPLICATIONS_REPORT_REQUEST,
  FETCH_APPLICATIONS_REPORT_SUCCESS,
  FETCH_APPLICATIONS_REPORT_FAILURE,
  FETCH_APPLICATIONS_REPORT_CLEAR,
  FETCH_FORMS_REPORT_REQUEST,
  FETCH_FORMS_REPORT_SUCCESS,
  FETCH_FORMS_REPORT_FAILURE,
  FETCH_FORMS_REPORT_CLEAR,
  FETCH_REPORT_FILTERS_REQUEST,
  FETCH_REPORT_FILTERS_SUCCESS,
  FETCH_REPORT_FILTERS_FAILURE,
  FETCH_REPORT_FILTERS_CLEAR,
  UPDATE_REPORT_FILTERS_REQUEST,
  UPDATE_REPORT_FILTERS_SUCCESS,
  UPDATE_REPORT_FILTERS_CLEAR,
  UPDATE_REPORT_FILTERS_FAILURE,
  FETCH_INTERNAL_PROGRAMS_REQUEST,
  FETCH_INTERNAL_PROGRAMS_SUCCESS,
  FETCH_INTERNAL_PROGRAMS_FAILURE,
  FETCH_INTERNAL_PROGRAMS_CLEAR,
  FETCH_EXTERNAL_PROGRAMS_REQUEST,
  FETCH_EXTERNAL_PROGRAMS_SUCCESS,
  FETCH_EXTERNAL_PROGRAMS_FAILURE,
  FETCH_EXTERNAL_PROGRAMS_CLEAR,
  FETCH_PROGRAM_TERMS_REQUEST,
  FETCH_PROGRAM_TERMS_SUCCESS,
  FETCH_PROGRAM_TERMS_FAILURE,
  FETCH_PROGRAM_TERMS_CLEAR,
  PUBLISH_INTERNAL_PROGRAM,
  PUBLISH_INTERNAL_PROGRAM_SUCCESS,
  PUBLISH_INTERNAL_PROGRAM_FAILURE,
  PUBLISH_INTERNAL_PROGRAM_CLEAR,
  UNPUBLISH_INTERNAL_PROGRAM,
  UNPUBLISH_INTERNAL_PROGRAM_SUCCESS,
  UNPUBLISH_INTERNAL_PROGRAM_FAILURE,
  UNPUBLISH_INTERNAL_PROGRAM_CLEAR,
  BATCH_ADD_TERM,
  BATCH_ADD_TERM_SUCCESS,
  BATCH_ADD_TERM_FAILURE,
  BATCH_ADD_TERM_CLEAR,
  CREATE_REPORT_FILTERS_REQUEST,
  CREATE_REPORT_FILTERS_SUCCESS,
  CREATE_REPORT_FILTERS_FAILURE,
  CREATE_REPORT_FILTERS_CLEAR,
  DELETE_REPORT_FILTERS_REQUEST,
  DELETE_REPORT_FILTERS_SUCCESS,
  DELETE_REPORT_FILTERS_FAILURE,
  DELETE_REPORT_FILTERS_CLEAR,
  BATCH_ADD_APPLICATION,
  BATCH_ADD_APPLICATION_SUCCESS,
  BATCH_ADD_APPLICATION_FAILURE,
  BATCH_ADD_APPLICATION_CLEAR,
  BATCH_ADD_FORM,
  BATCH_ADD_FORM_SUCCESS,
  BATCH_ADD_FORM_FAILURE,
  BATCH_ADD_FORM_CLEAR,
  AUTHORIZE_EXTERNAL_PROGRAM,
  AUTHORIZE_EXTERNAL_PROGRAM_SUCCESS,
  AUTHORIZE_EXTERNAL_PROGRAM_FAILURE,
  AUTHORIZE_EXTERNAL_PROGRAM_CLEAR,
  UNAUTHORIZE_EXTERNAL_PROGRAM,
  UNAUTHORIZE_EXTERNAL_PROGRAM_SUCCESS,
  UNAUTHORIZE_EXTERNAL_PROGRAM_FAILURE,
  UNAUTHORIZE_EXTERNAL_PROGRAM_CLEAR,
  FETCH_APPLICATION_DATA_REQUEST,
  FETCH_APPLICATION_DATA_SUCCESS,
  FETCH_APPLICATION_DATA_FAILURE,
  FETCH_APPLICATION_DATA_CLEAR,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_REQUEST,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_SUCCESS,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_FAILURE,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_CLEAR,
  TRAVELER_FORMS_REQUEST,
  TRAVELER_FORMS_SUCCESS,
  TRAVELER_FORMS_FAILURE,
  TRAVELER_FORMS_CLEAR,
  BATCH_ADD_AUTH_TERM,
  BATCH_ADD_AUTH_TERM_SUCCESS,
  BATCH_ADD_AUTH_TERM_FAILURE,
  BATCH_ADD_AUTH_TERM_CLEAR,
  BATCH_UPDATE_AUTH_TERM,
  BATCH_UPDATE_AUTH_TERM_SUCCESS,
  BATCH_UPDATE_AUTH_TERM_FAILURE,
  BATCH_UPDATE_AUTH_TERM_CLEAR,
  GET_AUTH_TERMS,
  GET_FORM_TEMPLATES,
  GET_APPLICATION_TEMPLATES,
  FETCH_TRAVELERS_REQUEST,
  FETCH_TRAVELERS_SUCCESS,
  FETCH_TRAVELERS_FAILURE,
  FETCH_TRAVELERS_CLEAR,
  BATCH_UPDATE_CUSTOM_FIELD,
  BATCH_UPDATE_CUSTOM_FIELD_SUCCESS,
  BATCH_UPDATE_CUSTOM_FIELD_CLEAR,
  BATCH_UPDATE_CUSTOM_FIELD_FAILURE,
  BATCH_ADD_MESSAGE_RECIPIENT,
  BATCH_ADD_MESSAGE_RECIPIENT_CLEAR,
  BATCH_ADD_MESSAGE_RECIPIENT_SUCCESS,
  BATCH_ADD_MESSAGE_RECIPIENT_FAILURE,
  BATCH_REMOVE_MESSAGE_RECIPIENT,
  BATCH_REMOVE_MESSAGE_RECIPIENT_CLEAR,
  BATCH_REMOVE_MESSAGE_RECIPIENT_FAILURE,
  BATCH_REMOVE_MESSAGE_RECIPIENT_SUCCESS,
  BATCH_INVITE_TRAVELERS_TO_EVENT,
  BATCH_INVITE_TRAVELERS_TO_EVENT_SUCCESS,
  BATCH_INVITE_TRAVELERS_TO_EVENT_FAILURE,
  BATCH_INVITE_TRAVELERS_TO_EVENT_CLEAR,
  BATCH_ADD_FORMS_TO_TRAVELERS,
  BATCH_ADD_FORMS_TO_TRAVELERS_SUCCESS,
  BATCH_ADD_FORMS_TO_TRAVELERS_FAILURE,
  BATCH_ADD_FORMS_TO_TRAVELERS_CLEAR,
  FETCH_EVENTS_REPORT_REQUEST,
  FETCH_EVENTS_REPORT_SUCCESS,
  FETCH_EVENTS_REPORT_FAILURE,
  FETCH_EVENTS_REPORT_CLEAR,
  FETCH_EVENT_PARTICIPANTS_REPORT_REQUEST,
  FETCH_EVENT_PARTICIPANTS_REPORT_SUCCESS,
  FETCH_EVENT_PARTICIPANTS_REPORT_FAILURE,
  FETCH_EVENT_PARTICIPANTS_REPORT_CLEAR,
  FETCH_TRAVELER_PROGRAMS_REQUEST,
  FETCH_TRAVELER_PROGRAMS_SUCCESS,
  FETCH_TRAVELER_PROGRAMS_FAILURE,
  FETCH_TRAVELER_PROGRAMS_CLEAR,
  UNASSIGN_SUITCASES_REQUEST,
  UNASSIGN_SUITCASES_SUCCESS,
  UNASSIGN_SUITCASES_CLEAR,
  UNASSIGN_SUITCASES_FAILURE,
  FETCH_HORIZONS_BROWN_REQUEST,
  FETCH_HORIZONS_BROWN_SUCCESS,
  FETCH_HORIZONS_BROWN_FAILURE,
  FETCH_HORIZONS_BROWN_CLEAR,
  APPLICATION_STATUS_UPDATE_REQUEST,
  APPLICATION_STATUS_UPDATE_SUCCESS,
  APPLICATION_STATUS_UPDATE_FAILURE,
  APPLICATION_STATUS_UPDATE_CLEAR,
  FORM_STATUS_UPDATE_REQUEST,
  FORM_STATUS_UPDATE_SUCCESS,
  FORM_STATUS_UPDATE_FAILURE,
  FORM_STATUS_UPDATE_CLEAR,
  FETCH_FORM_STATUS_OPTIONS_REQUEST,
  FETCH_FORM_STATUS_OPTIONS_CLEAR,
  FETCH_FORM_STATUS_OPTIONS_FAILURE,
  FETCH_FORM_STATUS_OPTIONS_SUCCESS,
  FETCH_APP_STATUS_OPTIONS_REQUEST,
  FETCH_APP_STATUS_OPTIONS_CLEAR,
  FETCH_APP_STATUS_OPTIONS_FAILURE,
  FETCH_APP_STATUS_OPTIONS_SUCCESS,
  TRAVELER_FORMS_TEMPLATE_REQUEST,
  TRAVELER_FORMS_TEMPLATE_SUCCESS,
  TRAVELER_FORMS_TEMPLATE_FAILURE,
  TRAVELER_FORMS_TEMPLATE_CLEAR,
  FETCH_APPLICATION_TEMPLATE_DATA_REQUEST,
  FETCH_APPLICATION_TEMPLATE_DATA_SUCCESS,
  FETCH_APPLICATION_TEMPLATE_DATA_FAILURE,
  FETCH_APPLICATION_TEMPLATE_DATA_CLEAR,
  FETCH_SFSU_DATA_REQUEST,
  FETCH_SFSU_DATA_SUCCESS,
  FETCH_SFSU_DATA_FAILURE,
  FETCH_SFSU_DATA_CLEAR,
  GET_STANDARD_REPORTS_REQUEST,
  GET_STANDARD_REPORTS_SUCCESS,
  GET_STANDARD_REPORTS_FAILURE,
  GET_STANDARD_REPORT_NOTIFICATION_REQUEST,
  GET_STANDARD_REPORT_NOTIFICATION_SUCCESS,
  GET_STANDARD_REPORT_NOTIFICATION_FAILURE,
  DELETE_STANDARD_REPORT_REQUEST,
  DELETE_STANDARD_REPORT_SUCCESS,
  DELETE_STANDARD_REPORT_FAILURE,
  MARK_READ_NOTIFICATION_REQUEST,
  MARK_READ_NOTIFICATION_SUCCESS,
  MARK_READ_NOTIFICATION_FAILURE,
  MARK_ALL_READ_NOTIFICATION_REQUEST,
  MARK_ALL_READ_NOTIFICATION_SUCCESS,
  MARK_ALL_READ_NOTIFICATION_FAILURE,
  GET_LIVE_REPORT_FLAG_REQUEST,
  GET_LIVE_REPORT_FLAG_SUCCESS,
  GET_LIVE_REPORT_FLAG_FAILURE,
  UPDATE_LIVE_REPORT_FLAG_REQUEST,
  UPDATE_LIVE_REPORT_FLAG_SUCCESS,
  UPDATE_LIVE_REPORT_FLAG_FAILURE,
  ADD_APPLICATION_TAGS_REQUEST,
  ADD_APPLICATION_TAGS_SUCCESS,
  ADD_APPLICATION_TAGS_CLEAR,
  ADD_APPLICATION_TAGS_FAILURE,
  REMOVE_APPLICATION_TAGS_REQUEST,
  REMOVE_APPLICATION_TAGS_SUCCESS,
  REMOVE_APPLICATION_TAGS_CLEAR,
  REMOVE_APPLICATION_TAGS_FAILURE,
  PLAN_FORM_STATUS_UPDATE_REQUEST,
  PLAN_FORM_STATUS_UPDATE_SUCCESS,
  PLAN_FORM_STATUS_UPDATE_FAILURE,
  PLAN_FORM_STATUS_UPDATE_CLEAR,
  FETCH_TRAVELERS_ACTIVITY_REQUEST,
  FETCH_TRAVELERS_ACTIVITY_SUCCESS,
  FETCH_TRAVELERS_ACTIVITY_CLEAR,
  FETCH_TRAVELERS_ACTIVITY_FAILURE,


} from '../actions/types';

const initialState = {
  report: [],
  fetchFormStatusOptions: {
    data: null,
    loading: false,
    error: null,
  },
  fetchAppStatusOptions: {
    data: null,
    loading: false,
    error: null,
  },
  fetchTravelersReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchApplicationsReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchFormsReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchProgramsReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchReportFilters: {
    data: null,
    loading: false,
    error: null,
  },
  fetchInternalPrograms: {
    data: null,
    loading: true,
    error: null,
  },
  fetchExternalPrograms: {
    data: null,
    loading: true,
    error: null,
  },
  fetchUnauthorizedExternalPrograms: {
    data: null,
    loading: true,
    error: null,
  },
  publishInternalProgram: {
    data: null,
    loading: false,
    error: null,
  },
  unpublishInternalProgram: {
    data: null,
    loading: false,
    error: null,
  },
  createReportFilter: {
    data: null,
    loading: false,
    error: null,
  },
  deleteReportFilter: {
    data: null,
    loading: false,
    error: null,
  },
  fetchProgramTerms: {
    data: null,
    loading: true,
    error: null,
  },
  batchAddTerm: {
    data: null,
    loading: false,
    error: null,
  },
  batchAddApplication: {
    data: null,
    loading: false,
    error: null,
  },
  batchAddForm: {
    data: null,
    loading: false,
    error: null,
  },
  authorizeExternalProgram: {
    data: null,
    loading: false,
    error: null,
  },
  unauthorizeExternalProgram: {
    data: null,
    loading: false,
    error: null,
  },
  updateReportFilter: {
    data: null,
    loading: false,
    error: null,
  },
  fetchApplicationData: {
    data: null,
    loading: true,
    error: null,
  },
  fetchApplicationTemplateData: {
    data: null,
    loading: true,
    error: null,
  },
  fetchTravelerFormsData: {
    data: null,
    loading: true,
    error: null,
  },
  fetchTravelerFormsTemplateData: {
    data: null,
    loading: true,
    error: null,
  },
  authTerms: {
    data: null,
    loading: true,
    error: null,
  },
  updateAuthTerms: {
    data: null,
    loading: true,
    error: null,
    action: 'updateAuthTerms',
  },
  batchAddAuthTerm: {
    data: null,
    loading: false,
    error: null,
    action: 'batchAddAuthTerm',
  },
  updateCustomField: {
    data: null,
    loading: true,
    error: null,
    action: 'batchUpdateCustomField',
  },
  updateMessageRecipient: {
    data: null,
    loading: true,
    error: null,
    action: 'updateMessageRecipient',
  },
  formTemplates: {
    data: null,
    loading: false,
    error: null,
  },
  applicationTemplates: {
    data: null,
    loading: false,
    error: null,
  },
  fetchTravelerData: {
    data: null,
    loading: false,
    error: null,
  },
  fetchTravelerActivityData: {
    data: null,
    loading: false,
    error: null,
  },
  batchInviteTravelersToEvent: {
    data: null,
    loading: false,
    error: null,
    action: 'batchInviteTravelersToEvent',
  },
  batchAddFormsToTravelers: {
    data: null,
    loading: false,
    error: null,
    action: 'batchAddFormsToTravelers',
  },
  fetchEventsReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchEventParticipantsReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchTravelerPrograms: {
    data: null,
    loading: true,
    error: null,
  },
  unassignSuitcases: {
    data: null,
    loading: true,
    error: null,
  },
  fetchHorizonsBrownReport: {
    data: null,
    loading: false,
    error: null,
  },
  fetchSFSUData: {
    data: null,
    loading: false,
    error: null,
  },
  updateApplicationStatus: {
    data: null,
    loading: false,
    error: null,
  },
  updatePlanFormStatus: {
    data: null,
    loading: false,
    error: null,
  },
  updateFormStatus: {
    data: null,
    loading: false,
    error: null,
  },
  reportDownloads: {
    data: [],
    loading: false,
    error: null,
  },
  reportDownloadNotifications: {
    data: [],
    loading: false,
    error: null,
  },
  deleteStandardReport: {
    deletingId: '',
    loading: false,
    error: null,
  },
  markReadNotification: {
    readingId: '',
    loading: false,
    error: null,
  },
  liveReportFlag: {
    loading: false,
    flag: false,
    error: null,
  },
  updateLiveReportFlag: {
    loading: false,
    error: null,
  },
  addApplicationTags: {
    data: null,
    loading: false,
    error: null,
  },
  removeApplicationTags: {
    data: null,
    loading: false,
    error: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORM_STATUS_OPTIONS_REQUEST:
      return {
        ...state,
        fetchFormStatusOptions: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_FORM_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchFormStatusOptions: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_FORM_STATUS_OPTIONS_FAILURE:
      return {
        ...state,
        fetchFormStatusOptions: {
          data: null,
          loading: false,
          error: 'error fetching status options',
        },
      };
    case FETCH_FORM_STATUS_OPTIONS_CLEAR:
      return {
        ...state,
        fetchFormStatusOptions: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_APP_STATUS_OPTIONS_REQUEST:
      return {
        ...state,
        fetchAppStatusOptions: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_APP_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchAppStatusOptions: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_APP_STATUS_OPTIONS_FAILURE:
      return {
        ...state,
        fetchAppStatusOptions: {
          data: null,
          loading: false,
          error: 'error fetching status options',
        },
      };
    case FETCH_APP_STATUS_OPTIONS_CLEAR:
      return {
        ...state,
        fetchAppStatusOptions: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case GET_REPORT:
      return {
        ...state,
        report: action.payload,
      };
    case GET_AVAILABLE_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case FETCH_TRAVELERS_REPORT_REQUEST:
      return {
        ...state,
        fetchTravelersReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_TRAVELERS_REPORT_SUCCESS:
      return {
        ...state,
        fetchTravelersReport: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELERS_REPORT_FAILURE:
      return {
        ...state,
        fetchTravelersReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the travelers report.  Please try again later.',
        },
      };
    case FETCH_TRAVELERS_REPORT_CLEAR:
      return {
        ...state,
        fetchTravelersReport: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case FETCH_APPLICATIONS_REPORT_REQUEST:
      return {
        ...state,
        fetchApplicationsReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_APPLICATIONS_REPORT_SUCCESS:
      return {
        ...state,
        fetchApplicationsReport: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_APPLICATIONS_REPORT_FAILURE:
      return {
        ...state,
        fetchApplicationsReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the applications report.  Please try again later.',
        },
      };
    case FETCH_APPLICATIONS_REPORT_CLEAR:
      return {
        ...state,
        fetchApplicationsReport: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_FORMS_REPORT_REQUEST:
      return {
        ...state,
        fetchFormsReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_FORMS_REPORT_SUCCESS:
      return {
        ...state,
        fetchFormsReport: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_FORMS_REPORT_FAILURE:
      return {
        ...state,
        fetchFormsReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the forms report.  Please try again later.',
        },
      };
    case FETCH_FORMS_REPORT_CLEAR:
      return {
        ...state,
        fetchFormsReport: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAMS_REPORT_REQUEST:
      return {
        ...state,
        fetchProgramsReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_PROGRAMS_REPORT_SUCCESS:
      return {
        ...state,
        fetchProgramsReport: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAMS_REPORT_FAILURE:
      return {
        ...state,
        fetchProgramsReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs report.  Please try again later.',
        },
      };
    case FETCH_PROGRAMS_REPORT_CLEAR:
      return {
        ...state,
        fetchProgramsReport: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_REPORT_FILTERS_REQUEST:
      return {
        ...state,
        fetchReportFilters: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        fetchReportFilters: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_REPORT_FILTERS_FAILURE:
      return {
        ...state,
        fetchReportFilters: {
          data: null,
          loading: false,
          error: 'There was a problem loading the report filters.  Please try again later.',
        },
      };
    case FETCH_REPORT_FILTERS_CLEAR:
      return {
        ...state,
        fetchReportFilters: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_INTERNAL_PROGRAMS_REQUEST:
      return {
        ...state,
        fetchInternalPrograms: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_INTERNAL_PROGRAMS_SUCCESS:
      return {
        ...state,
        fetchInternalPrograms: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_INTERNAL_PROGRAMS_FAILURE:
      return {
        ...state,
        fetchInternalPrograms: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case FETCH_INTERNAL_PROGRAMS_CLEAR:
      return {
        ...state,
        fetchInternalPrograms: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_EXTERNAL_PROGRAMS_REQUEST:
      return {
        ...state,
        fetchExternalPrograms: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_EXTERNAL_PROGRAMS_SUCCESS:
      return {
        ...state,
        fetchExternalPrograms: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_EXTERNAL_PROGRAMS_FAILURE:
      return {
        ...state,
        fetchExternalPrograms: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case FETCH_EXTERNAL_PROGRAMS_CLEAR:
      return {
        ...state,
        fetchExternalPrograms: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_REQUEST:
      return {
        ...state,
        fetchUnauthorizedExternalPrograms: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_SUCCESS:
      return {
        ...state,
        fetchUnauthorizedExternalPrograms: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_FAILURE:
      return {
        ...state,
        fetchUnauthorizedExternalPrograms: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_CLEAR:
      return {
        ...state,
        fetchUnauthorizedExternalPrograms: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case PUBLISH_INTERNAL_PROGRAM:
      return {
        ...state,
        publishInternalProgram: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case PUBLISH_INTERNAL_PROGRAM_SUCCESS:
      return {
        ...state,
        publishInternalProgram: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case PUBLISH_INTERNAL_PROGRAM_FAILURE:
      return {
        ...state,
        publishInternalProgram: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case PUBLISH_INTERNAL_PROGRAM_CLEAR:
      return {
        ...state,
        publishInternalProgram: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case UNPUBLISH_INTERNAL_PROGRAM:
      return {
        ...state,
        unpublishInternalProgram: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case UNPUBLISH_INTERNAL_PROGRAM_SUCCESS:
      return {
        ...state,
        unpublishInternalProgram: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case UNPUBLISH_INTERNAL_PROGRAM_FAILURE:
      return {
        ...state,
        unpublishInternalProgram: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case UNPUBLISH_INTERNAL_PROGRAM_CLEAR:
      return {
        ...state,
        unpublishInternalProgram: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_TERM:
      return {
        ...state,
        batchAddTerm: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case BATCH_ADD_TERM_SUCCESS:
      return {
        ...state,
        batchAddTerm: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_TERM_FAILURE:
      return {
        ...state,
        batchAddTerm: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case BATCH_ADD_TERM_CLEAR:
      return {
        ...state,
        batchAddTerm: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAM_TERMS_REQUEST:
      return {
        ...state,
        fetchProgramTerms: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_PROGRAM_TERMS_SUCCESS:
      return {
        ...state,
        fetchProgramTerms: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_PROGRAM_TERMS_FAILURE:
      return {
        ...state,
        fetchProgramTerms: {
          data: null,
          loading: false,
          error: 'There was a problem loading the program terms.  Please try again later.',
        },
      };
    case FETCH_PROGRAM_TERMS_CLEAR:
      return {
        ...state,
        fetchProgramTerms: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case CREATE_REPORT_FILTERS_REQUEST:
      return {
        ...state,
        createReportFilter: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case CREATE_REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        createReportFilter: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case CREATE_REPORT_FILTERS_FAILURE:
      return {
        ...state,
        createReportFilter: {
          data: null,
          loading: false,
          error: 'There was a problem creating the report filter.  Please try again later.',
        },
      };
    case CREATE_REPORT_FILTERS_CLEAR:
      return {
        ...state,
        createReportFilter: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case DELETE_REPORT_FILTERS_REQUEST:
      return {
        ...state,
        deleteReportFilter: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case DELETE_REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        deleteReportFilter: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case DELETE_REPORT_FILTERS_FAILURE:
      return {
        ...state,
        deleteReportFilter: {
          data: null,
          loading: false,
          error: 'There was a problem deleting the report filter.  Please try again later.',
        },
      };
    case DELETE_REPORT_FILTERS_CLEAR:
      return {
        ...state,
        deleteReportFilter: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case UPDATE_REPORT_FILTERS_REQUEST:
      return {
        ...state,
        updateReportFilter: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case UPDATE_REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        updateReportFilter: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case UPDATE_REPORT_FILTERS_FAILURE:
      return {
        ...state,
        updateReportFilter: {
          data: null,
          loading: false,
          error: 'There was a problem updating the report filter.  Please try again later.',
        },
      };
    case UPDATE_REPORT_FILTERS_CLEAR:
      return {
        ...state,
        updateReportFilter: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_APPLICATION:
      return {
        ...state,
        batchAddApplication: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case BATCH_ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        batchAddApplication: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_APPLICATION_FAILURE:
      return {
        ...state,
        batchAddApplication: {
          data: null,
          loading: false,
          error: 'There was a problem loading the applications.  Please try again later.',
        },
      };
    case BATCH_ADD_APPLICATION_CLEAR:
      return {
        ...state,
        batchAddApplication: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case AUTHORIZE_EXTERNAL_PROGRAM:
      return {
        ...state,
        authorizeExternalProgram: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case AUTHORIZE_EXTERNAL_PROGRAM_SUCCESS:
      return {
        ...state,
        authorizeExternalProgram: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case AUTHORIZE_EXTERNAL_PROGRAM_FAILURE:
      return {
        ...state,
        authorizeExternalProgram: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case AUTHORIZE_EXTERNAL_PROGRAM_CLEAR:
      return {
        ...state,
        authorizeExternalProgram: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case UNAUTHORIZE_EXTERNAL_PROGRAM:
      return {
        ...state,
        unauthorizeExternalProgram: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case UNAUTHORIZE_EXTERNAL_PROGRAM_SUCCESS:
      return {
        ...state,
        unauthorizeExternalProgram: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case UNAUTHORIZE_EXTERNAL_PROGRAM_FAILURE:
      return {
        ...state,
        unauthorizeExternalProgram: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case UNAUTHORIZE_EXTERNAL_PROGRAM_CLEAR:
      return {
        ...state,
        unauthorizeExternalProgram: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_FORM:
      return {
        ...state,
        batchAddForm: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case BATCH_ADD_FORM_SUCCESS:
      return {
        ...state,
        batchAddForm: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_FORM_FAILURE:
      return {
        ...state,
        batchAddForm: {
          data: null,
          loading: false,
          error: 'There was a problem loading the forms.  Please try again later.',
        },
      };
    case BATCH_ADD_FORM_CLEAR:
      return {
        ...state,
        batchAddForm: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_APPLICATION_DATA_REQUEST:
      return {
        ...state,
        fetchApplicationData: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        fetchApplicationData: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_APPLICATION_DATA_FAILURE:
      return {
        ...state,
        fetchApplicationData: {
          data: null,
          loading: false,
          error: 'There was a problem loading the application data.  Please try again later.',
        },
      };
    case FETCH_APPLICATION_DATA_CLEAR:
      return {
        ...state,
        fetchApplicationData: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case FETCH_APPLICATION_TEMPLATE_DATA_REQUEST:
      return {
        ...state,
        fetchApplicationTemplateData: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_APPLICATION_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        fetchApplicationTemplateData: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_APPLICATION_TEMPLATE_DATA_FAILURE:
      return {
        ...state,
        fetchApplicationTemplateData: {
          data: null,
          loading: false,
          error: 'There was a problem loading the application data.  Please try again later.',
        },
      };
    case FETCH_APPLICATION_TEMPLATE_DATA_CLEAR:
      return {
        ...state,
        fetchApplicationTemplateData: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case TRAVELER_FORMS_REQUEST:
      return {
        ...state,
        fetchTravelerFormsData: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case TRAVELER_FORMS_SUCCESS:
      return {
        ...state,
        fetchTravelerFormsData: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case TRAVELER_FORMS_FAILURE:
      return {
        ...state,
        fetchTravelerFormsData: {
          data: null,
          loading: false,
          error: 'There was a problem loading the traveler forms.  Please try again later.',
        },
      };
    case TRAVELER_FORMS_CLEAR:
      return {
        ...state,
        fetchTravelerFormsData: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case TRAVELER_FORMS_TEMPLATE_REQUEST:
      return {
        ...state,
        fetchTravelerFormsTemplateData: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case TRAVELER_FORMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetchTravelerFormsTemplateData: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case TRAVELER_FORMS_TEMPLATE_FAILURE:
      return {
        ...state,
        fetchTravelerFormsTemplateData: {
          data: null,
          loading: false,
          error: 'There was a problem loading the traveler forms.  Please try again later.',
        },
      };
    case TRAVELER_FORMS_TEMPLATE_CLEAR:
      return {
        ...state,
        fetchTravelerFormsTemplateData: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case GET_AUTH_TERMS:
      return {
        ...state,
        authTerms: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_AUTH_TERM:
      return {
        ...state,
        batchAddAuthTerm: {
          data: null,
          loading: true,
          error: null,
          action: 'batchAddAuthTerm',
        },
      };
    case BATCH_ADD_AUTH_TERM_SUCCESS:
      return {
        ...state,
        batchAddAuthTerm: {
          data: action.payload.data,
          loading: false,
          error: null,
          action: 'batchAddAuthTerm',
        },
      };
    case BATCH_ADD_AUTH_TERM_FAILURE:
      return {
        ...state,
        batchAddAuthTerm: {
          data: null,
          loading: false,
          error: 'There was a problem loading authorized programs.  Please try again later.',
          action: 'batchAddAuthTerm',
        },
      };
    case BATCH_ADD_AUTH_TERM_CLEAR:
      return {
        ...state,
        batchAddAuthTerm: {
          data: null,
          loading: false,
          error: null,
          action: 'batchAddAuthTerm',
        },
      };
    case BATCH_UPDATE_AUTH_TERM:
      return {
        ...state,
        updateAuthTerms: {
          data: null,
          loading: true,
          error: null,
          action: 'updateAuthTerms',
        },
      };
    case BATCH_UPDATE_AUTH_TERM_SUCCESS:
      return {
        ...state,
        updateAuthTerms: {
          data: action.payload.data,
          loading: false,
          error: null,
          action: 'updateAuthTerms',
        },
      };
    case BATCH_UPDATE_AUTH_TERM_FAILURE:
      return {
        ...state,
        updateAuthTerms: {
          data: null,
          loading: false,
          error: 'There was a problem loading authorized programs.  Please try again later.',
          action: 'updateAuthTerms',
        },
      };
    case BATCH_UPDATE_AUTH_TERM_CLEAR:
      return {
        ...state,
        updateAuthTerms: {
          data: null,
          loading: false,
          error: null,
          action: 'updateAuthTerms',
        },
      };
    case GET_APPLICATION_TEMPLATES:
      return {
        ...state,
        applicationTemplates: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case GET_FORM_TEMPLATES:
      return {
        ...state,
        formTemplates: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELERS_REQUEST:
      return {
        ...state,
        fetchTravelerData: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_TRAVELERS_SUCCESS:
      return {
        ...state,
        fetchTravelerData: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELERS_FAILURE:
      return {
        ...state,
        fetchTravelerData: {
          data: null,
          loading: false,
          error: 'There was a problem loading the travelers.  Please try again later.',
        },
      };
    case FETCH_TRAVELERS_CLEAR:
      return {
        ...state,
        fetchTravelerData: {
          data: null,
          loading: false,
          error: null,
        },
      };
    
      case FETCH_TRAVELERS_ACTIVITY_REQUEST:
        return {
          ...state,
          fetchTravelerActivityData: {
            data: null,
            loading: true,
            error: null,
          },
        };
      case FETCH_TRAVELERS_ACTIVITY_SUCCESS:
        return {
          ...state,
          fetchTravelerActivityData: {
            data: action.payload,
            loading: false,
            error: null,
          },
        };
      case FETCH_TRAVELERS_ACTIVITY_FAILURE:
        return {
          ...state,
          fetchTravelerActivityData: {
            data: null,
            loading: false,
            error: 'There was a problem loading the travelers activity.  Please try again later.',
          },
        };
      case FETCH_TRAVELERS_ACTIVITY_CLEAR:
        return {
          ...state,
          fetchTravelerActivityData: {
            data: null,
            loading: false,
            error: null,
          },
        };  
    case BATCH_UPDATE_CUSTOM_FIELD:
      return {
        ...state,
        updateCustomField: {
          data: null,
          loading: true,
          error: null,
          action: 'batchUpdateCustomField',
        },
      };
    case BATCH_UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        updateCustomField: {
          data: action.payload.data,
          loading: false,
          error: null,
          action: 'batchUpdateCustomField',
        },
      };
    case BATCH_UPDATE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        updateCustomField: {
          data: null,
          loading: false,
          error: 'There was a problem updating custom fields.  Please try again later.',
          action: 'batchUpdateCustomField',
        },
      };
    case BATCH_UPDATE_CUSTOM_FIELD_CLEAR:
      return {
        ...state,
        updateCustomField: {
          data: null,
          loading: false,
          error: null,
          action: 'batchUpdateCustomField',
        },
      };
    case BATCH_ADD_MESSAGE_RECIPIENT:
      return {
        ...state,
        updateMessageRecipient: {
          data: null,
          loading: true,
          error: null,
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_ADD_MESSAGE_RECIPIENT_SUCCESS:
      return {
        ...state,
        updateMessageRecipient: {
          data: action.payload.data,
          loading: false,
          error: null,
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_ADD_MESSAGE_RECIPIENT_FAILURE:
      return {
        ...state,
        updateMessageRecipient: {
          data: null,
          loading: false,
          error: 'There was a problem updating the message recipient.  Please try again later.',
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_ADD_MESSAGE_RECIPIENT_CLEAR:
      return {
        ...state,
        updateMessageRecipient: {
          data: null,
          loading: false,
          error: null,
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_REMOVE_MESSAGE_RECIPIENT:
      return {
        ...state,
        updateMessageRecipient: {
          data: null,
          loading: true,
          error: null,
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_REMOVE_MESSAGE_RECIPIENT_SUCCESS:
      return {
        ...state,
        updateMessageRecipient: {
          data: action.payload.data,
          loading: false,
          error: null,
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_REMOVE_MESSAGE_RECIPIENT_FAILURE:
      return {
        ...state,
        updateMessageRecipient: {
          data: null,
          loading: false,
          error: 'There was a problem updating custom fields.  Please try again later.',
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_REMOVE_MESSAGE_RECIPIENT_CLEAR:
      return {
        ...state,
        updateMessageRecipient: {
          data: null,
          loading: false,
          error: null,
          action: 'updateMessageRecipient',
        },
      };
    case BATCH_INVITE_TRAVELERS_TO_EVENT:
      return {
        ...state,
        batchInviteTravelersToEvent: {
          data: null,
          loading: true,
          error: null,
          action: 'batchInviteTravelersToEvent',
        },
      };
    case BATCH_INVITE_TRAVELERS_TO_EVENT_SUCCESS:
      return {
        ...state,
        batchInviteTravelersToEvent: {
          data: action.payload.message.success ? `${action.payload.message.success}` : null,
          loading: false,
          error: action.payload.message.failure ? `${action.payload.message.failure}` : null,
          action: 'batchInviteTravelersToEvent',
        },
      };
    case BATCH_INVITE_TRAVELERS_TO_EVENT_FAILURE:
      return {
        ...state,
        batchInviteTravelersToEvent: {
          data: null,
          loading: false,
          error: 'There was a problem with inviting travelers to this event.  Please try again later.',
          action: 'batchInviteTravelersToEvent',
        },
      };
    case BATCH_INVITE_TRAVELERS_TO_EVENT_CLEAR:
      return {
        ...state,
        batchInviteTravelersToEvent: {
          data: null,
          loading: false,
          error: null,
          action: 'batchInviteTravelersToEvent',
        },
      };

    case BATCH_ADD_FORMS_TO_TRAVELERS:
      return {
        ...state,
        batchAddFormsToTravelers: {
          data: null,
          loading: true,
          error: null,
          action: 'batchAddFormsToTravelers',
        },
      };
    case BATCH_ADD_FORMS_TO_TRAVELERS_SUCCESS:
      return {
        ...state,
        batchAddFormsToTravelers: {
          data: action.payload.data,
          loading: false,
          error: null,
          action: 'batchAddFormsToTravelers',
        },
      };
    case BATCH_ADD_FORMS_TO_TRAVELERS_FAILURE:
      return {
        ...state,
        batchAddFormsToTravelers: {
          data: null,
          loading: false,
          error: 'There was a problem with inviting travelers to this event.  Please try again later.',
          action: 'batchAddFormsToTravelers',
        },
      };
    case BATCH_ADD_FORMS_TO_TRAVELERS_CLEAR:
      return {
        ...state,
        batchAddFormsToTravelers: {
          data: null,
          loading: false,
          error: null,
          action: 'batchAddFormsToTravelers',
        },
      };
    case FETCH_EVENTS_REPORT_REQUEST:
      return {
        ...state,
        fetchEventsReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_EVENTS_REPORT_SUCCESS:
      return {
        ...state,
        fetchEventsReport: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_EVENTS_REPORT_FAILURE:
      return {
        ...state,
        fetchEventsReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the events report.  Please try again later.',
        },
      };
    case FETCH_EVENTS_REPORT_CLEAR:
      return {
        ...state,
        fetchEventsReport: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_EVENT_PARTICIPANTS_REPORT_REQUEST:
      return {
        ...state,
        fetchEventParticipantsReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_EVENT_PARTICIPANTS_REPORT_SUCCESS:
      return {
        ...state,
        fetchEventParticipantsReport: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case FETCH_EVENT_PARTICIPANTS_REPORT_FAILURE:
      return {
        ...state,
        fetchEventParticipantsReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the event participants report.  Please try again later.',
        },
      };
    case FETCH_EVENT_PARTICIPANTS_REPORT_CLEAR:
      return {
        ...state,
        fetchEventParticipantsReport: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELER_PROGRAMS_REQUEST:
      return {
        ...state,
        fetchTravelerPrograms: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_TRAVELER_PROGRAMS_SUCCESS:
      return {
        ...state,
        fetchTravelerPrograms: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRAVELER_PROGRAMS_FAILURE:
      return {
        ...state,
        fetchTravelerPrograms: {
          data: null,
          loading: false,
          error: 'There was a problem loading the programs.  Please try again later.',
        },
      };
    case FETCH_TRAVELER_PROGRAMS_CLEAR:
      return {
        ...state,
        fetchTravelerPrograms: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case UNASSIGN_SUITCASES_REQUEST:
      return {
        ...state,
        unassignSuitcases: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case UNASSIGN_SUITCASES_SUCCESS:
      return {
        ...state,
        unassignSuitcases: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case UNASSIGN_SUITCASES_FAILURE:
      return {
        ...state,
        unassignSuitcases: {
          data: null,
          loading: false,
          error: 'There was a problem unassigning the suitcases.  Please try again later.',
        },
      };
    case UNASSIGN_SUITCASES_CLEAR:
      return {
        ...state,
        unassignSuitcases: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_HORIZONS_BROWN_REQUEST:
      return {
        ...state,
        fetchHorizonsBrownReport: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_HORIZONS_BROWN_SUCCESS:
      return {
        ...state,
        fetchHorizonsBrownReport: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_HORIZONS_BROWN_FAILURE:
      return {
        ...state,
        fetchHorizonsBrownReport: {
          data: null,
          loading: false,
          error: 'There was a problem loading the horizons report.  Please try again later.',
        },
      };
    case FETCH_HORIZONS_BROWN_CLEAR:
      return {
        ...state,
        fetchHorizonsBrownReport: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case APPLICATION_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        updateApplicationStatus: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case APPLICATION_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        updateApplicationStatus: {
          // data: action.data.message
          //   ? `${action.data.data.length - parseInt(action.data.message.split(' ')[3])} ${
          //       action.data.data.length === 1 ? 'application' : 'applications'
          //     } updated`
          //   : `${action.data.data.length} ${action.data.data.length === 1 ? 'application' : 'applications'} updated`,
          //data:  action.data.updated_submission_ids?.length > 0 ? `${action.data.updated_submission_ids?.length} ${(action.data.updated_submission_ids?.length === 1 ? 'application updated' : 'applications updated')}` : "", 
          data:  "Batch action is processing", 
          loading: false,
          error: action.data?.failures?.length > 0 ? `${action.data.failures.length} ${(action.data.failures.length === 1 ? ' status change blocked' : "status changes blocked")}` : ""
          // error: action.data.message
          //   ? `${parseInt(action.data.message.split(' ')[3])} status ${
          //       parseInt(action.data.message.split(' ')[3]) === 1 ? 'change' : 'changes'
          //     } blocked`
          //   : null,
        },
      };
    case APPLICATION_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        updateApplicationStatus: {
          data: null,
          loading: false,
          error: 'There was a problem updating the application status.  Please try again later.',
        },
      };
    case APPLICATION_STATUS_UPDATE_CLEAR:
      return {
        ...state,
        updateApplicationStatus: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FORM_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        updateFormStatus: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FORM_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        updateFormStatus: {
          // data: action.data.failures
          //   ? `${action.data.data.length + action.data.failures.length - action.data.failures.length} ${
          //       action.data.data.length === 1 ? 'form' : 'forms'
          //     } updated`
          //   : `${action.data.data.length} ${action.data.data.length === 1 ? 'form' : 'forms'} updated`,
          loading: false,
          // error:
          //   !!action.data.failures && action.data.failures.length > 0
          //     ? `${action.data.failures.length} status ${
          //         action.data.failures.length === 1 ? 'change' : 'changes'
          //       } blocked`
          //     : null,
          //data:  action.data.updated_submission_ids?.length > 0 ? `${action.data.updated_submission_ids?.length} ${(action.data.updated_submission_ids?.length === 1 ? 'form updated' : 'forms updated')}` : "", 
          data:   'Batch action is processing', 
          loading: false,
          error: action.data?.failures?.length > 0 ? `${action.data.failures.length} ${(action.data.failures.length === 1 ? ' status change blocked' : "status changes blocked")}` : ""


        },
      };
    case FORM_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        updateFormStatus: {
          data: null,
          loading: false,
          error: 'There was a problem updating the form status.  Please try again later.',
        },
      };
    case FORM_STATUS_UPDATE_CLEAR:
      return {
        ...state,
        updateFormStatus: {
          data: null,
          loading: false,
          error: null,
        },
      };

    
    case PLAN_FORM_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        updatePlanFormStatus: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case PLAN_FORM_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        updatePlanFormStatus: {
          // data: action.data.message
          //   ? `${action.data.data.length - parseInt(action.data.message.split(' ')[3])} ${
          //       action.data.data.length === 1 ? 'application' : 'applications'
          //     } updated`
          //   : `${action.data.data.length} ${action.data.data.length === 1 ? 'application' : 'applications'} updated`,
          data:  action.data.updated_submission_ids?.length > 0 ? `${action.data.updated_submission_ids?.length} ${(action.data.updated_submission_ids?.length === 1 ? 'application updated' : 'applications updated')}` : "", 
          loading: false,
          error: action.data?.failures?.length > 0 ? `${action.data.failures.length} ${(action.data.failures.length === 1 ? ' status change blocked' : "status changes blocked")}` : ""
          // error: action.data.message
          //   ? `${parseInt(action.data.message.split(' ')[3])} status ${
          //       parseInt(action.data.message.split(' ')[3]) === 1 ? 'change' : 'changes'
          //     } blocked`
          //   : null,
        },
      };
    case PLAN_FORM_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        updatePlanFormStatus: {
          data: null,
          loading: false,
          error: 'There was a problem updating the application status.  Please try again later.',
        },
      };
    case PLAN_FORM_STATUS_UPDATE_CLEAR:
      return {
        ...state,
        updatePlanFormStatus: {
          data: null,
          loading: false,
          error: null,
        },
      };



    case FETCH_SFSU_DATA_REQUEST:
      return {
        ...state,
        fetchSFSUData: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_SFSU_DATA_SUCCESS:
      return {
        ...state,
        fetchSFSUData: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_SFSU_DATA_FAILURE:
      return {
        ...state,
        fetchSFSUData: {
          data: null,
          loading: false,
          error: 'There was a problem loading the SFSU report.  Please try again later.',
        },
      };
    case FETCH_SFSU_DATA_CLEAR:
      return {
        ...state,
        fetchSFSUData: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case GET_STANDARD_REPORTS_REQUEST:
      return {
        ...state,
        reportDownloads: {
          data: state.reportDownloads.data,
          loading: true,
          error: null,
        },
      };

    case GET_STANDARD_REPORTS_SUCCESS:
      return {
        ...state,
        reportDownloads: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };

    case GET_STANDARD_REPORTS_FAILURE:
      return {
        ...state,
        reportDownloads: {
          data: [],
          loading: false,
          error: action.payload,
        },
      };

    case GET_STANDARD_REPORT_NOTIFICATION_REQUEST:
      return {
        ...state,
        reportDownloadNotifications: {
          data: [],
          loading: true,
          error: '',
        },
      };

    case GET_STANDARD_REPORT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        reportDownloadNotifications: {
          data: action.payload.data,
          loading: false,
          error: '',
        },
      };

    case GET_STANDARD_REPORT_NOTIFICATION_FAILURE:
      return {
        ...state,
        reportDownloadNotifications: {
          data: [],
          loading: false,
          error: action.payload,
        },
      };

    case DELETE_STANDARD_REPORT_REQUEST:
      return {
        ...state,
        deleteStandardReport: {
          loading: true,
          error: null,
          deletingId: action.payload.id,
        },
      };

    case DELETE_STANDARD_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        deletingId: null,
      };

    case DELETE_STANDARD_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        deletingId: null,
      };

    case MARK_READ_NOTIFICATION_REQUEST:
      return {
        ...state,
        markReadNotification: {
          loading: true,
          readingId: action.payload.id,
          error: null,
        },
      };

    case MARK_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        markReadNotification: {
          loading: false,
          readingId: '',
          error: null,
        },
      };

    case MARK_READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        markReadNotification: {
          loading: false,
          readingId: '',
          error: action.payload,
        },
      };

    case MARK_ALL_READ_NOTIFICATION_REQUEST:
      return {
        ...state,
        markAllReadNotification: {
          loading: true,
          error: null,
        },
      };

    case MARK_ALL_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        markAllReadNotification: {
          loading: false,
          error: null,
        },
      };

    case MARK_ALL_READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        markAllReadNotification: {
          loading: false,
          error: action.payload,
        },
      };

    case GET_LIVE_REPORT_FLAG_REQUEST:
      return {
        ...state,
        liveReportFlag: {
          loading: true,
          flag: false,
          error: null,
        },
      };

    case GET_LIVE_REPORT_FLAG_SUCCESS:
      return {
        ...state,
        liveReportFlag: {
          loading: false,
          flag: action.payload.flag,
          error: null,
        },
      };

    case GET_LIVE_REPORT_FLAG_FAILURE:
      return {
        ...state,
        liveReportFlag: {
          loading: false,
          flag: false,
          error: action.payload,
        },
      };

    case UPDATE_LIVE_REPORT_FLAG_REQUEST:
      return {
        ...state,
        updateLiveReportFlag: {
          loading: true,
          error: null,
        },
      };

    case UPDATE_LIVE_REPORT_FLAG_SUCCESS:
      return {
        ...state,
        updateLiveReportFlag: {
          loading: false,
          error: null,
        },
      };

    case UPDATE_LIVE_REPORT_FLAG_FAILURE:
      return {
        ...state,
        updateLiveReportFlag: {
          loading: false,
          error: action.payload,
        },
      };

    case ADD_APPLICATION_TAGS_REQUEST:
      return {
        ...state,
        addApplicationTags: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case ADD_APPLICATION_TAGS_SUCCESS:
      return {
        ...state,
        addApplicationTags: {
          data: action.data,
          loading: false,
          error: null,
        },
      };
    case ADD_APPLICATION_TAGS_FAILURE:
      return {
        ...state,
        addApplicationTags: {
          data: null,
          loading: false,
          error: 'There was a problem adding the tags.  Please try again later.',
        },
      };
    case ADD_APPLICATION_TAGS_CLEAR:
      return {
        ...state,
        addApplicationTags: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case REMOVE_APPLICATION_TAGS_REQUEST:
      return {
        ...state,
        removeApplicationTags: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case REMOVE_APPLICATION_TAGS_SUCCESS:
      return {
        ...state,
        removeApplicationTags: {
          data: action.data,
          loading: false,
          error: '',
        },
      };
    case REMOVE_APPLICATION_TAGS_FAILURE:
      return {
        ...state,
        removeApplicationTags: {
          data: null,
          loading: false,
          error: 'There was a problem removing the tags.  Please try again later.',
        },
      };
    case REMOVE_APPLICATION_TAGS_CLEAR:
      return {
        ...state,
        removeApplicationTags: {
          data: null,
          loading: false,
          error: null,
        },
      };

    default:
      return state;
  }
}
