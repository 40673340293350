exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2FtHk{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end}._2FtHk a{font-weight:700;margin-right:15px;text-decoration:none;font-size:15px;margin-left:25px;color:#000;cursor:pointer}._1F3SB{margin:0 0 0 20px!important}._1F3SB img{height:90px}", ""]);

// exports
exports.locals = {
	"headerOfApp": "_2FtHk",
	"logoImg": "_1F3SB"
};