import {
  FETCH_AUTOMATION_DETAIL_SUCCESS,
  FETCH_AUTOMATION_DETAIL_FAILURE,
  FETCH_AUTOMATION_ACTIONS,
  FETCH_AUTOMATION_CONDITIONS,
  FETCH_AUTOMATION_TOKENS,
  FETCH_AUTOMATION_BUTTON_TARGETS,
  FETCH_AUTOMATION_TYPES,
  FETCH_AUTOMATION_REQUEST,
  FETCH_AUTOMATION_SUCCESS,
  FETCH_AUTOMATION_FAILURE,
  UPDATE_AUTOMATION_DETAIL_SUCCESS,
  UPDATE_AUTOMATION_DETAIL_FAILURE,
  UPDATE_AUTOMATION_DETAIL_CLEAR,
  FETCH_AUTOMATION_CLEAR,
  FETCH_AUTOMATION_QUALIFIER_REQUEST,
  FETCH_AUTOMATION_QUALIFIER_SUCCESS,
  FETCH_AUTOMATION_QUALIFIER_FAILURE,
  FETCH_AUTOMATION_PRECONDITION_REQUEST,
  FETCH_AUTOMATION_PRECONDITION_SUCCESS,
  FETCH_AUTOMATION_PRECONDITION_FAILURE,
  FETCH_AUTOMATION_CONDITION_REQUEST,
  FETCH_AUTOMATION_CONDITION_SUCCESS,
  FETCH_AUTOMATION_CONDITION_FAILURE,
  FETCH_AUTOMATION_TYPES_REQUEST,
  FETCH_AUTOMATION_TYPES_FAILURE,
  CREATE_AUTOMATION_REQUEST,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_AUTOMATION_FAILURE,
  GET_AUTOMATION_MAILER_REQUEST,
  GET_AUTOMATION_MAILER_SUCCESS,
  GET_AUTOMATION_MAILER_FAILURE,
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILURE,
  FETCH_AUTOMATION_MAILER_HISTORY_REQUEST,
  FETCH_AUTOMATION_MAILER_HISTORY_SUCCESS,
  FETCH_AUTOMATION_MAILER_HISTORY_FAILURE,
  FETCH_AUTOMATION_FORM_HISTORY_REQUEST,
  FETCH_AUTOMATION_FORM_HISTORY_SUCCESS,
  FETCH_AUTOMATION_FORM_HISTORY_FAILURE,
  FETCH_AUTOMATION_TAG_HISTORY_REQUEST,
  FETCH_AUTOMATION_TAG_HISTORY_SUCCESS,
  FETCH_AUTOMATION_TAG_HISTORY_FAILURE,
  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_REQUEST,
  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_SUCCESS,
  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_FAILURE,
  DELETE_AUTOMATION_REQUEST,
  DELETE_AUTOMATION_SUCCESS,
  DELETE_AUTOMATION_FAILURE
} from '../actions/types';

import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';

const initialState = {
  actions: [],
  conditions: [],
  tokens: [],
  buttonTargets: [],
  types: [],
  automationDetail: '',
  fetchAutomation: [],
  updateAutomation: {},
  automationTypes: {
    loading: false,
    data: [],
    error: '',
  },
  automationQualifiers: {
    loading: false,
    data: [],
    options: [],
    error: '',
  },
  automationPreconditions: {
    loading: false,
    data: [],
    options: [],
    error: '',
  },
  automationConditions: {
    loading: false,
    data: [],
    options: [],
    error: '',
  },

  createAutomation: {
    loading: false,
    error: '',
    data: {},
  },

  automationMailers: {
    loading: false,
    error: '',
    data: [],
  },

  programs: {
    loading: false,
    error: '',
    data: [],
  },

  automationMailerHistory: {
    loading: false,
    data: [],
    error: '',
  },

  automationFormHistory: {
    loading: false,
    data: [],
    error: '',
  },

  automationTagHistory: {
    loading: false,
    data: [],
    error: '',
  },

  automationChangeStatusHistory: {
    loading: false,
    data: [],
    error: '',
  },

  automationDelete: {
    loading: false,
    error: ""
  }

};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUTOMATION_DETAIL_SUCCESS:
      return {
        ...state,
        automationDetail: action.payload,
      };
    case FETCH_AUTOMATION_DETAIL_FAILURE:
      return {
        ...state,
        automationDetail: action.payload,
      };

    case FETCH_AUTOMATION_ACTIONS:
      return {
        ...state,
        actions: action.payload,
      };

    case FETCH_AUTOMATION_CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };

    case FETCH_AUTOMATION_TOKENS:
      return {
        ...state,
        tokens: action.payload,
      };
    case FETCH_AUTOMATION_BUTTON_TARGETS:
      return {
        ...state,
        buttonTargets: action.payload,
      };

    case FETCH_AUTOMATION_TYPES_REQUEST:
      return {
        ...state,
        automationTypes: {
          loading: true,
          data: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_TYPES:
      return {
        ...state,
        types: action.payload,
        automationTypes: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      };

    case FETCH_AUTOMATION_TYPES_FAILURE:
      return {
        ...state,
        automationTypes: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };

    case UPDATE_AUTOMATION_DETAIL_SUCCESS:
      return {
        ...state,
        updateAutomation: action.payload,
      };

    case UPDATE_AUTOMATION_DETAIL_FAILURE:
      return {
        ...state,
        updateAutomation: action.payload,
      };
    case UPDATE_AUTOMATION_DETAIL_CLEAR:
      return {
        ...state,
        updateAutomation: action.payload,
      };

    case FETCH_AUTOMATION_REQUEST:
      return {
        ...state,
        fetchAutomation: {
          data: null,
          loading: true,
          error: null,
        },
      };

    case FETCH_AUTOMATION_SUCCESS:
      return {
        ...state,
        fetchAutomation: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case FETCH_AUTOMATION_FAILURE:
      return {
        ...state,
        fetchAutomation: {
          data: null,
          loading: false,
          error: 'There was a problem loading the automations.  Please try again later.',
        },
      };
    case FETCH_AUTOMATION_CLEAR:
      return {
        ...state,
        fetchAutomation: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case FETCH_AUTOMATION_QUALIFIER_REQUEST:
      return {
        ...state,
        automationQualifiers: {
          loading: true,
          data: [],
          options: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_QUALIFIER_SUCCESS:
      return {
        ...state,
        automationQualifiers: {
          data: action.payload.data,
          options: action.payload.data.map(qualifier => {
            return {
              identifier: qualifier.attributes.identifier,
              label: qualifier.attributes.display_name,
              value: qualifier.id,
            };
          }),
          loading: false,
          error: '',
        },
      };
    case FETCH_AUTOMATION_QUALIFIER_FAILURE:
      return {
        ...state,
        automationQualifiers: {
          data: [],
          options: [],
          loading: false,
          error: 'There was a problem loading the qualifiers.  Please try again later.',
        },
      };

    case FETCH_AUTOMATION_PRECONDITION_REQUEST:
      return {
        ...state,
        automationPreconditions: {
          loading: true,
          data: [],
          options: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_PRECONDITION_SUCCESS:
      return {
        ...state,
        automationPreconditions: {
          data: action.payload.data,
          options: action.payload.data.map(item => {
            return {
              identifier: item.attributes.identifier,
              label: item.attributes.display_name,
              value: item.id,
            };
          }),
          loading: false,
          error: '',
        },
      };
    case FETCH_AUTOMATION_PRECONDITION_FAILURE:
      return {
        ...state,
        automationPreconditions: {
          data: [],
          options: [],
          loading: false,
          error: 'There was a problem loading the conditions.  Please try again later.',
        },
      };

    case FETCH_AUTOMATION_CONDITION_REQUEST:
      return {
        ...state,
        automationConditions: {
          loading: true,
          data: [],
          options: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_CONDITION_SUCCESS:
      return {
        ...state,
        automationConditions: {
          data: action.payload.data,
          options: action.payload.data.map(item => {
            return {
              identifier: item.attributes.identifier,
              label: item.attributes.display_name,
              value: item.id,
            };
          }),
          loading: false,
          error: '',
        },
      };
    case FETCH_AUTOMATION_CONDITION_FAILURE:
      return {
        ...state,
        automationConditions: {
          data: [],
          options: [],
          loading: false,
          error: 'There was a problem loading the conditions.  Please try again later.',
        },
      };

    case CREATE_AUTOMATION_REQUEST:
      return {
        ...state,
        createAutomation: {
          loading: true,
          error: '',
          data: {},
        },
      };

    case CREATE_AUTOMATION_SUCCESS:
      return {
        ...state,
        createAutomation: {
          loading: false,
          error: '',
          data: action.payload.data,
        },
      };

    case CREATE_AUTOMATION_FAILURE:
      return {
        ...state,
        createAutomation: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };

    case GET_AUTOMATION_MAILER_REQUEST:
      return {
        ...state,
        automationMailers: {
          loading: true,
          error: '',
          data: [],
        },
      };

    case GET_AUTOMATION_MAILER_SUCCESS:
      return {
        ...state,
        automationMailers: {
          loading: false,
          error: '',
          data: action.payload,
        },
      };

    case GET_AUTOMATION_MAILER_FAILURE:
      return {
        ...state,
        automationMailers: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        programs: {
          loading: true,
          error: '',
          data: [],
        },
      };

    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        programs: {
          loading: false,
          error: '',
          data: action.payload,
        },
      };

    case GET_PROGRAMS_FAILURE:
      return {
        ...state,
        programs: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case FETCH_AUTOMATION_MAILER_HISTORY_REQUEST:
      return {
        ...state,
        automationMailerHistory: {
          loading: true,
          data: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_MAILER_HISTORY_SUCCESS:
      return {
        ...state,
        automationMailerHistory: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case FETCH_AUTOMATION_MAILER_HISTORY_FAILURE:
      return {
        ...state,
        automationMailerHistory: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };

    case FETCH_AUTOMATION_FORM_HISTORY_REQUEST:
      return {
        ...state,
        automationFormHistory: {
          loading: true,
          data: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_FORM_HISTORY_SUCCESS:
      return {
        ...state,
        automationFormHistory: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case FETCH_AUTOMATION_FORM_HISTORY_FAILURE:
      return {
        ...state,
        automationFormHistory: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };

    
    case FETCH_AUTOMATION_TAG_HISTORY_REQUEST:
      return {
        ...state,
        automationTagHistory: {
          loading: true,
          data: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_TAG_HISTORY_SUCCESS:
      return {
        ...state,
        automationTagHistory: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case  FETCH_AUTOMATION_TAG_HISTORY_FAILURE:
      return {
        ...state,
        automationTagHistory: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
  

    case FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_REQUEST:
      return {
        ...state,
        automationChangeStatusHistory: {
          loading: true,
          data: [],
          error: '',
        },
      };

    case FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_SUCCESS:
      return {
        ...state,
        automationChangeStatusHistory: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_FAILURE:
      return {
        ...state,
        automationChangeStatusHistory: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };

    case DELETE_AUTOMATION_REQUEST:
      return {
        ...state,
        automationDelete: {
          loading: true,
          error: ""
        }
      }

    case DELETE_AUTOMATION_SUCCESS:
      return {
        ...state,
        automationDelete: {
          loading: false,
          error: ""
        }
      }
    
    case DELETE_AUTOMATION_FAILURE:
      return {
        ...state,
        automationDelete: {
          loading: false,
          error: action.payload
        }
      }
    
    default:
      return state;
  }
}
