import _cloneDeep from 'lodash/cloneDeep';
import {
  FETCH_ADMIN_FORMS,
  SEARCH_ADMIN_FORMS,
  INACTIVATE_FORM,
  DELETE_FORM,
  PUBLISH_FORM,
  DUPLICATE_FORM,
  CLEAR_FORM,
  CLIENT_FETCH_TRAVELER_FORM,
  ACCESS_DENIED,
  ADMIN_UPDATE_PAYMENT_STATUS,
} from '../actions/types';

const initialState = {
  forms: [],
  answers: [],
  loading: true,
  query: '',
  publishCode: null,
  unpublishCode: null,
  deleteCode: null,
  duplicateCode: null,
  formId: null,
  accessDenied: null,
};

export default function(state = initialState, action) {
  let loading;
  switch (action.type) {
    case ACCESS_DENIED:
      return {
        accessDenied: action,
        loading: false,
      }
    case FETCH_ADMIN_FORMS:
      return {
        ...state,
        forms: action.payload,
        loading: false,
      };
    case SEARCH_ADMIN_FORMS:
      return {
        ...state,
        query: action.query,
      };
    case INACTIVATE_FORM:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        inactivateCode: action.code,
        publishCode: null,
        deleteCode: null,
        loading,
      };
    case DELETE_FORM:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        inactivateCode: null,
        publishCode: null,
        deleteCode: action.code,
        loading,
      };
    case PUBLISH_FORM:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        inactivateCode: null,
        publishCode: 200,
        deleteCode: null,
        loading,
      };
    case DUPLICATE_FORM:
      return {
        ...state,
        formId: action.formId,
        duplicateCode: 200,
         loading,
      };
    case CLEAR_FORM:
      return {
        ...state,
        loading: false,
        publishCode: null,
        unpublishCode: null,
        deleteCode: null,
        duplicateCode: null,
        formId: null,
      };
    case CLIENT_FETCH_TRAVELER_FORM:
      return {
        ...state,
        program: action.payload.program,
        template: action.payload.trm_template,
        submission: action.payload.submission,
        answers: action.payload.answers,
        cashnetCodes: action.payload.cashnetCodes,
        loading: false,
      };
    case ADMIN_UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        answers: updatePaymentState(state.answers, action.payload)
      }
    default:
      return state;
  }
}

const updatePaymentState = (_answers = [], payload) => {
  let answers = _cloneDeep(_answers);
  const answerIdx = answers?.findIndex(ans => ans.element_id === payload.element_id);
  if (answerIdx > -1) {
    answers[answerIdx] = payload;
  } else {
    answers.unshift(payload);
  }

  return answers;
}