import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { ToastContainer, toast } from 'react-toastify';
import toastStyles from 'react-toastify/dist/ReactToastify.css';
import sToastMessage from './toastMessage.scss';
// https://fkhadra.github.io/react-toastify/how-to-style/#passing-css-classes-to-component

export const enhance = compose(withStyles(sToastMessage, toastStyles));

function ToastMessage(props) {
  const customId = props.id;

  const notify = () => {
    if (props.message?.length) {
      toast.dismiss();
      toast(props.message, {
        toastId: !!customId && customId,
        className: props.isSuccess
          ? sToastMessage['successContainer']
          : !props.isSuccess
            ? sToastMessage['failureContainer']
            : sToastMessage['container'],
        toastClassName: sToastMessage['wrapper'],
        bodyClassName: props.isSuccess
          ? sToastMessage['successBody']
          : !props.isSuccess
            ? sToastMessage['failureBody']
            : sToastMessage['body'],
        progressClassName: sToastMessage['progress-bar'],
      });
    }

    props.onClose(true);
  };

  props.show ? notify() : null;

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={!props.isSuccess ? 7000 : 5000}
        hideProgressBar
        newestOnTop={props.newestOnTop}
        closeOnClick
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        role="alert"
        style={{
          marginBottom: '45px',
          marginRight: '56px',
          marginLeft:'10px',
        }}
      />
    </div>
  );
}

ToastMessage.propTypes = {
  message: propTypes.string,
  onClose: propTypes.func,
  show: propTypes.bool,
  newestOnTop: propTypes.bool,
  id: propTypes.string,
};

ToastMessage.defaultProps = {
  newestOnTop: true,
};

export default enhance(ToastMessage);
