import {
  CREATE_NEW_PROGRAM_CLEAR,
  CREATE_NEW_PROGRAM_REQUEST,
  CREATE_NEW_PROGRAM_SUCCESS,
  CREATE_NEW_PROGRAM_FAILURE,
  DELETE_DRAFT_PROGRAM_REQUEST,
  DELETE_DRAFT_PROGRAM_SUCCESS,
  DELETE_DRAFT_PROGRAM_FAILURE,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILURE,
  MODIFY_PROGRAM_CLEAR,
  MODIFY_PROGRAM_REQUEST,
  MODIFY_PROGRAM_SUCCESS,
  MODIFY_PROGRAM_FAILURE,
  ARCHIVE_PROGRAM_SUCCESS,
  ARCHIVE_PROGRAM_CLEAR,
  ARCHIVE_PROGRAM_REQUEST,
  ARCHIVE_PROGRAM_FAILURE,

  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,

  ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,

  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,

  DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,

  PROGRAM_TESTIMONIAL_REQUEST,
  PROGRAM_TESTIMONIAL_SUCCESS,
  PROGRAM_TESTIMONIAL_FAILURE,
  PROGRAM_TESTIMONIAL_CLEAR,

  GET_CONTACT_TITLES_REQUEST,
  GET_CONTACT_TITLES_SUCCESS,
  GET_CONTACT_TITLES_FAILURE,
  GET_CONTACT_TITLES_CLEAR,

  CREATE_CONTACT_TITLES_REQUEST,
  CREATE_CONTACT_TITLES_SUCCESS,
  CREATE_CONTACT_TITLES_FAILURE,
  CREATE_CONTACT_TITLES_CLEAR,

  UPDATE_CONTACT_TITLES_REQUEST,
  UPDATE_CONTACT_TITLES_SUCCESS,
  UPDATE_CONTACT_TITLES_FAILURE,
  UPDATE_CONTACT_TITLES_CLEAR,

  DELETE_CONTACT_TITLES_REQUEST,
  DELETE_CONTACT_TITLES_SUCCESS,
  DELETE_CONTACT_TITLES_FAILURE,
  DELETE_CONTACT_TITLES_CLEAR,

  GET_PROGRAM_IMAGE_REQUEST,
  GET_PROGRAM_IMAGE_SUCCESS,
  GET_PROGRAM_IMAGE_FAILURE,
  GET_PROGRAM_IMAGE_CLEAR,

  UPLOAD_PROGRAM_IMAGE_REQUEST,
  UPLOAD_PROGRAM_IMAGE_SUCCESS,
  UPLOAD_PROGRAM_IMAGE_FAILURE,
  UPLOAD_PROGRAM_IMAGE_CLEAR,

  DELETE_PROGRAM_IMAGE_REQUEST,
  DELETE_PROGRAM_IMAGE_SUCCESS,
  DELETE_PROGRAM_IMAGE_FAILURE,
  DELETE_PROGRAM_IMAGE_CLEAR,
} from '../actions/types';

const initialState = {
  programCreate: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },
  programDeleteDraft: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  programGet: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },
  programModify: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  archiveProgram: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  batchProgramBrochureSection: {
    batchData: null,
    batchError: null,
    batchErrorMsg: '',
    batchLoading: false,
    batchSuccess: false,
  },
  programBrochureSection: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  deleteProgramBrochureSection: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  batchDeleteProgramBrochureSection: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  testimonialCreate: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  contactTitles: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  contactTitleCreate: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  contactTitleUpdate: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  contactTitleDelete: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  uploadProgramImages: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  programImages: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

  deleteProgramImages: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },

};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_PROGRAM_CLEAR:
      return {
        ...state,
        programCreate: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
        },
      };
    case CREATE_NEW_PROGRAM_REQUEST:
      return {
        ...state,
        programCreate: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
        },
      };
    case CREATE_NEW_PROGRAM_SUCCESS:
      return {
        ...state,
        programCreate: {
          data: action.payload.data,
          error: false,
          errorMsg: '',
          loading: false,
        },
      };
    case CREATE_NEW_PROGRAM_FAILURE:
      return {
        ...state,
        programCreate: {
          data: null,
          error: true,
          errorMsg: 'Error creating new program.',
          loading: false,
        },
      };
    case DELETE_DRAFT_PROGRAM_REQUEST:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case DELETE_DRAFT_PROGRAM_SUCCESS:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: false,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case DELETE_DRAFT_PROGRAM_FAILURE:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: true,
          errorMsg: 'There was a problem deleting...',
          loading: false,
          success: false,
        },
      };
    case GET_PROGRAM_REQUEST:
      return {
        ...state,
        programGet: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
        },
      };
    case GET_PROGRAM_SUCCESS:
      return {
        ...state,
        programGet: {
          data: action.payload.data,
          error: false,
          errorMsg: '',
          loading: false,
        },
      };
    case GET_PROGRAM_FAILURE:
      return {
        ...state,
        programGet: {
          data: null,
          error: true,
          errorMsg: 'There was and error loading....',
          loading: false,
        },
      };
    case MODIFY_PROGRAM_CLEAR:
      return {
        ...state,
        programModify: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case MODIFY_PROGRAM_REQUEST:
      return {
        ...state,
        programModify: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case MODIFY_PROGRAM_SUCCESS:
      return {
        ...state,
        programModify: {
          data: action.payload.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    
    case MODIFY_PROGRAM_FAILURE:
      return {
        ...state,
        programModify: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
      case ARCHIVE_PROGRAM_CLEAR:
        return {
          ...state,
          archiveProgram: {
            data: null,
            error: null,
            errorMsg: '',
            loading: false,
            success: false,
          },
        };
      case ARCHIVE_PROGRAM_REQUEST:
        return {
          ...state,
          archiveProgram: {
            data: null,
            error: null,
            errorMsg: '',
            loading: true,
            success: false,
          },
        };  
    case ARCHIVE_PROGRAM_SUCCESS:
      return {
        ...state,
        archiveProgram: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };    

      case ARCHIVE_PROGRAM_FAILURE:
        return {
          ...state,
          archiveProgram: {
            data: null,
            error: true,
            errorMsg: '',
            loading: false,
            success: false,
          },
        }; 

    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: null,
          batchError: null,
          batchErrorMsg: '',
          batchLoading: false,
          batchSuccess: false,
        },
      };
    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: null,
          batchError: null,
          batchErrorMsg: '',
          batchLoading: true,
          batchSuccess: false,
        },
      };
    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: action?.payload?.data,
          batchError: null,
          batchErrorMsg: '',
          batchLoading: false,
          batchSuccess: true,
        },
      };
    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: null,
          batchError: true,
          batchErrorMsg: action?.payload?.data,
          batchLoading: false,
          batchSuccess: false,
        },
      };   
    
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR:
        return {
          ...state,
          programBrochureSection: {
            data: null,
            error: null,
            errorMsg: '',
            loading: false,
            success: false,
          },
        };
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        programBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        programBrochureSection: {
          data: action?.payload?.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE:
        return {
          ...state,
          programBrochureSection: {
            data: null,
            error: true,
            errorMsg: '',
            loading: false,
            success: false,
          },
        };

    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: action?.payload?.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };        
    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      }; 
    case DELETE_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: action?.payload?.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case DELETE_PROGRAM_BROCHURE_SECTION_FAILURE:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };      
    case DELETE_PROGRAM_BROCHURE_SECTION_CLEAR:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };   

      case PROGRAM_TESTIMONIAL_CLEAR:
        return {
          ...state,
          testimonialCreate: {
            data: null,
            error: null,
            errorMsg: '',
            loading: false,
          },
        };
      case PROGRAM_TESTIMONIAL_REQUEST:
        return {
          ...state,
          testimonialCreate: {
            data: null,
            error: null,
            errorMsg: '',
            loading: true,
          },
        };
      case PROGRAM_TESTIMONIAL_SUCCESS:
        return {
          ...state,
          programGet: {
            ...state.programGet,
            data: {
              ...state.programGet.data,
              attributes: {
                ...state.programGet.data.attributes,
                program_review_attributes: action.payload.reviews.map((testimonial) => ({
                  id: testimonial.id,
                  review_text: testimonial.review_text,
                  reviewer_name: testimonial.reviewer_name,
                  reviewer_details: testimonial.reviewer_details,
                })),
              },
            },
          },
          testimonialCreate: {
            data: action.payload.reviews,
            error: false,
            errorMsg: '',
            loading: false,
          },
        };
      case PROGRAM_TESTIMONIAL_FAILURE:
        return {
          ...state,
          testimonialCreate: {
            data: null,
            error: true,
            errorMsg: 'Error creating testimonial.',
            loading: false,
          },
        };

      case GET_CONTACT_TITLES_CLEAR:
        return {
            ...state,
            contactTitles: {
                data: null,
                error: null,
                errorMsg: '',
                loading: false,
            },
        };
      case GET_CONTACT_TITLES_REQUEST:
          return {
              ...state,
              contactTitles: {
                  ...state.contactTitles,
                  loading: true,
              },
          };
      case GET_CONTACT_TITLES_SUCCESS:
          return {
              ...state,
              contactTitles: {
                  data: action.payload,
                  error: false,
                  errorMsg: '',
                  loading: false,
              },
          };
      case GET_CONTACT_TITLES_FAILURE:
          return {
              ...state,
              contactTitles: {
                  data: null,
                  error: true,
                  errorMsg: 'Error fetching contact titles.',
                  loading: false,
              },
          };
      
      case CREATE_CONTACT_TITLES_CLEAR:
          return {
              ...state,
              contactTitleCreate: {
                  data: null,
                  error: null,
                  errorMsg: '',
                  loading: false,
              },
          };
      case CREATE_CONTACT_TITLES_REQUEST:
          return {
              ...state,
              contactTitleCreate: {
                  ...state.contactTitleCreate,
                  loading: true,
              },
          };
      case CREATE_CONTACT_TITLES_SUCCESS:
          return {
              ...state,
              contactTitles: {
                ...state.contactTitles,
                data: [...state.contactTitles.data, action.payload], // Append new data
              },
              contactTitleCreate: {
                  data: action.payload,
                  error: false,
                  errorMsg: '',
                  loading: false,
              },
          };
      case CREATE_CONTACT_TITLES_FAILURE:
          return {
              ...state,
              contactTitleCreate: {
                  data: null,
                  error: true,
                  errorMsg: 'Error creating contact title.',
                  loading: false,
              },
          };
      
      case UPDATE_CONTACT_TITLES_CLEAR:
          return {
              ...state,
              contactTitleUpdate: {
                  data: null,
                  error: null,
                  errorMsg: '',
                  loading: false,
              },
          };
      case UPDATE_CONTACT_TITLES_REQUEST:
          return {
              ...state,
              contactTitleUpdate: {
                  ...state.contactTitleUpdate,
                  loading: true,
              },
          };
      case UPDATE_CONTACT_TITLES_SUCCESS:
          return {
              ...state,
              contactTitles: {
                ...state.contactTitles,
                data: state.contactTitles.data.map(item =>
                    item.id === action.payload.id
                        ? { ...item, ...action.payload } // Merge the updated object
                        : item // Keep the existing object unchanged
                ),
                error: false,
                errorMsg: '',
                loading: false,
              },
              contactTitleUpdate: {
                  data: action.payload,
                  error: false,
                  errorMsg: '',
                  loading: false,
              },
          };
      case UPDATE_CONTACT_TITLES_FAILURE:
          return {
              ...state,
              contactTitleUpdate: {
                  data: null,
                  error: true,
                  errorMsg: 'Error updating contact title.',
                  loading: false,
              },
          };
      
      case DELETE_CONTACT_TITLES_CLEAR:
          return {
              ...state,
              contactTitleDelete: {
                  data: null,
                  error: null,
                  errorMsg: '',
                  loading: false,
              },
          };
      case DELETE_CONTACT_TITLES_REQUEST:
          return {
              ...state,
              contactTitleDelete: {
                data: null,
                error: null,
                errorMsg: '',
                loading: true,
                success: false,
              },
          };
      case DELETE_CONTACT_TITLES_SUCCESS:
          return {
              ...state,
              contactTitles: {
                ...state.contactTitles,
                data: state.contactTitles.data.filter(item => item.id !== action.payload), // Remove the object with matching ID
                error: false,
                errorMsg: '',
                loading: false,
              },
              contactTitleDelete: {
                  data: action.payload,
                  error: false,
                  errorMsg: '',
                  loading: false,
              },
          };
      case DELETE_CONTACT_TITLES_FAILURE:
          return {
              ...state,
              contactTitleDelete: {
                  data: null,
                  error: true,
                  errorMsg: 'Error deleting contact title.',
                  loading: false,
              },
          };
      

      case UPLOAD_PROGRAM_IMAGE_CLEAR:
            return {
                ...state,
                uploadProgramImages: {
                    data: null,
                    error: null,
                    errorMsg: '',
                    loading: false,
                },
            };
      case UPLOAD_PROGRAM_IMAGE_REQUEST:
          return {
              ...state,
              uploadProgramImages: {
                  ...state.uploadProgramImages,
                  loading: true,
              },
          };
      case UPLOAD_PROGRAM_IMAGE_SUCCESS:
          return {
              ...state,
              uploadProgramImages: {
                  data: action.payload,
                  error: false,
                  errorMsg: '',
                  loading: false,
              },
          };
      case UPLOAD_PROGRAM_IMAGE_FAILURE:
            return {
                ...state,
                uploadProgramImages: {
                    data: null,
                    error: true,
                    errorMsg: 'Error uploading program images.',
                    loading: false,
                },
            };      

      case GET_PROGRAM_IMAGE_CLEAR:
        return {
          ...state,
          programImages: {
              data: null,
              error: null,
              errorMsg: '',
              loading: false,
          },
        };
      case GET_PROGRAM_IMAGE_REQUEST:
        return {
          ...state,
          programImages: {
            ...state.programImages,
            loading: true,
          },
        };
      case GET_PROGRAM_IMAGE_SUCCESS:
        return {
            ...state,
            programImages: {
                data: action.payload.data,
                error: false,
                errorMsg: '',
                loading: false,
            },
        };
      case GET_PROGRAM_IMAGE_FAILURE:
        return {
            ...state,
            programImages: {
                data: null,
                error: true,
                errorMsg: 'Error fetching program images.',
                loading: false,
            },
        };           

      case DELETE_PROGRAM_IMAGE_CLEAR:
        return {
          ...state,
          deleteProgramImages: {
              data: null,
              error: null,
              errorMsg: '',
              loading: false,
          },
        };
      case DELETE_PROGRAM_IMAGE_REQUEST:
        return {
          ...state,
          deleteProgramImages: {
            ...state.deleteProgramImages,
            loading: true,
          },
        };
      case DELETE_PROGRAM_IMAGE_SUCCESS:
        return {
            ...state,
            deleteProgramImages: {
                data: action.payload.data,
                error: false,
                errorMsg: '',
                loading: false,
            },
        };
      case DELETE_PROGRAM_IMAGE_FAILURE:
        return {
            ...state,
            deleteProgramImages: {
                data: null,
                error: true,
                errorMsg: 'Error fetching program images.',
                loading: false,
            },
        };               


    default:
      return state;
  }
}
