import {
  FETCH_PROGRAM,
  FETCH_PROGRAM_FOR_TRAVELER,
  FETCH_TRAVELER_PERCENT_MATCH_ONE,
  TOGGLE_FAVORITE,
  REQUEST_TO_APPLY,
  GET_AUTHORIZED_PROGRAM_SETTINGS,
  ASK_QUESTION,
  FETCH_PROGRAM_CONTACTS,
  TOGGLE_PROGRAM_PUBLISH,
  FETCH_AMMENITIES,
  FETCH_SUBJECTS,
  CREATE_PROGRAM_DRAFT,
  PROGRAM_ERRORS,
  GET_PROGRAM,
  CONTACT_PROGRAM_CONTACT,
  CLEAR_IS_CONTACTED,
  CLEAR_QUESTION_CODE,
  UPDATE_PROGRAM_LOADER,
  SAVE_NEW_DOCUMENT,
  DELETE_PROGRAM_ATTACHMENT,
  UPDATE_PROGRAM_ATTACHMENT,
} from '../actions/types';

const initialState = {
  programLoading: true,
  authorizedProgramLoading: true,
  percentLoading: true,
  ammenitiesLoading: true,
  subjectsLoading: true,
  requestSent: false,
  contactsLoading: true,
  error: '',
  subjects: [],
  ammenities: [],
  contacts: [],
  program: {},
  programHash: {},
  programAttachment: [],
  authorizedProgram: null,
  percent: {},
  favorite: {},
  question: null,
  status: null,
  programErrors: {},
  hasErrors: false,
  isContacted: null,
  questionCode: null,
  newProgramLoading: true,
  allHousingTypes: [],
  allProgramTypes: [],
  programTypeConnections: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROGRAM:
      return {
        ...state,
        program: action.payload,
        programLoading: false,
        programTypeConnections: action.payload[1].program_type_connections,
      };
    case FETCH_PROGRAM_FOR_TRAVELER:
      return {
        ...state,
        program: action.payload,
        programLoading: false,
      };
    case FETCH_TRAVELER_PERCENT_MATCH_ONE:
      return {
        ...state,
        percent: action.payload,
        percentLoading: false,
      };
    case TOGGLE_FAVORITE:
      return {
        ...state,
        favorite: action.payload,
      };
    case REQUEST_TO_APPLY:
      return {
        ...state,
        isContacted: action.payload.code == 200 ? true : false,
      };
    case GET_AUTHORIZED_PROGRAM_SETTINGS:
      return {
        ...state,
        authorizedProgram: action.payload,
        authorizedProgramLoading: false,
      };
    case ASK_QUESTION:
      return {
        ...state,
        question: action.payload,
        questionCode: action.payload.code,
      };
    case FETCH_PROGRAM_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
        contactsLoading: false,
      };
    case TOGGLE_PROGRAM_PUBLISH:
      return {
        ...state,
        status: action.payload,
      };
    case FETCH_AMMENITIES:
      return {
        ...state,
        ammenities: action.payload,
        ammenitiesLoading: false,
      };
    case FETCH_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
        allHousingTypes: action.payload[2],
        allProgramTypes: action.payload[3],
        subjectsLoading: false,
      };
    case CREATE_PROGRAM_DRAFT:
      return {
        ...state,
      };
    case PROGRAM_ERRORS:
      return {
        ...state,
        programErrors: action.payload.errors,
        hasErrors: Object.keys(action.payload.errors).length > 0,
      };
    case GET_PROGRAM:
      return {
        ...state,
        programHash: action.payload,
        programLoading: false,
      };
    case CONTACT_PROGRAM_CONTACT:
      return {
        ...state,
        isContacted: action.payload == 200 ? true : false,
      };
    case CLEAR_IS_CONTACTED:
      return {
        ...state,
        isContacted: null,
      };
    case CLEAR_QUESTION_CODE:
      return {
        ...state,
        questionCode: null,
      };
    case UPDATE_PROGRAM_LOADER:
      return {
        ...state,
        programLoading: action.payload,
        newProgramLoading: action.payload,
      };
    case SAVE_NEW_DOCUMENT:
      return {
        ...state,
        program_attachment: action.payload,
      };
    case DELETE_PROGRAM_ATTACHMENT:
      return {
        ...state,
        program_attachment: action.payload,
      };
    case UPDATE_PROGRAM_ATTACHMENT:
      return{
        ...state,
        program_attachment: action.payload
      }
    default:
      return state;
  }
}
