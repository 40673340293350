import moment from 'moment-timezone';
import defaultAvatar from '../../../public/img/default_user_avatar.svg';

const defaultUserProfileAvatar = () => defaultAvatar;

const defaultPlanLocationImage = () =>
  'https://images.unsplash.com/photo-1506012787146-f92b2d7d6d96?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0MTUyOH0';

const calcTiming = (start, end) => {
  const options = ['Past', 'Upcoming', 'On-site'];
  start = !start ? '2000-01-01' : start; //just force to date in past
  end = !end ? '2050-01-01' : end; //just force to date in future

  let startDate = start.trim().split(',');
  if (startDate.length == 1) {
    let date2 = start.trim().split(' ');
    if (date2.length > 1) {
      let newDate = date2[0] + ' ' + 1 + ',' + date2[1];
      startDate = new Date(newDate.replace(/-/g, '/'));
    } else {
      startDate = new Date(date2[0].replace(/-/g, '/'));
    }
  } else {
    startDate = new Date(start.replace(/-/g, '/'));
  }

  let endDate = end.trim().split(',');
  if (endDate.length == 1) {
    let date2 = end.trim().split(' ');
    if (date2.length > 1) {
      let newEndDate = date2[0] + ' ' + 1 + ',' + date2[1];
      endDate = new Date(newEndDate.replace(/-/g, '/'));
    } else {
      endDate = new Date(date2[0].replace(/-/g, '/'));
    }
  } else {
    endDate = new Date(end.replace(/-/g, '/'));
  }

  const momentStart = moment(startDate).format('YYYY-MM-DD');
  const momentEnd = moment(endDate).format('YYYY-MM-DD');

  if (moment().isAfter(momentEnd, 'day')) {
    return options[0];
  } else if (moment().isBefore(momentStart, 'day')) {
    return options[1];
  } else {
    return options[2];
  }
};

export { defaultUserProfileAvatar, defaultPlanLocationImage, calcTiming };
