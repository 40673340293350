import {
  SAVE_ALTERNATE_SETTINGS,
  ALTERNATE_LOADING,
  CLEAR_ALTERNATE_FLASH,
} from '../actions/types';

const initialState = {
  alternateSetting: {},
  successMsg: '',
  errorMsg: '',
  alternateSettingLoading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALTERNATE_LOADING:
      return {
        ...state,
        alternateSettingLoading: true,
      };
    case SAVE_ALTERNATE_SETTINGS:
      return {
        ...state,
        alternateSetting: action.payload.alternate_setting,
        successMsg: action.payload.success_msg,
        errorMsg: action.payload.error_msg,
        alternateSettingLoading: false,
      };
    case CLEAR_ALTERNATE_FLASH:
      return {
        ...state,
        successMsg: null,
        errorMsg: null,
      };

    default:
      return state;
  }
}
