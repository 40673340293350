import { GET_CURRENT_ORGANIZATION } from '../actions/types';

const initialState = {
  info: null,
  customAliases: null,
  brandingTheme: null,
  organizationId: null,
  organizationName: '',
  clientFeatureList: null,
  useCustomAliases: false,
  currentOrganizationLoading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ORGANIZATION:
      return {
        ...state,
        info: action.payload.info,
        currentOrganizationLoading: false,
        organizationId: action.payload.id,
        organizationName: action.payload.org_name,
        brandingTheme: action.payload.branding_theme,
        customAliases: action.payload.custom_aliases,
        useCustomAliases: action.payload.use_custom_aliases,
        clientFeatureList: action.payload.client_feature_list,
      };
    default:
      return state;
  }
}
