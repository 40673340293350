import {
  FETCH_TRAVEL_PLANS,
  FETCH_ADMIN_TRAVEL_PLAN_DATA,
  FETCH_TRAVEL_PLAN_LOGISTICS,
  SELECT_TRAVEL_PLAN_TAB,
} from '../actions/types';

const initialState = {
  loading: true,
  brochureLoading: true,
  logisticsLoading: true,
  travelPlans: [],
  travelPlan: {},
  logistics: {},
  activeTab: 'description',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRAVEL_PLANS:
      return {
        ...state,
        travelPlans: action.payload,
        loading: false,
      };
    case FETCH_ADMIN_TRAVEL_PLAN_DATA:
      return {
        ...state,
        travelPlan: action.payload,
        brochureLoading: false,
      };
    case FETCH_TRAVEL_PLAN_LOGISTICS:
      return {
        ...state,
        logistics: action.payload,
        logisticsLoading: false,
      };
    case SELECT_TRAVEL_PLAN_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    default:
      return state;
  }
}
