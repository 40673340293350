import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { REPORT_V2_FORM_DATA } from '../actions/types';
import { calcTiming } from '../shared/helpers/plans';
import { stripHtmlString } from '../shared/helpers/General';

const initialState = {
  formReportData: {
    data: null,
    error: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REPORT_V2_FORM_DATA:
      let dataset = _cloneDeep(action?.payload?.data);

      if (!action?.payload?.error) {
        dataset.forEach(row => {
          row.full_name = `${row.profile_first_name} ${row.profile_last_name}`.trim();
          row.program_notes = stripHtmlString(row.program_notes);
          row.term_timing = calcTiming(row.program_range_start_date, row.program_range_end_date).trim();
          row.submission_created_on_year = moment(row.submission_created_at_raw).year();
          row.submission_created_on_month = moment(row.submission_created_at_raw).format('MMM');
          row.submission_created_on_day = moment(row.submission_created_at_raw).date();
          row.term_timing = calcTiming(row.program_range_start_date, row.program_range_end_date).trim();
          row.submitted_at_on_year = moment(row.submitted_at_raw).year();
          row.submitted_at_on_month = moment(row.submitted_at_raw).format('MMM');
          row.submitted_at_on_day = moment(row.submitted_at_raw).date();
        });
      }

      return {
        ...state,
        formReportData: {
          data: dataset,
          error: action?.payload?.error,
        },
      };
    default:
      return state;
  }
}
