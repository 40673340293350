import {
  GET_VISITOR_PROGRAMS_REQUEST,
  GET_VISITOR_PROGRAMS_SUCCESS,
  GET_VISITOR_PROGRAMS_FAILURE,
  GET_VISITOR_PROGRAMS_CLEAR,
} from '../actions/types';

const initialState = {
  allVisitorPrograms: {
    data: null,
    loading: true,
    error: null,
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_VISITOR_PROGRAMS_REQUEST:
      return {
        ...state,
        allVisitorPrograms: {
          data: null,
          loading: true,
          error: null,
        }
      };
    case GET_VISITOR_PROGRAMS_SUCCESS:
      return {
        ...state,
        allVisitorPrograms: {
          data: action.payload.data,
          loading: false,
          error: null,
        }
      };
    case GET_VISITOR_PROGRAMS_FAILURE:
      return {
        ...state,
        allVisitorPrograms: {
          data: null,
          loading: false,
          error: 'There was a problem getting visitor programs.',
        }
      };
    case GET_VISITOR_PROGRAMS_CLEAR:
      return {
        ...state,
        allVisitorPrograms: {
          data: null,
          loading: false,
          error: null,
        }
      };
    default:
      return state;
  }
}
