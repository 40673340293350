import {
  SET_ACTIVE_EVENT,
  CLEAR_ACTIVE_EVENT,
  GET_TRAVELER_EVENTS,
  TOGGLE_EVENT_REGISTRATION_MODAL,
} from '../actions/types';

const initialState = {
  events: [],
  activeEvent: {},
  travelerEventsLoading: true,
  showEventRegistrationModal: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TRAVELER_EVENTS:
      return {
        ...state,
        events: action.payload,
        travelerEventsLoading: false,
      };
    case TOGGLE_EVENT_REGISTRATION_MODAL:
      return {
        ...state,
        showEventRegistrationModal: !state.showEventRegistrationModal,
      };
    case SET_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: action.payload,
      };
    case CLEAR_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: {},
      };
    default:
      return state;
  }
}
