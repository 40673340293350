import {
  BATCH_INVITE_TRAVELERS_DASHBOARD,
  INVITE_SINGLE_TRAVELER_DASHBOARD,
  CLEAR_BATCH_INVITATIONS_MESSAGE,
  CLEAR_INVITATIONS_MESSAGE,
} from '../actions/types';

const initialState = {
  batchInviteCode: null,
  batchMessage: null,
  singleInviteCode: null,
  message: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case BATCH_INVITE_TRAVELERS_DASHBOARD:
      return {
        ...state,
        batchInviteCode: action.code,
        batchMessage: action.message,
      };
    case INVITE_SINGLE_TRAVELER_DASHBOARD:
      return {
        ...state,
        singleInviteCode: action.code,
        message: action.message,
      };
    case CLEAR_BATCH_INVITATIONS_MESSAGE:
      return {
        // ...state,
        batchInviteCode: null,
        batchMessage: null,
        singleInviteCode: null,
        message: null,
      };
    case CLEAR_INVITATIONS_MESSAGE:
      return {
        // ...state,
        batchInviteCode: null,
        batchMessage: null,
        singleInviteCode: null,
        message: null,
      };
    default:
      return state;
  }
}
