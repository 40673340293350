import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './header.css';
import ImageLogo from './images/Logo.png';

class PrimaryHeader extends React.Component {
  render() {
    return (
      <div className={s.headerOfApp}>
        <div>
          <a href={`/`}>Sign In</a>
          <a className={s.logoImg}><img src={ImageLogo} /></a>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(PrimaryHeader);
