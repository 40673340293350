import {
  FETCH_TRAVELERS,
  FILTER_TRAVELERS,
  FETCH_INITIAL_TRAVELERS,
  FETCH_CLIENT_EVENTS,
  FETCH_CLIENT_FORMS,
  FETCH_CLIENT_APPLICATIONS,
  FETCH_CLIENT_PROGRAMS,
  FETCH_CLIENT_AUTHORIZED_PROGRAMS,
  FETCH_CLIENT_PROGRAM_ORGS,
  FETCH_CLIENT_ADMINS,
  FETCH_CUSTOM_FIELDS,
  CREATE_TRAVELER_BATCH_MESSAGE,
  UPDATE_CUSTOM_FIELDS_MODAL,
  FETCH_COLLEGE_OR_FACULTY,
  SELECT_TRAVELER,
  UNSELECT_TRAVELER,
  ADD_FILTER,
  CLEAR_FILTER_MESSAGE,
  APPLY_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER_APPLIED,
  DIAL_FILTER,
  DELETE_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER_CODE,
  LOADING_FILTER_DATA,
  SELECT_TRAVELERS,
  UNSELECT_TRAVELERS,
  FETCH_CLIENT_DASHBOARD_DETAILS,
} from '../actions/types';

const initialState = {
  travelersLoading: true,
  fullTravelersLoading: true,
  eventsLoading: true,
  formsLoading: true,
  applicationsLoading: true,
  programsLoading: true,
  authorized_programsLoading: true,
  adminsLoading: true,
  orgsLoading: true,
  collegesLoading: true,
  adminDashboardLoading: true,
  travelers: [],
  forms: [],
  events: [],
  applications: [],
  programs: [],
  adminDashboard: [],
  allProgramTypes: [],
  organizations: [],
  authorized_programs: [],
  colleges: [],
  admins: [],
  params: [{}],
  cutomFieldsModal: false,
  selectedTravelers: [],
  filterMessage: null,
  searchFilters: [],
  searchFilterApplied: false,
  appliedFilterId: null,
  searchFilterCode: null,
  block: 0,
  moreTravelersLoading: false,
  schoolNames: [],
  messageSuccess: '',
};

export default function(state = initialState, action) {
  let prevSearchFilters;

  switch (action.type) {
    case FETCH_INITIAL_TRAVELERS:
      return {
        ...state,
        travelers:
          action.payload.travelers.length > 0
            ? [...state.travelers, action.payload.travelers].flat()
            : state.travelers,
        travelersLoading: false,
        moreTravelersLoading:
          action.payload.travelers.length > 0 ? true : false,
        block: state.block + 1,
        searchFilters: action.payload.search_filters,
      };
    case FILTER_TRAVELERS:
      return {
        ...state,
        travelers: action.payload.travelers,
        searchFilters: action.payload.search_filters,
        schoolNames: action.payload.school_names,
        travelersLoading: false,
      };
    case FETCH_TRAVELERS:
      return {
        ...state,
        travelers: action.payload.travelers,
        travelersLoading: false,
      };
    case FETCH_TRAVELERS:
      return {
        ...state,
        travelers: action.payload.travelers,
        fullTravelersLoading: false,
        searchFilters: action.payload.search_filters,
      };
    case FETCH_CLIENT_EVENTS:
      return {
        ...state,
        events: action.payload,
        eventsLoading: false,
      };
    case FETCH_CLIENT_DASHBOARD_DETAILS:
      return {
        ...state,
        adminDashboard: action.payload,
        adminDashboardLoading: false,
      }
    case FETCH_CLIENT_FORMS:
      return {
        ...state,
        forms: action.payload,
        formsLoading: false,
      };
    case FETCH_CLIENT_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
        applicationsLoading: false,
      };
    case FETCH_CLIENT_PROGRAMS:
      return {
        ...state,
        programs: action.payload.programs,
        allProgramTypes: action.payload.all_program_types,
        programsLoading: false,
      };
    case FETCH_CLIENT_AUTHORIZED_PROGRAMS:
      return {
        ...state,
        authorized_programs: action.payload,
        authorized_programsLoading: false,
      };
    case FETCH_CLIENT_PROGRAM_ORGS:
      return {
        ...state,
        organizations: action.payload,
        orgsLoading: false,
      };
    case FETCH_CLIENT_ADMINS:
      return {
        ...state,
        admins: action.payload,
        adminsLoading: false,
      };
    case FETCH_CUSTOM_FIELDS:
      return {
        ...state,
        custom_fields: action.payload,
      };
    case CREATE_TRAVELER_BATCH_MESSAGE:
      return {
        ...state,
        toggleMessageModal: action.payload,
        messageSuccess: action.success,
      };
    case UPDATE_CUSTOM_FIELDS_MODAL:
      return {
        ...state,
        cutomFieldsModal: action.payload,
      };
    case FETCH_COLLEGE_OR_FACULTY:
      return {
        ...state,
        colleges: action.payload,
        collegesLoading: false,
      };
    case SELECT_TRAVELER:
      return {
        ...state,
        selectedTravelers:
          action.travelerId !== undefined
            ? [...state.selectedTravelers, action.travelerId]
            : [...state.selectedTravelers],
      };
    case UNSELECT_TRAVELER:
      let selectedTravelers = state.selectedTravelers;

      selectedTravelers.splice(
        selectedTravelers.lastIndexOf(action.travelerId),
        1,
      );

      return {
        ...state,
        selectedTravelers: selectedTravelers,
      };
    case SELECT_TRAVELERS:
      return {
        ...state,
        selectedTravelers: action.ids,
      };
    case UNSELECT_TRAVELERS:
      return {
        ...state,
        selectedTravelers: [],
      };
    case APPLY_SEARCH_FILTER:
      return {
        ...state,
        travelers: action.payload,
        travelersLoading: false,
        searchFilters: state.searchFilters,
        searchFilterApplied: true,
        appliedFilterId: action.appliedFilterId,
        filterMessage: action.payload.message,
        searchFilterCode: action.payload.code,
      };
    case CLEAR_SEARCH_FILTER_APPLIED:
      return {
        ...state,
        searchFilterApplied: false,
      };
    case ADD_FILTER:
      prevSearchFilters = [...state.searchFilters];
      let added_filter = {
        ...action.payload.search_filter,
        filter: JSON.parse(action.payload.search_filter.filter),
      };
      prevSearchFilters.push(added_filter);
      return {
        ...state,
        searchFilters: prevSearchFilters,
        filterMessage: action.payload.message,
        searchFilterCode: action.payload.code,
      };
    case DIAL_FILTER:
      return {
        ...state,
        travelers: {
          travelers: action.payload.travelers.filter(
            traveler =>
              traveler.current_tracking_step &&
              traveler.current_tracking_step.filter(
                _d => _d.step == dialStatus(action.payload.dial_status),
              ).length > 0,
          ),
        },
        travelersLoading: false,
        searchFilterApplied: false,
      };
    case DELETE_SEARCH_FILTER:
      prevSearchFilters = [...state.searchFilters];
      prevSearchFilters.splice(
        prevSearchFilters.findIndex(
          x => x.id === action.payload.appliedFilterId,
        ),
        1,
      );
      return {
        ...state,
        searchFilters: prevSearchFilters,
        searchFilterCode: action.payload.code,
        filterMessage: action.payload.message,
      };
    case CLEAR_SEARCH_FILTER_CODE:
      return {
        ...state,
        searchFilterCode: null,
      };
    case LOADING_FILTER_DATA:
      return {
        ...state,
        travelersLoading: true,
      };
    default:
      return state;
  }
}

const dialStatus = status => {
  const arr = {
    Invited: 'invited',
    Interested: 'interested',
    Applying: 'applying',
    Predeparture: 'predeparture',
    'On Site': 'on_site',
    Alumni: 'alumni',
    'Deferred/Withdrawn/Closed': 'deferred_withdrawn_closed',
  };
  return arr[status];
};
