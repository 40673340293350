import moment from 'moment-timezone';
import _cloneDeep from 'lodash/cloneDeep';

import {
  FETCH_GRAPH_INFORMATION,
  FETCH_GRAPH_PROGRAM,
  FETCH_ORGANIZATION_INSIGHTS,
  FETCH_ORGANIZATION_INFORMATION,
  FETCH_POPULAR_PROGRAMS,
  FETCH_RECENT_CONNECTS,
  FETCH_ANALYTICS_REPORT,
  FETCH_TRAVELER_TRACKER,
  FETCH_TERM_DEPARTING,
  FETCH_UPCOMING_APPLICATION_DEADLINE,
  FETCH_PROGRAM_DISTRIBUTION,
  FETCH_APPLICATION_STARTED,
  FETCH_FAVORITE_PROGRAM,
  FETCH_PARTICIPATION_BARRIERS
} from '../actions/types';


const initialState = {
  graphInformation: [],
  propularPrograms: [],
  recentConnects: [],
  programGraphInformation : [],
  organizationsList : [],
  organizationDetails : [],
  loading: true,
  donutChart: [],
  barChart: [],
  geoChart: [],
  reports: [],
  travelerTracker: [],
  termsDeparting: [],
  upcomingApplicationDeadline: [],
  programDistribution: [],
  applicationStarted: [],
  favoritePrograms: [],
  participationBarriers: [],
};

export default function(state = initialState, action) {
  const recentConnectData = data => {
    return data.data.map(item => {
      return {
        id: item.attributes.id,
        action: item.attributes.action,
        user_email: item.attributes.user_email,
        action_date: moment(new Date(item.attributes.action_date)).format('MMM DD, YYYY'),
        program_name: item.attributes.program_name,
        full_name: item.attributes.full_name || item.attributes.user_email,
      };
    });
  };

  const analyticsReportData = data => {
    const transformedArray = data?.map(originalObject => {
      const actionTime = originalObject.action_time;
      originalObject.timezone = moment(actionTime).format('Z');
      originalObject.action_time = actionTime ? moment(actionTime,'HH:mm:ss.SSSSSS').format('hh:mm A') :  "";
      //const { id, type, attributes } = originalObject;
      //return { id, type, ...attributes };
      return originalObject;
    });

    return transformedArray;
  };

  switch (action.type) {
    case FETCH_GRAPH_INFORMATION:
      return {
        ...state,
        graphInformation: action.payload,
        donutChart: action.payload.doughnut,
        barChart: action.payload.bar,
        geoChart: action.payload.geo,
        loading: false,
      };
    
    case FETCH_GRAPH_PROGRAM:
      return {
        ...state,
        programGraphInformation: action.payload,
        loading: false,
      };  

    case FETCH_ORGANIZATION_INSIGHTS:
      return {
        ...state,
        organizationsList: action.payload,
        loading: false,
      };    

    case FETCH_ORGANIZATION_INFORMATION:
      return {
        ...state,
        organizationDetails: action.payload,
        loading: false,
      };   


    case FETCH_POPULAR_PROGRAMS:
      return {
        ...state,
        propularPrograms: action.payload,
        loading: false,
      };

    case FETCH_RECENT_CONNECTS:
      return {
        ...state,
        recentConnects: recentConnectData(action.payload),
        loading: false,
      };
    case FETCH_ANALYTICS_REPORT:
      return {
        ...state,
        reports: analyticsReportData(action.payload),
        loading: false,
      };

    case FETCH_TRAVELER_TRACKER:
      return {
        ...state,
        travelerTracker: action.payload,
        
      };  

    case FETCH_TERM_DEPARTING:
      return {
        ...state,
        termsDeparting: action.payload,
      };  
    case FETCH_UPCOMING_APPLICATION_DEADLINE:
      return {
        ...state,
        upcomingApplicationDeadline: action.payload,
      };  
    case FETCH_PROGRAM_DISTRIBUTION:
      return {
        ...state,
        programDistribution: action.payload,
      };  
    case FETCH_APPLICATION_STARTED:
      return {
        ...state,
        applicationStarted: action.payload,
      };  
    case FETCH_FAVORITE_PROGRAM:
      return {
        ...state,
        favoritePrograms: action.payload,
      };  
    case FETCH_PARTICIPATION_BARRIERS:
      return {
        ...state,
        participationBarriers: action.payload,
      };  

    default:
      return state;
  }
}
