import {
  FETCH_VISITOR_EVENT,
  FETCH_VISITOR_EVENT_FAILURE,
  FETCH_VISITOR_EVENT_REQUEST,
  VISITOR_SINGLE_TRAVELER,
} from '../actions/types';

const initialState = {
  loading: true,
  event: {},
  error: "",
  inviteSingleTraveler: {
    status: null,
    loading: true,
    error: null,
  }
};

export default function(state = initialState, action) {
  switch (action.type) {

    case FETCH_VISITOR_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      }
      
    case FETCH_VISITOR_EVENT:
      return {
        ...state,
        event: action.payload,
        loading: false,
      };

    case FETCH_VISITOR_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case VISITOR_SINGLE_TRAVELER:
      return {
        ...state,
        inviteSingleTraveler: {
          status: action.code,
          loading: false,
          error: false,
        }
      }
    default:
      return state;
  }
}
