import React from 'react';
import {
  AUTHORIZED_FOR
} from '../actions/types';

const initialState = {
  authorizations: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTHORIZED_FOR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
