import { GET_TEMPLATES } from '../actions/types';

const initialState = {
  templates: [],
  hasDuplicate: false,
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        loading: false,
        templates: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
