import moment from 'moment-timezone';
import FileSaver from 'file-saver';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import fetch from 'node-fetch';
import { Cookies } from 'react-cookie';
import qs from "query-string";
import filterDateRangeOptions from "../../sites/travelerProfile/utils/options/filterDateRangeOptions";


const customFormatDate = (date, format) => moment(date, format).toDate();
const formatDate = date => moment(date).format('MMMM D[,] YYYY');
const formatDateWithTimeZone = (date, timeZone) => {
  if (timeZone) {
    return moment(date)
      .tz(timeZone)
      .format('MMM DD, YYYY hh:mm a z');
  } else {
    return moment(date).format('MMM DD, YYYY hh:mm a z');
  }
};

const compatibleForDatepicker = date => (date ? moment(date) : null);
const formatDateForBackend = date => (date ? date.format() : null);
const formatDateForDeadline = date => (date ? date.format('LL') : null);
const checkDeadlinePast = date => {
  const nowDate = moment().format('MMMM D, YYYY h:mm a');
  const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
  return moment(dateToCheck).isSameOrBefore(nowDate);
};

const generateRangeArray = (start, stop, step) =>
  Array.from({ length: (stop - start) / step }, (_, i) => start + i * step);

const generateRandomImageIndex = (images = 5) => Math.floor(Math.random() * (images - 1)) + 0;

const emptyStringOrValue = value => (!!value && value.toString().trim().length > 0 ? value.toString() : '');
const emptyIntOrValue = value => (!!value && value.toString().trim().length > 0 ? parseInt(value) : '');
const emptyFloatOrValue = value => (!!value && value.toString().trim().length > 0 ? parseFloat(value) : '');
const emptyOrFormattedDate = value => (moment(value).isValid() ? moment(value).format('MMM D, YYYY') : '');
const numberCommaFormat = value => new Intl.NumberFormat().format(value);
const replaceCustomAliasesHandlebars = (string, customAliases) => {
  return string
    .replace(/{{Program}}/g, customAliases.alias_program)
    .replace(/{{Traveler}}/g, customAliases.alias_traveler)
    .replace(/{{Travelers}}/g, customAliases.alias_travelers)
    .replace(/{{Programs}}/g, customAliases.alias_programs)
    .replace(/{{program}}/g, customAliases.alias_program.toLowerCase())
    .replace(/{{traveler}}/g, customAliases.alias_traveler.toLowerCase())
    .replace(/{{travelers}}/g, customAliases.alias_travelers.toLowerCase())
    .replace(/{{programs}}/g, customAliases.alias_programs.toLowerCase());
  // .replace(/(?<!General )Form/g, 'General Form')
  // .replace(/(?<!general )form/g, 'general form');
};

const stripHardReturns = value => {
  if (!value || value === null) {
    value = '';
  }

  value = value.replace(/(?:\\[rn]|[\r\n]+)+/g, '');

  return value;
};
const stripHtmlString = value => {
  if (!value || value === null) {
    value = '';
  }

  value = value.toString();

  while (value !== null && value.includes('&nbsp;')) {
    value = value.replace('&nbsp;', '');
  }
  while (value !== null && value.includes('&amp;')) {
    value = value.replace('&amp;', '');
  }
  return value !== null && value.replace(/(<([^>]+)>)/gi, '');
};

const stripHtmlStringValidation = value => {
  if (!value || value === null) {
    value = '';
  }

  value = value.toString();

  while (value !== null && value.includes('&nbsp;')) {
    value = value.replace('&nbsp;', '_');
  }
  while (value !== null && value.includes('&amp;')) {
    value = value.replace('&amp;', '_');
  }
  return value !== null && value.replace(/(<([^>]+)>)/gi, '');
};

const hTag_To_pTag = value => {
  if (!value || value === null) {
    value = '';
  }
  while (value !== null && value.includes('&nbsp;')) {
    value = value.replace('&nbsp;', ' ');
  }

  value = value.replaceAll('h1>', 'p>');
  value = value.replaceAll('h2>', 'p>');
  value = value.replaceAll('h3>', 'p>');
  value = value.replaceAll('h4>', 'p>');
  value = value.replaceAll('h5>', 'p>');
  value = value.replaceAll('h6>', 'p>');

  return value !== null && value;
};

const buildSelectOptions = (values, sort = false, unique = false) => {
  let valueOptions = [];
  if (values && values.length > 0) {
    values = unique ? _uniq(values) : values;

    values = sort ? _orderBy(values, [value => value.toLowerCase()], ['asc']) : values;

    values.forEach(item => {
      valueOptions.push({
        label: item,
        value: item,
      });
    });
  }

  return valueOptions;
};

const buildCountyAndCityArray = locations => {
  let cityList = [];
  let countryList = [];

  if (locations?.length > 0) {
    let arrLocations = locations.split(';');
    arrLocations.forEach(loc => {
      let separate = loc.split(',');
      if (!separate[1]) {
        countryList.push(separate[0].trim());
      } else {
        if (separate[0]) {
          cityList.push(separate[0].trim());
        }
        if (separate[1]) {
          countryList.push(separate[1].trim());
        }
      }
    });
  }

  cityList = _uniq(cityList);
  cityList = _sortBy(cityList);

  countryList = _uniq(countryList);
  countryList = _sortBy(countryList);

  return {
    cities: cityList,
    countries: countryList,
    formattedCities: cityList.join('; '),
    formattedCountries: countryList.join('; '),
  };
};

const getContrastColor = hexcolor => {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const validateEmail = (email = '', errorMsg) => {
  let validEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  return email.length < 3
    ? { valid: false, error: errorMsg ? errorMsg : 'Email must be entered' }
    : validEmail === null
    ? { valid: false, error: 'Invalid email address format' }
    : { valid: true, error: '' };
};

const isValidURL = str => {
  const regexp = /^http(s?):\/\/(www\.)?(((\w+(([\.\-]{1}([a-z]{2,})+)+)(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)|(\w+((\.([a-z]{2,})+)+)(\:[0-9]{1,5}(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)))|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|([1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]+)+)(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*)((\:[0-9]{1,5}(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)*))$/;
  return regexp.test(str);
};

const downloadFile = (file, name) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  fetch(`${file}`, {
    headers: token,
  })
    .then(function(response) {
      return response.blob();
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, name.replace(/ /g, '_'));
    });
};

const dateInUTC = () => {
  const now = new Date();
  const now_utc = new Date(now.toUTCString().slice(0, -4));
  const dateNowUTC = new Date(now_utc);
  const year = dateNowUTC.getFullYear();
  const month = ('0' + (dateNowUTC.getMonth() + 1)).slice(-2);
  const day = ('0' + dateNowUTC.getDate()).slice(-2);

  const dateUtc = `${year}-${month}-${day}`;

  return dateUtc;
};

const getDateFromOffsetDays = (date, dayOffset) => {
  let targetDate = date;

  if (dayOffset > 0) {
    targetDate = moment(date)
      .add(dayOffset, 'day')
      .format('YYYY-MM-DD');
  }

  if (dayOffset < 0) {
    targetDate = moment(date)
      .subtract(dayOffset * -1, 'day')
      .format('YYYY-MM-DD');
  }

  return targetDate;
};

const customFormatDateWithTimezone = (date, format, timeZone) => {
    let newDate = date;
    let updatedDate =  moment(newDate).format(format);
    if(timeZone && timeZone?.length > 0) {
      newDate = moment(date)
      .tz(timeZone)
      .format();

      updatedDate = moment(newDate)
      .tz(timeZone)
      .format("MMM DD[,] YYYY");
    }
    return updatedDate;
}

const getDateDaysBefore = (days) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - days); // Subtract 90 days

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day} 00:00:00`;
}
const getDateDaysAfter = (days) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + days); // Subtract 90 days

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day} 00:00:00`;
}




const generateProgramDashboardFilterQueryString = (filters) => {
  const formattedFilters = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0 && typeof value[0] === "object") {
        const values = value.map((item) => item.value).join(",");
        if (values) formattedFilters[key] = values;
      } else if (value.length > 0) {
        formattedFilters[key] = value.join(",");
      }
    } else if (typeof value === "object" && value !== null) {
      // Handling startDate and endDate
      if (key === "startDate" || key === "endDate") {
        if (value.date) formattedFilters[`${key}_date`] = new Date(value.date).toISOString();
        if (value.range) formattedFilters[`${key}_range`] = value.range.value;
        if (value.dateInclusive) formattedFilters[`${key}_inclusive`] = new Date(value.dateInclusive).toISOString();
      } else {
        // Handle other object cases
        if (value.date) formattedFilters[`${key}_date`] = value.date;
        if (value.range) formattedFilters[`${key}_range`] = value.range.value;
      }
    } else if (value !== "" && value !== null && value !== undefined) {
      formattedFilters[key] = value;
    }
  });

  const queryString = qs.stringify(formattedFilters, { skipNull: true, skipEmptyString: true });
  let newUrl = `${window.location.pathname}`;

  if (queryString) {
    newUrl += `?${queryString}`;
  }

  window.history.pushState(null, "", newUrl);
  return queryString;
};


const parseProgramDashboardQueryStringToFilters = (queryString, initialSelectedFilters) => {
  const parsed = qs.parse(queryString, { parseNumbers: true, parseBooleans: true });

  const filters = { ...initialSelectedFilters };

  Object.entries(filters).forEach(([key, defaultValue]) => {
    if (parsed[key] !== undefined) {
      let value = parsed[key];

      if (typeof value === "string") {
        if (value.includes(",")) {
          value = value.split(",").map((item) => item.trim());
        } else if (Array.isArray(defaultValue)) {
          value = [value];
        }
      }

      filters[key] = value;
    } else {
      // Maintain default values if not in query string
      if (Array.isArray(defaultValue)) {
        filters[key] = [];
      } else if (typeof defaultValue === "boolean") {
        filters[key] = false;
      } else if (typeof defaultValue === "string") {
        filters[key] = "no";
      } else if (typeof defaultValue === "object" && defaultValue !== null) {
        filters[key] = { ...defaultValue };
      }
    }
  });

  // Manually extract startDate and endDate from query string
  const dateKeys = ["startDate", "endDate"];
  dateKeys.forEach((key) => {
    const date = parsed[`${key}_date`] || null;
    let rangeValue = parsed[`${key}_range`] || null;

    const dateInclusive = parsed[`${key}_inclusive`] || null;

    if(rangeValue){
      console.log("range value is ", rangeValue);
      rangeValue = filterDateRangeOptions.find(i => i.value === rangeValue);
      console.log("range value after ", rangeValue);
    }

    if (date || rangeValue || dateInclusive) {
      filters[key] = {
        date: date || null,
        range: rangeValue || null,
        dateInclusive: dateInclusive || null,
      };
    }
  });

  // Ensure 'favorites' is properly parsed from query string
  if ("favorites" in filters) {
    filters["favorites"] = filters["favorites"] === "true";
  }

  // Process dropdown filters (ensure they are arrays before mapping)
  const dropDownFilters = [
    "cities",
    "countries",
    "housingType",
    "programName",
    "organizationName",
    "languageOfInstruction",
  ];

  dropDownFilters.forEach((filter) => {
    if (Array.isArray(filters[filter])) {
      filters[filter] = filters[filter].map((item) => ({
        label: item,
        value: item,
      }));
    }
  });

  return filters;
};




export {
  checkDeadlinePast,
  buildSelectOptions,
  generateRandomImageIndex,
  formatDate,
  formatDateWithTimeZone,
  compatibleForDatepicker,
  formatDateForBackend,
  generateRangeArray,
  formatDateForDeadline,
  emptyStringOrValue,
  emptyIntOrValue,
  stripHtmlString,
  stripHtmlStringValidation,
  numberCommaFormat,
  emptyOrFormattedDate,
  emptyFloatOrValue,
  buildCountyAndCityArray,
  getContrastColor,
  replaceCustomAliasesHandlebars,
  hTag_To_pTag,
  validateEmail,
  isValidURL,
  stripHardReturns,
  downloadFile,
  dateInUTC,
  getDateFromOffsetDays,
  customFormatDate,
  customFormatDateWithTimezone,
  getDateDaysBefore,
  getDateDaysAfter,
  generateProgramDashboardFilterQueryString,
  parseProgramDashboardQueryStringToFilters
};

