import {
  GET_PROGRAM_PREFERENCES,
  ADD_PROGRAM_MATCH_FORM_COMPLETED,
  ADD_PROGRAM_MATCH_FORM_LANGUAGES,
  ADD_PROGRAM_MATCH_FORM_PRIORITIES,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_TYPE,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_TERMS,
  ADD_PROGRAM_MATCH_FORM_SUBJECT_AREAS,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_LENGTH,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_COUNTRY,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_HOUSING,
} from '../actions/types';

const initialState = {
  priorities: [],
  programTerms: [],
  programTypes: [],
  subjectAreas: [],
  userLanguages: [],
  programHousing: [],
  allProgramTypes: [],
  programLength: null,
  programCountries: [],
  languageImmersion: null,
  otherProgramTypeText: '',
  otherProgramHousingText: '',
  programMatchCompleted: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAM_PREFERENCES:
      return {
        ...state,
        priorities: action.payload.priorities,
        programTerms: action.payload.program_terms,
        programTypes: action.payload.program_types,
        subjectAreas: action.payload.subject_areas,
        programLength: {
          minWeeks: action.payload.program_length.minimum_duration_weeks,
          maxWeeks: action.payload.program_length.maximum_duration_weeks,
        },
        userLanguages: action.payload.user_languages,
        programHousing: action.payload.program_housing,
        allProgramHousings: action.payload.housing_types,
        allProgramTypes: action.payload.all_program_types,
        programCountries: action.payload.program_countries,
        languageImmersion: action.payload.language_immersion,
        otherProgramTypeText: action.payload.other_program_type_text,
        otherProgramHousingText: action.payload.other_program_housing_text,
      };
    case ADD_PROGRAM_MATCH_FORM_SUBJECT_AREAS:
      return {
        ...state,
        subjectAreas: action.payload,
      };
    case ADD_PROGRAM_MATCH_FORM_PROGRAM_COUNTRY:
      return {
        ...state,
        programCountries: action.payload,
      };
    case ADD_PROGRAM_MATCH_FORM_PROGRAM_HOUSING:
      return {
        ...state,
        programHousing: action.payload.housingData,
        otherProgramHousingText: action.payload.otherText,
      };
    case ADD_PROGRAM_MATCH_FORM_PROGRAM_LENGTH:
      return {
        ...state,
        programLength: {
          minWeeks: action.payload.minWeeks,
          maxWeeks: action.payload.maxWeeks,
        },
      };
    case ADD_PROGRAM_MATCH_FORM_PROGRAM_TERMS:
      return {
        ...state,
        programTerms: action.payload,
      };
    case ADD_PROGRAM_MATCH_FORM_LANGUAGES:
      return {
        ...state,
        userLanguages: action.payload.userLanguages,
        languageImmersion: action.payload.languageImmersion,
      };
    case ADD_PROGRAM_MATCH_FORM_PROGRAM_TYPE:
      return {
        ...state,
        programTypes: action.payload.types,
        otherProgramTypeText: action.payload.otherText,
      };
    case ADD_PROGRAM_MATCH_FORM_PRIORITIES:
      return {
        ...state,
        priorities: action.payload,
      };
    case ADD_PROGRAM_MATCH_FORM_COMPLETED:
      return {
        ...state,
        programMatchCompleted: action.payload,
      };
    default:
      return state;
  }
}
