import {
  GET_TERM_NAMES,
  GET_TERM_NAMES_ACTIVE,
  CREATE_TERM_NAME_REQUEST,
  CREATE_TERM_NAME_SUCCESS,
  CREATE_TERM_NAME_FAILURE,
  CREATE_TERM_NAME_CLEAR,
  EDIT_TERM_NAME_REQUEST,
  EDIT_TERM_NAME_SUCCESS,
  EDIT_TERM_NAME_FAILURE,
  EDIT_TERM_NAME_CLEAR,
  BATCH_ADD_TERM_NAME_AUTH,
  BATCH_ADD_TERM_NAME_AUTH_SUCCESS,
  BATCH_ADD_TERM_NAME_AUTH_CLEAR,
  BATCH_ADD_TERM_NAME_AUTH_FAILURE,
} from '../actions/types';

const initialState = {
  termNames: {
    data: null,
    loading: false,
    error: null,
  },
  termNamesActive: {
    data: null,
    loading: false,
    error: null,
  },
  createTermName: {
    data: null,
    loading: false,
    error: null,
  },
  editTermName: {
    data: null,
    loading: false,
    error: null,
  },
  termNamesAuth: {
    data: null,
    loading: false,
    error: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TERM_NAMES:
      return {
        ...state,
        termNames: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };

    case GET_TERM_NAMES_ACTIVE:
      return {
        ...state,
        termNamesActive: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };

    case CREATE_TERM_NAME_REQUEST:
      return {
        ...state,
        createTermName: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case CREATE_TERM_NAME_SUCCESS:
      return {
        ...state,
        createTermName: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case CREATE_TERM_NAME_FAILURE:
      return {
        ...state,
        createTermName: {
          data: null,
          loading: false,
          error: 'There was a problem creating the Term Name',
        },
      };
    case CREATE_TERM_NAME_CLEAR:
      return {
        ...state,
        createTermName: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case EDIT_TERM_NAME_REQUEST:
      return {
        ...state,
        editTermName: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case EDIT_TERM_NAME_SUCCESS:
      return {
        ...state,
        editTermName: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case EDIT_TERM_NAME_FAILURE:
      return {
        ...state,
        editTermName: {
          data: null,
          loading: false,
          error: 'There was a problem editing the Term Name',
        },
      };
    case EDIT_TERM_NAME_CLEAR:
      return {
        ...state,
        editTermName: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_TERM_NAME_AUTH:
      return {
        ...state,
        termNamesAuth: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case BATCH_ADD_TERM_NAME_AUTH_SUCCESS:
      return {
        ...state,
        termNamesAuth: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case BATCH_ADD_TERM_NAME_AUTH_FAILURE:
      return {
        ...state,
        termNamesAuth: {
          data: null,
          loading: false,
          error: 'There was a problem adding a Term Name',
        },
      };
    case BATCH_ADD_TERM_NAME_AUTH_CLEAR:
      return {
        ...state,
        termNamesAuth: {
          data: null,
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
}
