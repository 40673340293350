export default [
  {
    id: 1,
    label: 'Is',
    value: 'is',
  },
  {
    id: 2,
    label: 'Is between (inclusive)',
    value: 'between_inclusive',
  },
  {
    id: 3,
    label: 'Is greater than or equal to',
    value: 'greater_or_equal',
  },
  {
    id: 4,
    label: 'Is less than or equal to',
    value: 'less_or_equal',
  },
];
