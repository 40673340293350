// import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import devToolsEnhancer from 'remote-redux-devtools';
import rootReducer from './reducers';

const initialState = {};
const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['intakeReducer', 'authReducer'],
  blacklist: ['programMatchReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(persistedReducer, initialState, enhancer);

const persistor = persistStore(store);

export { store, persistor };
