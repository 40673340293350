import {
  FETCH_PROGRAMS,
  FETCH_UNAUTHORIZED_PROGRAMS,
  AUTHORIZE_PROGRAMS,
  FETCH_PROVIDERS,
  FILTER_PROGRAM_LOCATION,
  FILTER_PROGRAM_CITIES,
  FILTER_PROGRAM_LANGUAGES,
  FILTER_PROGRAM_SUBJECTS,
  FILTER_LANGUAGE_IMMERSION,
  FILTER_PROGRAM_LENGTH,
  FILTER_PROGRAM_CONTACTS,
  FILTER_PROGRAM_PROVIDERS,
  FILTER_PROGRAM_SUPPORT_LEVEL,
  FILTER_PROGRAM_TYPES,
  FILTER_HOUSING_TYPES,
  FILTER_FAVORITES,
  FILTER_PUBLISH_STATUS,
  FILTER_AUTHORIZATION_STATUS,
  FILTER_ELIGIBILITY,
  FILTER_START_DATE,
  FILTER_END_DATE,
  BATCH_PUBLISH_PROGRAM,
  BATCH_UNPUBLISH_PROGRAM,
  CLEAR_PROGRAM_FILTERS,
  TOGGLE_FAVORITE,
  SEARCH_PROGRAMS,
  SELECT_PROGRAM,
  UNSELECT_PROGRAM,
  ADD_PROGRAM_RANGES,
  ADD_PROGRAM_ADMINS,
  DEAUTHORIZE_PROGRAMS,
  BATCH_ADD_TAGS_SUCCESS,
  BATCH_ADD_TAGS_FAILURE,
  PROGRAM_DASHBOARD_LOADING,
  CLEAR_PROGRAM_DASHBOARD_FLASHES,
  FILTER_SUPPORT_LEVEL_PROGRAMS,
  CLEAR_DRAFT_STATUSES,
  CLEAR_SELECTED_PROGRAMS,
  CLEAR_PUBLISHED_ID,
  PROGRAM_LOADING,
  FILTER_TERM_NAME,
} from '../actions/types';
import _flattenDeep from 'lodash/flattenDeep';

const initialState = {
  authorizeCode: null,
  programs: [],
  providers: [],
  clientId: null,
  selectedPrograms: [],
  favorites: [],
  administrators: [],
  loading: true,
  cities: [],
  contacts: [],
  allProgramTypes: [],
  programTypes: [],
  housingTypes: [],
  programLocations: [],
  programCities: [],
  programLanguages: [],
  programSubjects: [],
  languageImmersion: [],
  programLength: [1, 96],
  programContacts: [],
  programProviders: [],
  programSupportLevel: [],
  programTermNames: [],
  favoritesFilter: false,
  publishStatus: [],
  eligibility: [],
  startDate: [null, null],
  endDate: [null, null],
  query: '',
  code: null,
  rangeCode: null,
  adminCode: null,
  publishCode: null,
  unpublishCode: null,
  deauthorizeCode: null,
  showBatch: false,
  batchTagSuccessMessage: '',
  batchTagFailureMessage: '',
  batchLoading: false,
  draftErrors: {},
  draftCode: null,
  allHousingTypes: [],
  programTypeConnections: [],
  block: 0,
  moreProgramsLoading: true,
  published_id: [],
  program_loading: false,
  message: null,
  termNames: [],
};

export default function(state = initialState, action) {
  let loading;
  switch (action.type) {
    case FETCH_PROGRAMS:
      return {
        ...state,
        programs:
          action.payload.programs.length > 0 ? [...state.programs, action.payload.programs].flat() : state.programs,
        moreProgramsLoading: action.payload.programs.length > 0 ? true : false,
        clientId: action.payload.client_account_id,
        allProgramTypes: action.payload.all_program_types,
        allHousingTypes: action.payload.housing_types,
        cities: [
          ...state.cities,
          action.payload.programs
            .map(program => program.program_locations)
            .map(program_location => program_location.map(location => location[1]))
            .flat()
            .sort(),
        ],
        contacts:
          action.payload.programs.length > 0
            ? _flattenDeep([...state.contacts, action.payload.programs.map(program => program.program_contact)]).sort()
            : [...state.contacts.sort()],
        termNames:
          action.payload.programs.length > 0
            ? _flattenDeep([
                ...state.termNames,
                action.payload.programs.map(program => {
                  return program.program_ranges.map(range => {
                    const array = [];
                    if (range.name !== null) {
                      range.name = range.name.toLowerCase();
                      array.push(range);
                    }
                    return array;
                  });
                }),
              ]).sort()
            : [...state.termNames.sort()],
        favorites: action.payload.favorites,
        administrators: action.payload.administrators,
        rangeCode: null,
        adminCode: null,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        loading: false,
        block: state.block + 1,
      };
    case FETCH_UNAUTHORIZED_PROGRAMS:
      let activePrograms = [];
      if (action.payload.programs) {
        action.payload.programs.map(program => {
          program.status === 1 ? activePrograms.push(program) : null;
        });
      }
      return {
        ...state,
        programs: activePrograms,
        cities: [
          action.payload.programs
            .map(program => program.program_locations)
            .map(program_location => program_location.map(location => location[1]))
            .flat()
            .sort(),
        ],
        selectedPrograms: [],
        loading: false,
        allHousingTypes: action.payload.housing_types,
      };
    case AUTHORIZE_PROGRAMS:
      return {
        ...state,
        authorizeCode: action.payload.code,
      };
    case FETCH_PROVIDERS:
      return {
        ...state,
        providers: action.payload.providers,
      };
    case FILTER_PROGRAM_LOCATION:
      return {
        ...state,
        programLocations: action.programLocations,
      };
    case FILTER_PROGRAM_CITIES:
      return {
        ...state,
        programCities: action.programCities,
      };

    case FILTER_PROGRAM_LANGUAGES:
      return {
        ...state,
        programLanguages: action.programLanguages,
      };
    case FILTER_PROGRAM_SUBJECTS:
      return {
        ...state,
        programSubjects: action.programSubjects,
      };
    case FILTER_LANGUAGE_IMMERSION:
      return {
        ...state,
        languageImmersion: action.value,
      };
    case FILTER_PROGRAM_LENGTH:
      return {
        ...state,
        programLength: action.programLength,
      };
    case FILTER_PROGRAM_CONTACTS:
      return {
        ...state,
        programContacts: action.programContacts,
      };
    case FILTER_PROGRAM_PROVIDERS:
      return {
        ...state,
        programProviders: action.programProviders,
      };
    case FILTER_PROGRAM_SUPPORT_LEVEL:
      return {
        ...state,
        programSupportLevel: action.programSupportLevel,
      };
    case FILTER_PROGRAM_TYPES:
      return {
        ...state,
        programTypes: action.programTypes,
      };
    case FILTER_HOUSING_TYPES:
      return {
        ...state,
        housingTypes: action.housingTypes,
      };
    case FILTER_FAVORITES:
      return {
        ...state,
        favoritesFilter: action.value,
      };
    case FILTER_PUBLISH_STATUS:
      return {
        ...state,
        publishStatus: action.value,
      };
    case FILTER_AUTHORIZATION_STATUS:
      return {
        ...state,
        authorizationStatus: action.value,
      };
    case FILTER_ELIGIBILITY:
      return {
        ...state,
        eligibility: action.value,
      };
    case FILTER_START_DATE:
      return {
        ...state,
        startDate: action.value,
      };
    case FILTER_END_DATE:
      return {
        ...state,
        endDate: action.value,
      };
    case FILTER_TERM_NAME:
      return {
        ...state,
        programTermNames: action.programTermNames,
      };
    case CLEAR_PROGRAM_FILTERS:
      return {
        ...state,
        programTypes: [],
        housingTypes: [],
        programLocations: [],
        programCities: [],
        programLanguages: [],
        programSubjects: [],
        languageImmersion: [],
        programLength: [1, 96],
        programContacts: [],
        programProviders: [],
        programSupportLevel: [],
        programTermNames: [],
        startDate: [null, null],
        endDate: [null, null],
        favoritesFilter: false,
        publishStatus: [],
        eligibility: [],
      };
    case SEARCH_PROGRAMS:
      return {
        ...state,
        query: action.query,
      };
    case TOGGLE_FAVORITE:
      return {
        ...state,
        favorites: action.payload.favorites,
      };
    case SELECT_PROGRAM:
      return {
        ...state,
        selectedPrograms: [...state.selectedPrograms, action.programId],
        rangeCode: null,
        adminCode: null,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        showBatch: true,
      };
    case UNSELECT_PROGRAM:
      let selectedProgs = [...state.selectedPrograms.filter(program => program != action.programId)];
      return {
        ...state,
        selectedPrograms: selectedProgs,
        showBatch: selectedProgs.length > 0,
      };
    case BATCH_PUBLISH_PROGRAM:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        publishCode: action.code,
        published_id: action.published_id,
        unpublishCode: null,
        rangeCode: null,
        adminCode: null,
        deauthorizeCode: null,
        loading: false,
        draftErrors: action.draftErrors,
        draftCode: action.code,
        program_loading: false,
        message: action.message,
      };
    case BATCH_UNPUBLISH_PROGRAM:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        unpublishCode: action.code,
        publishCode: null,
        rangeCode: null,
        adminCode: null,
        deauthorizeCode: null,
        loading: false,
      };
    case ADD_PROGRAM_RANGES:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        rangeCode: action.code,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        loading,
      };
    case ADD_PROGRAM_ADMINS:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }
      return {
        ...state,
        adminCode: action.code,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        loading,
      };
    case DEAUTHORIZE_PROGRAMS:
      if (action.code == 200) {
        loading = true;
      } else {
        loading = false;
      }

      return {
        ...state,
        programs: state.programs.filter(program => !action.payload.program_ids.includes(program.id)),
        selectedPrograms: [],
        adminCode: null,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: action.payload.code,
        loading,
      };
    case BATCH_ADD_TAGS_SUCCESS:
      return {
        ...state,
        adminCode: null,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        batchTagSuccessMessage: action.success,
        batchTagFailureMessage: '',
        batchLoading: false,
        selectedPrograms: [],
        showBatch: false,
      };
    case BATCH_ADD_TAGS_FAILURE:
      return {
        ...state,
        adminCode: null,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        batchTagFailureMessage: action.error,
        batchTagSuccessMessage: '',
        batchLoading: false,
      };
    case PROGRAM_DASHBOARD_LOADING:
      return {
        ...state,
        batchLoading: true,
      };
    case CLEAR_PROGRAM_DASHBOARD_FLASHES:
      return {
        ...state,
        adminCode: null,
        publishCode: null,
        unpublishCode: null,
        deauthorizeCode: null,
        batchTagSuccessMessage: '',
        batchTagFailureMessage: '',
      };
    case FILTER_SUPPORT_LEVEL_PROGRAMS:
      return {
        ...state,
      };
    case CLEAR_DRAFT_STATUSES:
      return {
        ...state,
        draftErrors: {},
        draftCode: null,
      };
    case CLEAR_SELECTED_PROGRAMS:
      return {
        ...state,
        selectedPrograms: [],
        showBatch: false,
      };
    case PROGRAM_LOADING:
      return {
        ...state,
        program_loading: true,
      };
    case CLEAR_PUBLISHED_ID:
      return {
        ...state,
        published_id: [],
      };

    default:
      return state;
  }
}
