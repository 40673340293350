import {
  NEXT_PROGRAM_MATCH_STEP,
  PREVIOUS_PROGRAM_MATCH_STEP,
  CHANGE_PROGRAM_MATCH_STEP,
  FETCH_PROGRAM_PREFERENCES,
  ADD_PROGRAM_TERM_VALUES,
  REMOVE_PROGRAM_TERM_VALUES,
  ADD_PROGRAM_MATCH_PROGRAM_TYPE,
  ADD_PROGRAM_MATCH_PROGRAM_LENGTH,
  ADD_PROGRAM_MATCH_PROGRAM_PREFERENCE,
  ADD_PROGRAM_MATCH_LANGUAGES,
  ADD_PROGRAM_MATCH_PROGRAM_COUNTRY,
  ADD_PROGRAM_MATCH_PROGRAM_HOUSING,
  ADD_PROGRAM_MATCH_SUBJECT_AREAS,
  ADD_PROGRAM_MATCH_PRIORITIES,
  ADD_PROGRAM_MATCH_PROGRAM_TERMS,
  ADD_PROGRAM_MATCH_COMPLETED,
} from '../actions/types';

const initialState = {
  programMatchStep: 0,
  loading: true,
  programTypes: [],
  allProgramTypes: [],
  otherProgramTypeText: '',
  programLength: null,
  programTerms: [],
  // levelOfSupport: 1,
  languageImmersion: null,
  userLanguages: [],
  programCountries: [],
  programHousing: [],
  otherProgramHousingText: '',
  subjectAreas: [],
  priorities: [],
  program_match_completed: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PREVIOUS_PROGRAM_MATCH_STEP:
      return {
        ...state,
        programMatchStep: state.programMatchStep - 1,
      };
    case NEXT_PROGRAM_MATCH_STEP:
      return {
        ...state,
        programMatchStep: state.programMatchStep + 1,
      };
    case CHANGE_PROGRAM_MATCH_STEP:
      return {
        ...state,
        programMatchStep: action.step,
      };
    case ADD_PROGRAM_TERM_VALUES:
      return {
        ...state,
        programTerms: [...state.programTerms.filter(term => term.id != action.term.id), action.term],
      };
    case REMOVE_PROGRAM_TERM_VALUES:
      return {
        ...state,
        programTerms: [...state.programTerms.filter(term => term.id !== action.term.id)],
      };
    case FETCH_PROGRAM_PREFERENCES:
      return {
        ...state,
        programTypes: action.payload.program_types,
        allProgramTypes: action.payload.all_program_types,
        otherProgramTypeText: action.payload.other_program_type_text,
        programLength: action.payload.program_length,
        programTerms: action.payload.program_terms,
        // levelOfSupport: action.payload.level_of_support,
        languageImmersion: action.payload.language_immersion,
        userLanguages: action.payload.user_languages,
        programCountries: action.payload.program_countries,
        programHousing: action.payload.program_housing,
        otherProgramHousingText: action.payload.other_program_housing_text,
        subjectAreas: action.payload.subject_areas,
        priorities: action.payload.priorities,
        allProgramHousings: action.payload.housing_types,
        loading: false,
      };
    case ADD_PROGRAM_MATCH_PROGRAM_TYPE:
      return {
        ...state,
        programTypes: action.payload,
      };
    case ADD_PROGRAM_MATCH_PROGRAM_LENGTH:
      return {
        ...state,
        programLength: {
          minimum_duration_weeks: action.payload[0],
          maximum_duration_weeks: action.payload[1],
        },
      };
    case ADD_PROGRAM_MATCH_LANGUAGES:
      return {
        ...state,
        languageImmersion: action.payload.language_immersion,
        userLanguages: action.payload.user_languages,
      };
    case ADD_PROGRAM_MATCH_PROGRAM_COUNTRY:
      return {
        ...state,
        programCountries: action.payload.program_countries,
      };
    case ADD_PROGRAM_MATCH_PROGRAM_HOUSING:
      return {
        ...state,
        programHousing: action.payload,
      };
    case ADD_PROGRAM_MATCH_SUBJECT_AREAS:
      return {
        ...state,
        subjectAreas: action.payload.subject_areas,
      };
    case ADD_PROGRAM_MATCH_PRIORITIES:
      return {
        ...state,
        priorities: action.payload,
      };
    case ADD_PROGRAM_MATCH_PROGRAM_TERMS:
      return {
        ...state,
        programTerms: action.payload,
      };
    case ADD_PROGRAM_MATCH_COMPLETED:
      return {
        ...state,
        program_match_completed: action.payload.program_match_completed,
      };
    default:
      return state;
  }
}
