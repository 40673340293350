import {
  SET_RISK_LEVELS,
  FETCH_RISK_LEVELS_REQUEST,
  FETCH_RISK_LEVELS_FAILURE,
  FETCH_RISK_LEVELS_CLEAR,
  FETCH_RISK_TYPES_REQUEST,
  FETCH_RISK_TYPES_FAILURE,
  FETCH_RISK_TYPES_CLEAR,
  SET_RISK_TYPES,
  SAVE_FILTER_REQUEST,
  SAVE_FILTER_FAILURE,
  SAVE_FILTER_CLEAR,
  FETCH_FILTER_CONFIG_CLEAR,
  FETCH_FILTER_CONFIG_REQUEST,
  FETCH_FILTER_CONFIG_FAILURE,
  SET_FILTER_CONFIG,
  TOGGLE_SEND_MESSAGE_DIALOG,
  TOGGLE_SEND_RISK_ALERT_DIALOG,
  FETCH_RISK_ALERTS_REQUEST,
  FETCH_RISK_ALERTS_FAILURE,
  FETCH_RISK_ALERTS_CLEAR,
  SET_RISK_ALERTS,
  MARK_ALL_READ_REQUEST,
  MARK_ALL_READ_FAILURE,
  MARK_ALL_READ_CLEAR,
  FETCH_RISK_DETAIL_REQUEST,
  FETCH_RISK_DETAIL_FAILURE,
  FETCH_RISK_DETAIL_CLEAR,
  SET_RISK_DETAIL,
  FETCH_RISK_ALERT_HISTORY_REQUEST,
  FETCH_RISK_ALERT_HISTORY_FAILURE,
  FETCH_RISK_ALERT_HISTORY_CLEAR,
  SET_RISK_ALERT_HISTORY,
  FETCH_RISK_ALERT_HISTORY_CSV_REQUEST,
  FETCH_RISK_ALERT_HISTORY_CSV_FAILURE,
  FETCH_RISK_ALERT_HISTORY_CSV_CLEAR,
  SET_RISK_ALERT_HISTORY_CSV_DATA,
  FETCH_ALERT_TRAVELERS_REQUEST,
  FETCH_ALERT_TRAVELERS_FAILURE,
  FETCH_ALERT_TRAVELERS_CLEAR,
  SET_ALERT_TRAVELERS,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_CLEAR,
  SET_COUNTRIES,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_FAILURE,
  SEND_NOTIFICATION_SUCCESS,
  SEND_ALERT_REQUEST,
  SEND_ALERT_FAILURE,
  SEND_ALERT_SUCCESS,
  FETCH_TRAVELER_ALERT_INFO_REQUEST,
  FETCH_TRAVELER_ALERT_INFO_FAILURE,
  FETCH_TRAVELER_ALERT_INFO_CLEAR,
  SET_TRAVELER_ALERT_INFO,
  SEND_CUSTOM_MESSAGE_REQUEST,
  SEND_CUSTOM_MESSAGE_SUCCESS,
  SEND_CUSTOM_MESSAGE_FAILURE,
  TOGGLE_EMERGENCY_NUMBER_DIALOG,
  CREATE_RISK_ALERTS_NOTE_REQUEST,
  CREATE_RISK_ALERTS_NOTE_SUCCESS,
  CREATE_RISK_ALERTS_NOTE_CLEAR,
  CREATE_RISK_ALERTS_NOTE_FAILURE,
  UPDATE_RISK_ALERTS_NOTE_REQUEST,
  UPDATE_RISK_ALERTS_NOTE_SUCCESS,
  UPDATE_RISK_ALERTS_NOTE_CLEAR,
  UPDATE_RISK_ALERTS_NOTE_FAILURE,
  DELETE_RISK_ALERTS_NOTE_REQUEST,
  DELETE_RISK_ALERTS_NOTE_SUCCESS,
  DELETE_RISK_ALERTS_NOTE_CLEAR,
  DELETE_RISK_ALERTS_NOTE_FAILURE
} from '../actions/types';

const initialState = {
  riskLevels: [],
  riskLevelOptions: [],
  riskTypes: [],
  riskTypeOptions: [],
  riskTypes: [],
  loading: false,
  saveLoading: false,
  infoLoading: false,
  filterInfo: null,
  error: '',
  openSendMessageDialog: false,
  sendMessageUserIds: [],
  openSendRiskAlertDialog: false,
  sendRiskAlertUserIds: [],
  riskAlerts: [],
  isNewAlert: false,
  riskAlertDetail: null,
  riskAlertDetailLoading: false,
  riskAlertDetailError: '',

  appliedFilters: [],

  riskAlertHistoryLoading: false,
  riskAlertHistoryCsvLoading: false,
  riskAlertHistoryList: [],
  riskAlertHistoryCsvData: [],
  riskAlertHistoryError: '',
  riskAlertHistoryCsvError: '',
  riskAlertHistoryPagination: {
    current_page: 1,
    per_page: 10,
    total_pages: 1,
  },
  riskAlertHistoryCountryCount: 0,
  riskAlertHistoryAlertCount: 0,
  alertTravelers: [],
  alertTravelersLoading: false,
  alertTravelersError: '',
  countries: [],
  countriesOptions: [],
  countriesError: '',
  countriesLoading: false,
  notificationLoading: false,
  notificationError: '',
  sendAlertLoading: false,
  sendAlertError: '',
  travelerAlertInfo: null,
  travelerAlertInfoLoading: false,
  travelerAlertInfoError: '',
  sendCustomMessageLoading: false,
  sendCustomMessageError: '',
  emergencyNumbersDialog: {
    show: false,
    data: [],
    countryName: '',
  },
  organizationNote :   {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_RISK_LEVELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RISK_LEVELS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_RISK_LEVELS_CLEAR:
      return {
        ...state,
        loading: false,
      };
    case SET_RISK_LEVELS:
      return {
        ...state,
        loading: false,
        riskLevels: action.payload,
        riskLevelOptions: action.payload.map(riskLevel => {
          return {
            label: riskLevel.attributes.name,
            value: riskLevel.attributes.id,
          };
        }),
      };

    case FETCH_RISK_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RISK_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_RISK_TYPES_CLEAR:
      return {
        ...state,
        loading: false,
      };
    case SET_RISK_TYPES:
      return {
        ...state,
        loading: false,
        riskTypes: action.payload,
        riskTypeOptions: action.payload.map(riskType => {
          return {
            label: riskType.attributes.name,
            value: riskType.attributes.id,
          };
        }),
      };

    case FETCH_FILTER_CONFIG_REQUEST:
      return {
        ...state,
        infoLoading: true,
      };
    case FETCH_FILTER_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        infoLoading: false,
      };
    case FETCH_FILTER_CONFIG_CLEAR:
      return {
        ...state,
        infoLoading: false,
      };

    case SET_FILTER_CONFIG:
      let filters = [];

      for (let i = 0; i < action.payload.risk_levels.length; i++) {
        filters.push({
          type: 'risk_level',
          id: action.payload.risk_levels[i]?.id,
          name: action.payload.risk_levels[i]?.name,
        });
      }

      for (let i = 0; i < action.payload.risk_categories.length; i++) {
        filters.push({
          type: 'risk_category',
          id: action.payload.risk_categories[i]?.id,
          name: action.payload.risk_categories[i]?.name,
        });
      }

      return {
        ...state,
        infoLoading: false,
        filterInfo: {
          ...action.payload,
          risk_level_ids: action.payload.risk_level_ids.map(level => Number(level)),
          risk_category_ids: action.payload.risk_category_ids.map(category => Number(category)),
        },
        appliedFilters: filters,
      };

    case SAVE_FILTER_REQUEST:
      return {
        ...state,
        saveLoading: true,
      };

    case SAVE_FILTER_FAILURE:
      return {
        ...state,
        saveLoading: false,
        error: action.payload,
      };

    case SAVE_FILTER_CLEAR:
      return {
        ...state,
        saveLoading: false,
      };

    case TOGGLE_SEND_MESSAGE_DIALOG:
      return {
        ...state,
        openSendMessageDialog: action.payload.flag,
        sendMessageUserIds: action.payload.data,
      };

    case TOGGLE_SEND_RISK_ALERT_DIALOG:
      return {
        ...state,
        openSendRiskAlertDialog: action.payload.flag,
        sendRiskAlertUserIds: action.payload.data,
      };

    case FETCH_RISK_ALERTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_RISK_ALERTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case FETCH_RISK_ALERTS_CLEAR:
      return {
        ...state,
        loading: false,
      };

    case SET_RISK_ALERTS:
      return {
        ...state,
        loading: false,
        riskAlerts: action.payload.data,
        isNewAlert: action.payload.isNewAlert,
      };

    case MARK_ALL_READ_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MARK_ALL_READ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MARK_ALL_READ_CLEAR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_RISK_DETAIL_REQUEST:
      return {
        ...state,
        riskAlertDetailLoading: true,
      };

    case FETCH_RISK_DETAIL_FAILURE:
      return {
        ...state,
        riskAlertDetailError: action.payload,
        riskAlertDetailLoading: false,
      };

    case FETCH_RISK_DETAIL_CLEAR:
      return {
        ...state,
        riskAlertDetailLoading: false,
      };

    case SET_RISK_DETAIL:
      return {
        ...state,
        riskAlertDetailLoading: false,
        riskAlertDetail: action.payload,
      };

    case FETCH_RISK_ALERT_HISTORY_REQUEST:
      return {
        ...state,
        riskAlertHistoryLoading: true,
      };

    case FETCH_RISK_ALERT_HISTORY_FAILURE:
      return {
        ...state,
        riskAlertHistoryError: action.payload,
        riskAlertHistoryLoading: false,
      };

    case FETCH_RISK_ALERT_HISTORY_CLEAR:
      return {
        ...state,
        riskAlertHistoryLoading: false,
      };

    case SET_RISK_ALERT_HISTORY:
      return {
        ...state,
        riskAlertHistoryLoading: false,
        riskAlertHistoryList: [...action.payload.data],
        riskAlertHistoryPagination: action.payload.pagination,
        riskAlertHistoryCountryCount: action.payload.country_count,
        riskAlertHistoryAlertCount: action.payload.alert_count,
      };

    case FETCH_RISK_ALERT_HISTORY_CSV_REQUEST:
      return {
        ...state,
        riskAlertHistoryCsvLoading: true,
      };

    case FETCH_RISK_ALERT_HISTORY_CSV_FAILURE:
      return {
        ...state,
        riskAlertHistoryCsvError: action.payload,
        riskAlertHistoryCsvLoading: false,
      };

    case FETCH_RISK_ALERT_HISTORY_CSV_CLEAR:
      return {
        ...state,
        riskAlertHistoryCsvLoading: false,
      };

    case SET_RISK_ALERT_HISTORY_CSV_DATA:
      return {
        ...state,
        riskAlertHistoryCsvLoading: false,
        riskAlertHistoryCsvData: [...action.payload.data],
      };

    case FETCH_ALERT_TRAVELERS_REQUEST:
      return {
        ...state,
        alertTravelersLoading: true,
      };

    case FETCH_ALERT_TRAVELERS_FAILURE:
      return {
        ...state,
        alertTravelersError: action.payload,
        alertTravelersLoading: false,
      };

    case FETCH_ALERT_TRAVELERS_CLEAR:
      return {
        ...state,
        alertTravelersLoading: false,
      };

    case SET_ALERT_TRAVELERS:
      return {
        ...state,
        alertTravelersLoading: false,
        alertTravelers: action.payload,
      };

    case FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        countriesLoading: true,
      };

    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        countriesOptions: action.payload.map(country => {
          return {
            label: country?.attributes?.name,
            value: country?.attributes?.id,
          };
        }),
        countriesLoading: false,
      };

    case FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        countriesLoading: false,
        countriesError: action.payload,
      };

    case FETCH_COUNTRIES_CLEAR:
      return {
        ...state,
        countriesLoading: false,
      };

    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationLoading: true,
      };

    case SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationLoading: false,
        notificationError: action.payload,
      };

    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationLoading: false,
      };

    case SEND_ALERT_REQUEST:
      return {
        ...state,
        sendAlertLoading: true,
      };

    case SEND_ALERT_FAILURE:
      return {
        ...state,
        sendAlertLoading: false,
        sendAlertError: action.payload,
      };

    case SEND_ALERT_SUCCESS:
      return {
        ...state,
        sendAlertLoading: false,
      };

    case FETCH_TRAVELER_ALERT_INFO_REQUEST:
      return {
        ...state,
        travelerAlertInfoLoading: true,
      };

    case FETCH_TRAVELER_ALERT_INFO_FAILURE:
      return {
        ...state,
        travelerAlertInfoError: action.payload,
        travelerAlertInfoLoading: false,
      };

    case FETCH_TRAVELER_ALERT_INFO_CLEAR:
      return {
        ...state,
        travelerAlertInfoLoading: false,
      };

    case SET_TRAVELER_ALERT_INFO:
      return {
        ...state,
        travelerAlertInfoLoading: false,
        travelerAlertInfo: action.payload,
      };

    case SEND_CUSTOM_MESSAGE_REQUEST:
      return {
        ...state,
        sendCustomMessageLoading: true,
      };

    case SEND_CUSTOM_MESSAGE_FAILURE:
      return {
        ...state,
        sendCustomMessageLoading: false,
        sendCustomMessageError: action.payload,
      };

    case SEND_CUSTOM_MESSAGE_SUCCESS:
      return {
        ...state,
        sendCustomMessageLoading: false,
      };

    case TOGGLE_EMERGENCY_NUMBER_DIALOG:
      return {
        ...state,
        emergencyNumbersDialog: {
          data: action.payload.data,
          show: action.payload.show,
          countryName: action.payload.countryName,
        },
      };
    
      case DELETE_RISK_ALERTS_NOTE_REQUEST:
        return {
          ...state,
          organizationNote: {
            ...state.organizationNote,
            loading: true,
            error: ""
          }
        }
  
      
      case DELETE_RISK_ALERTS_NOTE_SUCCESS:
        return {
          ...state,
          organizationNote: {
            ...state.organizationNote,
            loading: false,
            error: ""
          }
        }
  
  
      case DELETE_RISK_ALERTS_NOTE_FAILURE:
        return {
          ...state,
          organizationNote: {
            ...state.organizationNote,
            loading: false,
            error: action.payload
          }
        }
    
        case CREATE_RISK_ALERTS_NOTE_CLEAR:
          return {
            ...state,
            organizationNote: {
              data: null,
              loading: false,
              error: false,
              message: null,
            },
          };
        case CREATE_RISK_ALERTS_NOTE_REQUEST:
          return {
            ...state,
            organizationNote: {
              data: null,
              loading: true,
              error: false,
              message: null,
            },
          };
        case CREATE_RISK_ALERTS_NOTE_SUCCESS:
          return {
            ...state,
            organizationNote: {
              data: action.payload.data,
              loading: false,
              error: false,
              message: action.payload.message,
            },
          };
        case CREATE_RISK_ALERTS_NOTE_FAILURE:
          return {
            ...state,
            organizationNote: {
              data: null,
              loading: false,
              error: true,
              message: action.message,
            },
          };
        
          case UPDATE_RISK_ALERTS_NOTE_CLEAR:
            return {
              ...state,
              organizationNote: {
                data: null,
                loading: false,
                error: false,
                message: null,
              },
            };
          case UPDATE_RISK_ALERTS_NOTE_REQUEST:
            return {
              ...state,
              organizationNote: {
                data: null,
                loading: true,
                error: false,
                message: null,
              },
            };
          case UPDATE_RISK_ALERTS_NOTE_SUCCESS:
            return {
              ...state,
              organizationNote: {
                data: action.payload.data,
                loading: false,
                error: false,
                message: action.payload.message,
              },
            };
          case UPDATE_RISK_ALERTS_NOTE_FAILURE:
            return {
              ...state,
              organizationNote: {
                data: null,
                loading: false,
                error: true,
                message: action.message,
              },
            };  

    default:
      return state;
  }
}
