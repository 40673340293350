import {
  NEXT_INTAKE_STEP,
  PREVIOUS_INTAKE_STEP,
  CHANGE_INTAKE_STEP,
  FETCH_INTAKE,
  ADD_INTAKE_PROFILE,
  ADD_INTAKE_TRAVELER_INFO,
  ADD_INTAKE_COUNTRIES_OF_CITIZENSHIP,
  ADD_INTAKE_TRAVELER_TYPE,
  ADD_INTAKE_HOME_INSTITUTION,
  ADD_INTAKE_ADVISING,
  ADD_INTAKE_FINANCIAL_INFO,
  ADD_INTAKE_FINANCIAL_AID,
  ADD_INTAKE_GOAL,
  ADD_INTAKE_TRAVEL_EXPERIENCE,
  ADD_INTAKE_LANGUAGES,
  ADD_INTAKE_PASSPORT,
  ADD_INTAKE_COMPLETE,
} from '../actions/types';

const initialState = {
  intakeStep: 0,
  addIntakeProfileStatus: null,
  addIntakeTravelerInfoStatus: null,
  first_name: '',
  last_name: '',
  countries_of_citizenship: [],
  traveler_type: '',
  school_name: '',
  advising: [],
  financial_info: [],
  receives_federal_financial_aid: null,
  travel_goals: [],
  travel_experience: [],
  travel_certainty: 0,
  languages: [false, false, []],
  passport: { status: null, alpha2: null },
  stepsCompleted: 0,
  intake: {},
  readonly: [],
  ssoTraveler: false,
  intake_complete: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PREVIOUS_INTAKE_STEP:
      return {
        ...state,
        intakeStep: state.intakeStep - 1,
      };
    case NEXT_INTAKE_STEP:
      return {
        ...state,
        intakeStep: state.intakeStep + 1,
      };
    case CHANGE_INTAKE_STEP:
      return {
        ...state,
        intakeStep: action.step,
      };
    case FETCH_INTAKE:
      return {
        ...state,
        advising: action.payload.advising,
        countries_of_citizenship: action.payload.countries_of_citizenship,
        financial_info: action.payload.financial_info,
        receives_federal_financial_aid: action.payload.receives_federal_financial_aid,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        passport: action.payload.passport,
        school_name: action.payload.school_name,
        travel_certainty: action.payload.travel_certainty,
        travel_experience: action.payload.travel_experience,
        travel_goals: action.payload.travel_goals,
        traveler_type: action.payload.traveler_type,
        readonly: action.payload.readonly,
        ssoTraveler: action.payload.sso_traveler,
      };
    case ADD_INTAKE_PROFILE:
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        stepsCompleted: 1,
      };
    case ADD_INTAKE_COUNTRIES_OF_CITIZENSHIP:
      return {
        ...state,
        countries_of_citizenship: action.payload.countries_of_citizenship,
        stepsCompleted: 2,
      };
    case ADD_INTAKE_TRAVELER_TYPE:
      return {
        ...state,
        traveler_type: action.payload,
        stepsCompleted: 3,
      };
    case ADD_INTAKE_HOME_INSTITUTION:
      return {
        ...state,
        school_name: action.payload.school_name,
        stepsCompleted: 4,
      };
    case ADD_INTAKE_ADVISING:
      return {
        ...state,
        advising: action.payload,
        stepsCompleted: 5,
      };
    case ADD_INTAKE_FINANCIAL_INFO:
      return {
        ...state,
        financial_info: action.payload,
        stepsCompleted: 6,
      };
    case ADD_INTAKE_FINANCIAL_AID:
      return {
        ...state,
        receives_federal_financial_aid: action.payload,
        stepsCompleted: 7,
      };
    case ADD_INTAKE_GOAL:
      return {
        ...state,
        travel_goals: action.payload,
        stepsCompleted: 8,
      };
    case ADD_INTAKE_TRAVEL_EXPERIENCE:
      return {
        ...state,
        travel_experience: action.payload,
        stepsCompleted: 9,
      };
    case ADD_INTAKE_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
        stepsCompleted: 10,
      };
    case ADD_INTAKE_PASSPORT:
      return {
        ...state,
        passport: action.payload,
        stepsCompleted: 11,
      };
    case ADD_INTAKE_COMPLETE:
      return {
        ...state,
        intake_complete: action.payload,
      };

    default:
      return state;
  }
}
