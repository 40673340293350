import moment from 'moment-timezone';

import {
  GET_PROGRAM_BROCHURE,
  TOGGLE_LEARN_MORE_MODAL,
  MARK_PROGRAM_BROCHURE_AMENITIES_PARSED,
  GET_AUTHORIZED_PROGRAM_ORGANIZATION_SETTINGS,
  FAVORITE_DISCLAIMER_ACCEPTED,
  GET_PROGRAM_BROCHURE_NEW,
} from '../actions/types';

const initialState = {
  loading: true,
  showLearnMoreModal: false,
  showMap: true,
  meta: {
    id: null,
    title: null,
    intro: null,
    provider: null,
    internal: null,
    authorized: false,
    description: null,
    isFavorite: false,
    maxAlternates: null,
    externalApplicationUrl: null,
  },
  language: {
    primary: null,
    isImmersive: false,
  },
  organization: {
    name: null,
    timezone: null,
    description: null,
    authorizedProgramApplicationInstructions: null,
  },
  eligibility: null,
  images: {
    list: null,
    result: null,
    large: null,
    medium: null,
    small: null,
    square: null,
    background: null,
  },
  types: [],
  testimonials: [],
  terms: [],
  scholarshipInfo: '',
  amenities: {
    parsed: false,
    all: [],
    included: [],
    excluded: [],
  },
  contacts: {
    primary: [],
    secondary: [],
  },
  academic: {
    subjects: [],
    course: {
      courses: [],
      notes: null,
    },
    notes: null,
    academic_video_url: ""
  },
  location: {
    locations: [],
    coordinates: [],
    notes: null,
  },
  housing: {
    types: [],
    notes: null,
  },
  hostOrganization: {
    name: null,
    notes: null,
  },
  activity: {
    activities: [],
    excursions: [],
    addons: [],
    notes: null,
  },
  attachments: [],
  update_favorite_disclaimer: false,
  about_program_video_url: "",
  program_info_video_url: "",
  eligibility_video_url: ""
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROGRAM_BROCHURE:
      return {
        ...state,
        loading: false,
        showMap: payload.program_map,
        meta: {
          internal: payload.internal_program,
          id: payload.program.id,
          is_authorizable: payload.program.is_authorizable,
          provider: payload.provider,
          title: payload.program.title,
          authorized: payload.authorized,
          intro: payload.program_highlight.text,
          description: payload.program_location.text,
          externalApplicationUrl: payload.program.external_application_url || null,
          isFavorite: payload?.program_favorite?.id ? true : false,
          maxAlternates:
            !!payload.alternate_settings && 'alternate_setting' in payload
              ? payload.alternate_setting.maximum_alternates
              : null,
          minAlternates: payload?.alternate_setting?.minimum_alternates || 0,
          brochure_instruction: payload?.alternate_setting?.brochure_instruction || '',
          occasional_user_assigned: payload?.occasional_user_assigned,
        },
        language: {
          primary: payload.program_languages,
          isImmersive: payload.program.language_immersion,
        },
        organization: {
          name: payload.home_campus,
          timezone: payload.org_timezone,
          description: payload.organization_description,
        },
        eligibility: payload.program_eligibility.eligibility_info,
        images: {
          background: payload.program.background_photo.url,
          list: payload.program.background_photo.program_list.url,
          result: payload.program.background_photo.program_result.url,
          large: payload.program.background_photo.program_large.url,
          medium: payload.program.background_photo.program_medium.url,
          small: payload.program.background_photo.program_small.url,
          square: payload.program.background_photo.program_square.url,
        },
        types: payload.program_types || [],
        testimonials: [payload.program_reviews],
        terms: parseTermStatus(payload.ranges || [], payload.org_timezone),
        scholarshipInfo: payload.program_scholarship.scholarship_info || '',
        amenities: {
          parsed: false,
          all: payload.program_amenities || [],
          included: payload.program_amenities_included || [],
          excluded: payload.program_amenities_excluded || [],
        },
        contacts: {
          primary: payload.program_administrators
            ? payload.program_administrators.filter(admin => admin.program_contact)
            : [],
          secondary: [],
        },
        academic: {
          subjects: payload.program_subjects || [],
          course: {
            courses: payload.program_courses || [],
            notes: payload.pcourses,
          },
          notes: payload.academic_notes,
          embed_course_list_url: payload.program.embed_course_list_url,
          academic_video_url: payload.program.academic_video_url
        },
        location: {
          locations: payload.program_locations || [],
          coordinates: payload.coordinates || [],
          notes: payload.location_notes,
        },
        housing: {
          types: payload.program_housing || [],
          notes: payload.housing_notes,
        },
        hostOrganization: {
          name: payload.host_organization,
          notes: payload.host_organization_notes,
        },
        activity: {
          activities: payload.pactivities || [],
          excursions: payload.pexcursions || [],
          addons: payload.padd_ons || [],
          notes: payload.activity_notes,
        },
        publishStatus: payload.publish_status,
        attachments: payload.program_attachments || [],
        program_brochure_section: payload.program_brochure_section || {},
        program_info_video_url: payload.program.program_info_video_url,
        about_program_video_url: payload.program.about_program_video_url,
        eligibility_video_url: payload.program.eligibility_video_url
      };

    case GET_PROGRAM_BROCHURE_NEW:
      return {
        ...state,
        loading: false,
        showMap: payload.program_map,
        meta: {
          internal: payload.internal_program,
          id: payload.program.id,
          is_authorizable: payload.program.is_authorizable,
          provider: payload.provider,
          title: payload.program.title,
          authorized: payload.authorized,
          intro: payload.program_highlight.text,
          description: payload.program_location.text,
          externalApplicationUrl: payload.program.external_application_url || null,
          isFavorite: payload?.program_favorite?.id ? true : false,
          maxAlternates:
            !!payload.alternate_settings && 'alternate_setting' in payload
              ? payload.alternate_setting.maximum_alternates
              : null,
          minAlternates: payload?.alternate_setting?.minimum_alternates || 0,
          brochure_instruction: payload?.alternate_setting?.brochure_instruction || '',
          occasional_user_assigned: payload?.occasional_user_assigned,
        },
        language: {
          primary: payload.program_languages,
          isImmersive: payload.program.language_immersion,
        },
        organization: {
          name: payload.home_campus,
          timezone: payload.org_timezone,
          description: payload.organization_description,
        },
        eligibility: payload.program_eligibility.eligibility_info,
        images: {
          background: payload.program.background_photo,
          list: payload.program.program_list_img_url
          // list: payload.program.background_photo.program_list.url,
          // result: payload.program.background_photo.program_result.url,
          // large: payload.program.background_photo.program_large.url,
          // medium: payload.program.background_photo.program_medium.url,
          // small: payload.program.background_photo.program_small.url,
          // square: payload.program.background_photo.program_square.url,
        },
        types: payload.program_types || [],
        testimonials: payload.program_reviews,
        terms: parseTermStatus(payload.ranges || [], payload.org_timezone),
        scholarshipInfo: payload.program_scholarship.scholarship_info || '',
        amenities: {
          parsed: false,
          all: payload.program_amenities || [],
          included: payload.program_amenities_included || [],
          excluded: payload.program_amenities_excluded || [],
        },
        contacts: {
          primary: payload.program_administrators
            ? payload.program_administrators.filter(admin => admin.program_contact)
            : [],
          secondary: [],
        },
        managers: payload.program_administrators,
        academic: {
          subjects: payload.program_subjects || [],
          course: {
            courses: payload.program_courses || [],
            notes: payload.pcourses,
          },
          notes: payload.academic_notes,
          embed_course_list_url: payload.program.embed_course_list_url,
          academic_video_url: payload.program.academic_video_url
        },
        location: {
          locations: payload.locations || [],
          coordinates: payload.coordinates || [],
          notes: payload.location_notes,
        },
        housing: {
          types: payload.program_housing || [],
          notes: payload.housing_notes,
        },
        hostOrganization: {
          name: payload.host_organization,
          notes: payload.host_organization_notes,
        },
        activity: {
          activities: payload.pactivities || [],
          excursions: payload.pexcursions || [],
          addons: payload.padd_ons || [],
          notes: payload.activity_notes,
        },
        publishStatus: payload.publish_status,
        attachments: payload.program_attachments || [],
        program_brochure_section: payload.program_brochure_section || {},
        program_info_video_url: payload.program.program_info_video_url,
        about_program_video_url: payload.program.about_program_video_url,
        eligibility_video_url: payload.program.eligibility_video_url,
      };

    case FAVORITE_DISCLAIMER_ACCEPTED:
      return {
        ...state,
        update_favorite_disclaimer: payload,
      };
    case TOGGLE_LEARN_MORE_MODAL:
      return {
        ...state,
        showLearnMoreModal: !state.showLearnMoreModal,
      };
    case MARK_PROGRAM_BROCHURE_AMENITIES_PARSED:
      return {
        ...state,
        amenities: {
          ...state.amenities,
          parsed: true,
        },
      };
    case GET_AUTHORIZED_PROGRAM_ORGANIZATION_SETTINGS:
      return {
        ...state,
        organization: {
          ...state.organization,
          authorizedProgramApplicationInstructions: payload[0].AuthorizedProgramSettings,
        },
      };
    default:
      return state;
  }
}

const parseTermStatus = (terms, timezone = null) => {
  return terms
    .filter(term => term)
    .map(term => {
      const nowDate = timezone
        ? moment()
            .tz(timezone)
            .format('MMMM D, YYYY h:mm a')
        : moment()
            .tz(moment.tz.guess())
            .format('MMMM D, YYYY h:mm a');

      const termStartDate = timezone
        ? moment()
            .tz(timezone)
            .format('MMMM D, YYYY h:mm a')
        : moment()
            .tz(moment.tz.guess())
            .format('MMMM D, YYYY h:mm a');
      const termDeadlineDate = moment(new Date(term.range.deadline)).format('MMMM D, YYYY h:mm a');
      if (term.range.status === 'active') {
        if (
          !moment(termDeadlineDate).isSameOrAfter(nowDate) &&
          term.application !== null &&
          term.range.status !== 'cancelled' &&
          term.range.deadline !== null
        ) {
          term.range.parsed_status = 'closed';
        } else {
          if (moment(nowDate).isSameOrBefore(termStartDate)) {
            term.range.parsed_status = term.application ? 'active' : 'coming-soon';
          } else {
            term.range.parsed_status = 'past';
          }
        }
      } else if (term.range.deadline <= nowDate && term.range.status !== 'cancelled') {
        term.range.parsed_status = 'closed';
      } else {
        term.range.parsed_status = 'cancelled';
      }

      return term;
    });
};
