export default function getRenderedLayout(form) {
  let qLayout = [];

  form.template.template_layout.layout.forEach(layout => {
    const q = form.template.questions.find(q => q.question_id.toString() === layout.id);

    if (q && layout) {
      const findResponse = form.responses.find(r => r.question_id.toString() === q.question_id.toString());

      qLayout.push({
        layout_id: layout.id,
        layout_position: layout.position,
        questionId: q.question_id,
        question: q,
        response: findResponse ? findResponse : null,
        cash_net_codes: q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
      });
    }

    if (q?.single_choice_question_logic?.child_question) {
      const child_q = q?.single_choice_question_logic?.child_question;
      const findResponse = form?.responses.find(r => r?.question_id?.toString() === child_q?.question_id?.toString());

      child_q.response = findResponse;

      qLayout.push({
        layout_id: child_q?.question_id?.toString(),
        layout_position: ['9999', '9999'],
        questionId: child_q.question_id,
        question: child_q,
        response: findResponse ? findResponse : null,
        cash_net_codes: child_q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
      });
    }

    if (q?.dropdown_question_logic?.child_question) {
      const child_q = q?.dropdown_question_logic?.child_question;
      const findResponse = form?.responses.find(r => r?.question_id?.toString() === child_q?.question_id?.toString());

      child_q.response = findResponse;

      qLayout.push({
        layout_id: child_q?.question_id?.toString(),
        layout_position: ['9999', '9999'],
        questionId: child_q.question_id,
        question: child_q,
        response: findResponse ? findResponse : null,
        cash_net_codes: child_q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
      });
    }
  });

  return qLayout;
}
