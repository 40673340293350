import {
  CREATE_TEMPLATE,
  TEMPLATE_UPDATE,
  GET_TEMPLATE,
  UPDATE_TEMPLATE_WITH_OUT_SYNC,
  UPDATE_TEMPLATE_STATE_ONLY,
} from '../actions/types';

const initialState = {
  id: '',
  title: '',
  introduction: '',
  status: '',
  loading: true,
  sections: [],
  sectionsCount: 0,
  show_cashnet: false,
  show_cashnet_with_code: false,
  show_flywire: false,
  show_payflow: false,
  show_touchnet: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE:
      return {
        ...state,
        loading: false,
        id: action.payload[0].template.id,
        title: action.payload[0].template.title,
        status: action.payload[0].template.status,
        introduction: action.payload[0].template.introduction,
        type: action.payload[0].template.type,
        sections: action.payload[0].sections,
        sectionsCount: action.payload[0].sections.length,
        show_cashnet: action.payload[0].template.show_cashnet,
        show_cashnet_with_code: action.payload[0].template.show_cashnet_with_code,
        show_flywire: action.payload[0].template.show_flywire,
        show_payflow: action.payload[0].template.show_payflow,
        show_touchnet: action.payload[0].template.show_touchnet,
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
      };
    case TEMPLATE_UPDATE:
      let updatedTemplate = action.payload.data.attributes;
      updatedTemplate.id = Number(action.payload.data.id);

      return {
        ...state,
        loading: false,
        id: Number(action.payload.data.id),
        title: updatedTemplate.title,
        introduction: updatedTemplate.introduction,
        sections: updatedTemplate.sections,
      };
    case UPDATE_TEMPLATE_WITH_OUT_SYNC:
      return {
        ...state,
        sections: action.payload.sections,
      };
    case UPDATE_TEMPLATE_STATE_ONLY:
      return {
        ...state,
        sections: action.payload.sections,
        template: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
