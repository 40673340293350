import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faExclamation,
  faEnvelope,
  faPlaneDeparture,
  faPlaneArrival,
  faPlane,
  faBed,
  faWalking,
  faTimesCircle,
  faPencilAlt,
  faArrowRight,
  faTrashAlt,
  faCalendarDay,
  faEdit,
  faBan,
  faCheck,
  faExclamationTriangle,
  faExclamationCircle,
  faShip,
  faCar,
  faTrain,
  faSubway,
  faBicycle,
  faComment,
  faBuilding,
  faHome,
  faHotel,
  faHSquare,
  faCouch,
  faHouseUser,
  faPaintBrush,
  faHandshake,
  faRoute,
  faFutbol,
  faCapsules,
  faHandsHelping,
  faBriefcase,
  faSearch,
  faSuitcase,
  faHiking,
  faComments,
  faUser,
  faUsers,
  faSuitcaseRolling,
  faMapMarkerAlt,
  faGlobe,
  faCity,
  faUniversity,
  faMapMarkedAlt,
  faFileDownload,
  faClipboardList,
  faFileAlt,
  faPassport,
  faSms,
  faClipboard,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faUpload,
  faDownload,
  faCheckCircle,
  faCaretUp,
  faCaretDown,
  faLongArrowAltLeft,
  faTaxi,
  faBus,
  faBars,
  faCalendarAlt,
  faTable,
  faEllipsisH,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

function initFontAwesome() {
  library.add(
    faPlus,
    faExclamation,
    faEnvelope,
    faPlaneDeparture,
    faPlaneArrival,
    faPlane,
    faBed,
    faWalking,
    faTimesCircle,
    faPencilAlt,
    faArrowRight,
    faTrashAlt,
    faCalendarDay,
    faEdit,
    faBan,
    faCheck,
    faExclamationTriangle,
    faExclamationCircle,
    faShip,
    faCar,
    faTrain,
    faSubway,
    faBicycle,
    faComment,
    faBuilding,
    faHome,
    faHotel,
    faHSquare,
    faCouch,
    faHouseUser,
    faPaintBrush,
    faHandshake,
    faRoute,
    faFutbol,
    faCapsules,
    faHandsHelping,
    faBriefcase,
    faSearch,
    faSuitcase,
    faHiking,
    faComments,
    faUser,
    faUsers,
    faSuitcaseRolling,
    faMapMarkerAlt,
    faGlobe,
    faCity,
    faUniversity,
    faMapMarkedAlt,
    faFileDownload,
    faClipboardList,
    faFileAlt,
    faPassport,
    faSms,
    faClipboard,
    faAngleLeft,
    faAngleDoubleLeft,
    faAngleRight,
    faAngleDoubleRight,
    faUpload,
    faDownload,
    faCheckCircle,
    faCaretUp,
    faCaretDown,
    faLongArrowAltLeft,
    faTaxi,
    faBus,
    faBars,
    faCalendarAlt,
    faTable,
    faEllipsisH,
    faAngleUp,
    faAngleDown,
  );
}

export default initFontAwesome;
