import _cloneDeep from 'lodash/cloneDeep';
import {
  FETCH_TRAVELER_FORM,
  UPDATE_PAYMENT_STATUS,
} from '../actions/types';

const initialState = {
  program: {},
  template: {},
  submission: {},
  answers: [],
  loading: true,
  cashnetCodes: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRAVELER_FORM:
      return {
        ...state,
        program: action.payload.program,
        template: action.payload.trm_template,
        submission: action.payload.submission,
        answers: action.payload.answers,
        cashnetCodes: action.payload.cashnetCodes,
        loading: false,
      };
    case UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        answers: updatePaymentState(state.answers, action.payload)
      }
    default:
      return state;
  }
}


const updatePaymentState = (_answers = [], payload) => {
  let answers = _cloneDeep(_answers);
  const answerIdx = answers?.findIndex(ans => ans.element_id === payload.element_id);
  if (answerIdx > -1) {
    answers[answerIdx] = payload;
  } else {
    answers.unshift(payload);
  }
  return answers;
}
