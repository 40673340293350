import {
  FETCH_HEADER,
  FETCH_TRAVELER_DATA,
  FETCH_EVENTS,
  FETCH_APPLICATIONS,
  FETCH_FAVORITES,
  FETCH_FORMS,
  FETCH_MESSAGES,
} from '../actions/types';

const initialState = {
  headerLoading: true,
  profileLoading: true,
  eventsLoading: true,
  applicationsLoading: true,
  favoritesLoading: true,
  formsLoading: true,
  messagesLoading: true,
  header: [],
  profile: [],
  events: [],
  applications: [],
  favorites: [],
  forms: [],
  messages: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_HEADER:
      return {
        ...state,
        header: action.payload,
        headerLoading: false,
      };
    case FETCH_TRAVELER_DATA:
      return {
        ...state,
        profile: action.payload,
        profileLoading: false,
      };
    case FETCH_EVENTS:
      return {
        ...state,
        events: action.payload,
        eventsLoading: false,
      };
    case FETCH_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
        applicationsLoading: false,
      };
    case FETCH_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
        favoritesLoading: false,
      };
    case FETCH_FORMS:
      return {
        ...state,
        forms: action.payload,
        formsLoading: true,
      };
    case FETCH_MESSAGES:
      return {
        ...state,
        messages: action.payload,
        messagesLoading: false,
      };

    default:
      return state;
  }
}
