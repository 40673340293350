import {
  GET_PROGRAMS,
  GET_PROGRAMS_REQUEST,
  SET_PROGRAMS_SORT,
  TOGGLE_PROGRAMS_FILTER,
  SET_PROGRAMS_SEARCH_TERM,
  CLEAR_ALL_PROGRAMS_FILTERS,
} from '../actions/types';
import { SET_QUERY_STRING_FILTERS } from "../../../actions/types";
import { generateProgramDashboardFilterQueryString } from "../../../shared/helpers/General";


const initialSelectedFilters = {
  cities: [],
  termName: [],
  countries: [],
  housingType: [],
  programName: [],
  programType: [],
  favorites: false,
  subjectAreas: [],
  organizationName: [],
  languageImmersion: 'no',
  languageOfInstruction: [],
  endDate: {
    date: null,
    range: null,
    dateInclusive: null,
  },
  startDate: {
    date: null,
    range: null,
    dateInclusive: null,
  },
}

const initialState = {
  programs: [],
  searchTerm: '',
  programsError: false,
  programsLoading: false,
  sortDirections: ['desc', 'asc'],
  sortProperties: ['internal_authorized', 'name'],
  selectedFilters: initialSelectedFilters
};

export default function(state = initialState, action) {
  switch (action.type) {

    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        programsLoading: true
      }

    case GET_PROGRAMS:
      return {
        ...state,
        programsLoading: false,
        programs: action.payload.data,
        programsError: action.payload.error,
      };
    case SET_PROGRAMS_SORT:
      return {
        ...state,
        sortDirections: action.payload.sortDirections,
        sortProperties: action.payload.sortProperties,
      };
    case SET_PROGRAMS_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case TOGGLE_PROGRAMS_FILTER:

      let filterObj = {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.name]: action.payload.value,
        },
      }
      generateProgramDashboardFilterQueryString(filterObj.selectedFilters);
      return filterObj;
    case CLEAR_ALL_PROGRAMS_FILTERS:
      generateProgramDashboardFilterQueryString({ ...initialSelectedFilters, languageImmersion: "", favorites: "" });
      return {
        ...state,
        selectedFilters: initialSelectedFilters,
      };

    case SET_QUERY_STRING_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload
      }

    default:
      return state;
  }
}
