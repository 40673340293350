import {
  CREATE_TAG,
  GET_TAGS,
  TAGS_LOADING,
  ARCHIVE_TAG,
  GET_ARCHIVED_TAGS,
  RESTORE_TAG,
  CLEAR_TAG_FLASH,
  GET_PAGINATED_TAGS,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_REQUEST,
  EDIT_TAG_FAILURE,
  TAG_CLEAR,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
} from '../actions/types';

const initialState = {
  tags: [],
  errorTags: [],
  tagsLoading: false,
  archivedTags: [],
  successfulTagsCount: 0,
  errorTagsCount: 0,
  archiveTagSuccess: false,
  restoreTagSuccess: false,
  paginatedTags: [],
  pagination: {},
  editTag: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  createTag: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_TAG:
      return {
        ...state,
        tags: [...state.tags, ...action.payload.success],
        errorTags: [...action.payload.failure],
        successfulTagsCount: action.payload.success.length,
        errorTagsCount: action.payload.failure.length,
      };
    case GET_TAGS:
      return {
        ...state,
        tags: action.payload,
        tagsLoading: false,
      };
    case TAGS_LOADING:
      return {
        ...state,
        tagsLoading: true,
      };
    case ARCHIVE_TAG:
      if (!!action.payload.errors) {
        return {
          ...state,
          errorTags: action.payload.errors,
          archiveTagSuccess: false,
        };
      } else {
        return {
          ...state,
          tags: filterTags([...state.tags], action.payload),
          archivedTags: [...state.archivedTags, action.payload],
          archiveTagSuccess: true,
        };
      }

    case GET_ARCHIVED_TAGS:
      return {
        ...state,
        archivedTags: action.payload,
        tagsLoading: false,
      };
    case RESTORE_TAG:
      return {
        ...state,
        tags: [...state.tags, action.payload],
        archivedTags: filterTags([...state.archivedTags], action.payload),
        restoreTagSuccess: true,
      };
    case CLEAR_TAG_FLASH:
      return {
        ...state,
        successfulTagsCount: 0,
        errorTagsCount: 0,
        archiveTagSuccess: false,
        restoreTagSuccess: false,
      };
    case GET_PAGINATED_TAGS:
      return {
        ...state,
        paginatedTags: action.payload.tags,
        pagination: action.payload.pagination,
        tagsLoading: false,
      };
    case EDIT_TAG_REQUEST:
      return {
        ...state,
        editTag: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        editTag: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case EDIT_TAG_FAILURE:
      return {
        ...state,
        editTag: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case TAG_CLEAR:
      return {
        ...state,
        editTag: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
        errorTags: [],
        archiveTagSuccess: false,
        restoreTagSuccess: false,
      };
    case CREATE_TAG_REQUEST:
      return {
        ...state,
        createTag: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        createTag: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        createTag: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };

    default:
      return state;
  }
}

const filterTags = (tags, payload) =>
  tags.filter(tag => {
    return tag.id !== payload.id;
  });
