import {
  GET_TRAVELER_APPLICATION,
  APPLICATION_LOADING,
  APPLICATION_LOADED,
  GET_PROGRAM_RANGES_WITH_SAME_TEMPLATE,
  SET_TRANSFER_APPLICATION_ERROR,
  SET_TRANSFER_APPLICATION_SUCCESS,
  SET_ALTERNATE_PROGRAM_SUCCESS,
} from '../actions/types';

const initialState = {
  applicationHash: {},
  applicationLoading: true,
  sameTemplateProgramRanges: [],
  transferErrors: null,
  transferSuccess: null,
  alternateProgramSuccess: null,
  programPhoto: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRAVELER_APPLICATION:
      return {
        ...state,
        applicationHash: action.payload,
        applicationLoading: false,
        programPhoto: (action.payload.program_photo && action.payload.program_photo.program_background) ? action.payload.program_photo.program_background : {},
      };
    case APPLICATION_LOADING:
      return {
        ...state,
        applicationLoading: true,
      };
    case APPLICATION_LOADED:
      return {
        ...state,
        applicationLoading: false,
      };
    case GET_PROGRAM_RANGES_WITH_SAME_TEMPLATE:
      return {
        ...state,
        sameTemplateProgramRanges: action.payload,
      };
    case SET_TRANSFER_APPLICATION_ERROR:
      return {
        ...state,
        transferErrors: action.payload,
      };
    case SET_TRANSFER_APPLICATION_SUCCESS:
      return {
        ...state,
        transferSuccess: action.payload,
      };
    case SET_ALTERNATE_PROGRAM_SUCCESS:
      return {
        ...state,
        alternateProgramSuccess: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
