import uuid from 'node-uuid';

import { FETCH_ANNOUNCEMENTS } from '../actions/types';

const initialState = {
  announcementsLoading: true,
  announcements: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload.announcements
          .sort((a, b) => a.weight - b.weight)
          .map(announcement => ({
            body: announcement.body,
            class: announcement.class,
            weight: announcement.weight,
            id: uuid(),
          })),
        announcementsLoading: false,
      };
    default:
      return state;
  }
}
