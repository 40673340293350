import React from 'react';
import PropTypes from 'prop-types';

import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { persistor, store } from '../store.js';

import history from '../history';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import ToastMessage from '../sites/viaGlobal/components/toastMessage';
import unregister from '../fetchInterceptor';
import AgGridKey from './ag-grid-key';
import initFontAwesome from '../sites/components/library/fontAwesome';

initFontAwesome();

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */

unregister;
class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.

    return (
      <CookiesProvider>
        <AgGridKey />
        <PersistGate loading={null} persistor={persistor}>
          <Provider store={store}>
            <Router history={history}>
              <ToastMessage />
            </Router>
            <Router history={history}>
              <ErrorBoundary>{React.Children.only(this.props.children)}</ErrorBoundary>
            </Router>
          </Provider>
        </PersistGate>
      </CookiesProvider>
    );
  }
}

export default App;
