import {
  CHECK_EMAIL_CLEAR,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST_CLEAR,
  RESET_PASSWORD_REQUEST_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  GET_USER_FROM_RESET_TOKEN_CLEAR,
  GET_USER_FROM_RESET_TOKEN_REQUEST,
  GET_USER_FROM_RESET_TOKEN_SUCCESS,
  GET_USER_FROM_RESET_TOKEN_FAILURE,
  TOKEN_CHANGE_PASSWORD_CLEAR,
  TOKEN_CHANGE_PASSWORD_REQUEST,
  TOKEN_CHANGE_PASSWORD_SUCCESS,
  TOKEN_CHANGE_PASSWORD_FAILURE,
  CREATE_USER_ACCOUNT_CLEAR,
  CREATE_USER_ACCOUNT_REQUEST,
  CREATE_USER_ACCOUNT_SUCCESS,
  CREATE_USER_ACCOUNT_FAILURE,
  SELECT_USER_ROLE_CLEAR,
  SELECT_USER_ROLE_REQUEST,
  SELECT_USER_ROLE_SUCCESS,
  SELECT_USER_ROLE_FAILURE,
} from '../actions/types';

const initialState = {
  checkEmail: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  resetPasswordRequest: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  getUserFromResetToken: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  tokenChangePassword: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  createUserAccount: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  changeUserRole: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHECK_EMAIL_CLEAR:
      return {
        ...state,
        checkEmail: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        checkEmail: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        }
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checkEmail: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case CHECK_EMAIL_FAILURE:
      return {
        ...state,
        checkEmail: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        }
      };
    case RESET_PASSWORD_REQUEST_CLEAR:
      return {
        ...state,
        resetPasswordRequest: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case RESET_PASSWORD_REQUEST_REQUEST:
      return {
        ...state,
        resetPasswordRequest: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        }
      };
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        resetPasswordRequest: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case RESET_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        resetPasswordRequest: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        }
      };
    case GET_USER_FROM_RESET_TOKEN_CLEAR:
      return {
        ...state,
        getUserFromResetToken: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case GET_USER_FROM_RESET_TOKEN_REQUEST:
      return {
        ...state,
        getUserFromResetToken: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        }
      };
    case GET_USER_FROM_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        getUserFromResetToken: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case GET_USER_FROM_RESET_TOKEN_FAILURE:
      return {
        ...state,
        getUserFromResetToken: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        }
      };

    case TOKEN_CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        tokenChangePassword: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case TOKEN_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        tokenChangePassword: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        }
      };
    case TOKEN_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        tokenChangePassword: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case TOKEN_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        tokenChangePassword: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        }
      };
    case CREATE_USER_ACCOUNT_CLEAR:
      return {
        ...state,
        createUserAccount: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case CREATE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        createUserAccount: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        }
      };
    case CREATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        createUserAccount: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case CREATE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        createUserAccount: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        }
      };
    case SELECT_USER_ROLE_CLEAR:
      return {
        ...state,
        changeUserRole: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case SELECT_USER_ROLE_REQUEST:
      return {
        ...state,
        changeUserRole: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        }
      };
    case SELECT_USER_ROLE_SUCCESS:
      return {
        ...state,
        changeUserRole: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        }
      };
    case SELECT_USER_ROLE_FAILURE:
      return {
        ...state,
        changeUserRole: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        }
      };
    default:
      return state;
  }
}