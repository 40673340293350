import {
  FETCH_TRAVELER_SETTINGS_HEADER,
  SELECT_TRAVELER_SETTINGS_TAB,
  UPDATE_TRAVELER_ACCOUNT,
} from '../actions/types';

const initialState = {
  activeTab: 'myAccount',
  userInfoLoading: true,
  userInfo: {},
  updateCode: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SELECT_TRAVELER_SETTINGS_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case FETCH_TRAVELER_SETTINGS_HEADER:
      return {
        ...state,
        userInfo: action.payload,
        userInfoLoading: false,
        updateCode: null
      };
    case UPDATE_TRAVELER_ACCOUNT:
      return {
        ...state,
        userInfo: action.payload.profile,
        updateCode: action.payload.status
      };
    default:
      return state;
  }
}
